import { type FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat, PnlFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { TerminalDealType } from "@/services/openapi";
import { NumberInput } from "@/shared/ui";

import { calculateProfitAndLoss } from "../../helpers/formulas";
import { MergedTerminalSymbol } from "../../helpers/symbols";
import { TakeProfitStopLossError, usePlaceOrderContext } from "../context";
import { PlaceOrderFieldBlock } from "../ui/field-block/block";

type Props = {
  changeStopLoss: ReturnType<typeof usePlaceOrderContext>["changeStopLoss"];
  stopLossFormValue: ReturnType<typeof usePlaceOrderContext>["stopLossFormValue"];
  stopLossError: ReturnType<typeof usePlaceOrderContext>["stopLossError"];
  stopLoss: ReturnType<typeof usePlaceOrderContext>["stopLoss"];
  stopLossThresholdValue: ReturnType<typeof usePlaceOrderContext>["stopLossThresholdValue"];
  currentPrice: ReturnType<typeof usePlaceOrderContext>["currentPrice"];
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  isBuyOrder: ReturnType<typeof usePlaceOrderContext>["isBuyOrder"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  symbols: MergedTerminalSymbol[];
  priceDecimalScale: number;
  isSubmitting: boolean;
  contractSize: number;
  quoteCurrency: string;
  baseCurrency: string;
  currency: string;
};

const StopLossField: FC<Props> = ({
  changeStopLoss,
  isSubmitting,
  stopLossFormValue,
  priceDecimalScale,
  stopLossError,
  stopLoss,
  stopLossThresholdValue,
  baseCurrency,
  contractSize,
  currency,
  currentPrice,
  isBuyOrder,
  quoteCurrency,
  symbols,
  volumeLots,
  volumeMarginDecimalScale,
}) => {
  const { t } = useTranslation();

  const invalid = stopLossError !== TakeProfitStopLossError.NONE;

  return (
    <>
      <NumberInput
        size="sm"
        clearable
        pending={isSubmitting}
        invalid={invalid}
        value={stopLossFormValue}
        onChange={value => changeStopLoss(value, { format: false })}
        decimalScale={priceDecimalScale}
        placeholder={t("terminal.place-order.stop-loss.placeholder")!}
        descriptor={
          invalid ? (
            <>
              {stopLossError === TakeProfitStopLossError.MORE && (
                <Trans
                  i18nKey="terminal.place-order.stop-loss.more-error"
                  components={{
                    value: <NumberFormat value={stopLossThresholdValue} decimalScale={priceDecimalScale} />,
                    valueButton: (
                      <NumberInput.DescriptorButton onClick={() => changeStopLoss(stopLossThresholdValue)} />
                    ),
                  }}
                />
              )}
              {stopLossError === TakeProfitStopLossError.LESS && (
                <Trans
                  i18nKey="terminal.place-order.stop-loss.less-error"
                  components={{
                    value: <NumberFormat value={stopLossThresholdValue} decimalScale={priceDecimalScale} />,
                    valueButton: (
                      <NumberInput.DescriptorButton onClick={() => changeStopLoss(stopLossThresholdValue)} />
                    ),
                  }}
                />
              )}
            </>
          ) : null
        }
      />
      {!invalid && (
        <PlaceOrderFieldBlock.Caption
          label={t("terminal.place-order.stop-loss.caption")}
          valueClassName={!stopLoss || !volumeLots ? undefined : getNumberColorClassname(-1)}
          value={
            !stopLoss || !volumeLots ? (
              "—"
            ) : (
              <PnlFormat
                value={calculateProfitAndLoss({
                  type: isBuyOrder ? TerminalDealType.Buy : TerminalDealType.Sell,
                  volume: volumeLots,
                  openPrice: currentPrice,
                  accountCurrency: currency,
                  currentPrice: stopLoss,
                  baseCurrency,
                  quoteCurrency,
                  contractSize,
                  symbols,
                })}
                decimalScale={volumeMarginDecimalScale}
                currency={currency}
              />
            )
          }
        />
      )}
    </>
  );
};

export { StopLossField };
