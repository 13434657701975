import * as SelectPrimitive from "@radix-ui/react-select";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";

import { IconChevronDown, IconTick } from "@/domains/icons";
import { cn } from "@/shared/styles";

import { type _ExternalWrapperProps, _inputTextStyles, _Wrapper } from "../inputs/_base";
import { popoverContentStyles } from "../popper";

type TriggerProps = Omit<_ExternalWrapperProps, "endSection" | "tooltipContent">;
const Trigger = forwardRef<ElementRef<typeof SelectPrimitive.Trigger>, TriggerProps>((props, ref) => (
  <SelectPrimitive.Trigger ref={ref} asChild>
    <_Wrapper
      type="select"
      endSection={
        <SelectPrimitive.Icon>
          <IconChevronDown />
        </SelectPrimitive.Icon>
      }
      {...props}
    >
      <div className={cn(_inputTextStyles(), "group/value")} data-select-value>
        <SelectPrimitive.Value />
      </div>
    </_Wrapper>
  </SelectPrimitive.Trigger>
));

const Content: FC<{ children: ReactNode }> = ({ children }) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      className={cn(
        popoverContentStyles(),
        "max-h-96 w-[var(--radix-select-trigger-width)] overflow-hidden rounded-[16px] bg-bg shadow",
      )}
      sideOffset={8}
      position="popper"
    >
      <SelectPrimitive.Viewport className={cn("py-2")}>{children}</SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
);

const textStyles = cva("font-roboto text-[14px] leading-[1.5] text-text")();

const Item = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & { asChild?: boolean }
>(({ className, children, asChild, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      textStyles,
      "flex w-full cursor-pointer select-none items-center justify-between p-4 outline-none transition-colors data-[highlighted]:bg-bg-back data-[state=checked]:bg-bg-selected",
      className,
    )}
    {...props}
  >
    <SelectPrimitive.ItemText asChild={asChild}>{children}</SelectPrimitive.ItemText>
    <SelectPrimitive.ItemIndicator>
      <IconTick />
    </SelectPrimitive.ItemIndicator>
  </SelectPrimitive.Item>
));

const ItemHiddenValue: FC<{ children: ReactNode }> = ({ children }) => (
  <Slot className="group-data-[select-value=true]/value:hidden">{children}</Slot>
);

type Props = ComponentPropsWithoutRef<typeof Trigger> &
  Pick<ComponentPropsWithoutRef<typeof SelectPrimitive.Root>, "value" | "onValueChange" | "disabled"> & {
    children: ReactNode;
  };

const Select = forwardRef<ElementRef<typeof Trigger>, Props>(({ children, value, onValueChange, ...props }, ref) => (
  <SelectPrimitive.Root disabled={props.disabled} onValueChange={onValueChange} value={value}>
    <Trigger ref={ref} {...props} />
    <Content>{children}</Content>
  </SelectPrimitive.Root>
));

const Component = Object.assign(Select, { Item, ItemHiddenValue });

export { Component as Select };
