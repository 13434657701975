import { TFunction } from "i18next";

import { NumberFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { TerminalDealEventType, TerminalDealType, TerminalDealUpdateType, TerminalEventDeal } from "@/services/openapi";
import { Toast, toast, ToastIcons } from "@/shared/ui";

import { TerminalTableState, useLayoutContext } from "../layout/context";
import { SymbolIcon } from "../symbols/icon";
import { getOrderTypeText } from "../trading-tables/components/trading-table/order-type";
import { MergedTerminalSymbol } from "./symbols";

export type LiveOrder = {
  id: number;
  symbol: string;
  type: TerminalDealType;
  swap: number;
  volume: number;
  price: number;
  event: TerminalDealEventType;
  date: string;
  isUpdate: boolean;
  marginRate: number;
  profitRate: number;
  stopLoss?: number;
  takeProfit?: number;
  updateVolume?: number;
  updatePrice?: number;
  updateProfit?: number | null;
  updateType?: TerminalDealType;
  updateAction?: TerminalDealUpdateType;
  updatePosition?: number;
};

export const transformTerminalOrder = ({
  d,
  e,
  p,
  s,
  sl,
  sw,
  t,
  tp,
  v,
  dt,
  u,
  rm,
  rp,
}: TerminalEventDeal): LiveOrder => ({
  marginRate: rm!,
  profitRate: rp!,
  id: d!,
  symbol: s!,
  date: dt!,
  type: t!,
  swap: sw!,
  volume: v!,
  price: p!,
  stopLoss: sl!,
  takeProfit: tp!,
  event: e!,
  updatePosition: u?.i,
  updateProfit: u?.r,
  updateAction: u?.u,
  updatePrice: u?.p,
  updateType: u?.t,
  updateVolume: u?.v,
  isUpdate: !!u,
});

export const normalizeOrdersList = (list: TerminalEventDeal[]) => {
  const initObject: { [key: string]: LiveOrder } = {};
  list.forEach(item => (initObject[item.d!] = transformTerminalOrder(item)));
  return initObject;
};

export const isPendingOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.BuyStop:
    case TerminalDealType.SellStop:
    case TerminalDealType.BuyLimit:
    case TerminalDealType.SellLimit:
      return true;
    default:
      return false;
  }
};

export const isMarketOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.Buy:
    case TerminalDealType.Sell:
      return true;
    default:
      return false;
  }
};

export const isBuyOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.Buy:
    case TerminalDealType.BuyLimit:
    case TerminalDealType.BuyStop:
    case TerminalDealType.BuyStopLimit:
      return true;
    default:
      return false;
  }
};

export const isSellOrder = (type: TerminalDealType): boolean => {
  switch (type) {
    case TerminalDealType.Sell:
    case TerminalDealType.SellLimit:
    case TerminalDealType.SellStop:
    case TerminalDealType.SellStopLimit:
      return true;
    default:
      return false;
  }
};

export const updateOrdersList = ({
  t,
  newOrder,
  currency,
  list,
  symbols,
  currencyDecimalScale,
  changeTable,
}: {
  t: TFunction;
  newOrder: LiveOrder;
  currency: string;
  currencyDecimalScale: number;
  list: {
    [key: string]: LiveOrder;
  };
  symbols: MergedTerminalSymbol[];
  changeTable: ReturnType<typeof useLayoutContext>["changeTable"];
}): {
  [key: string]: LiveOrder;
} => {
  const updatedList: typeof list = {};
  for (const key in list) {
    updatedList[key] = { ...list[key] };
  }

  const oldOrder: LiveOrder = updatedList[newOrder.id];

  const isMarket = isMarketOrder(newOrder.type);

  const { volumeDecimalScale } = symbols.find(({ symbol }) => symbol === newOrder.symbol) || {};

  const marketMessageInfo = {
    id: newOrder.id,
    type: getOrderTypeText(t, newOrder.updateType!),
    volume: newOrder.updateVolume?.toFixed(volumeDecimalScale),
    symbol: newOrder.symbol,
    position: newOrder.updatePosition,
  };

  const pendingMessageInfo = {
    id: newOrder.id,
    type: getOrderTypeText(t, newOrder.type),
    volume: newOrder.volume.toFixed(volumeDecimalScale),
    symbol: newOrder.symbol,
  };

  if (isMarket) {
    if (newOrder.event === TerminalDealEventType.Delete) {
      delete updatedList[newOrder.id];
      if (newOrder.updateAction === TerminalDealUpdateType.Close) {
        toast({
          icon: <SymbolIcon size="md" symbol={marketMessageInfo.symbol} />,
          title: t("terminal.messages.market-order-closed", { symbol: marketMessageInfo.symbol })!,
          description: (
            <span className={getNumberColorClassname(newOrder.updateProfit!)}>
              <NumberFormat value={newOrder.updateProfit} decimalScale={currencyDecimalScale} currency={currency} />
            </span>
          ),
          action: (
            <Toast.Action
              altText={t("terminal.messages.market-order-closed-button")}
              onClick={() => {
                changeTable(TerminalTableState.CLOSED_POSITIONS);
              }}
            >
              {t("terminal.messages.market-order-closed-button")}
            </Toast.Action>
          ),
        });
      }
      return updatedList;
    }

    updatedList[newOrder.id] = {
      ...oldOrder,
      ...newOrder,
    };

    if (newOrder.isUpdate) {
      if (oldOrder && oldOrder.type !== newOrder.updateType && newOrder.updateType !== newOrder.type) {
        toast({
          text: t("terminal.messages.market-order-partially-closed", marketMessageInfo),
          icon: <SymbolIcon size="md" symbol={marketMessageInfo.symbol} />,
          action: (
            <Toast.Action
              altText={t("terminal.messages.market-order-partially-closed-button")}
              onClick={() => {
                changeTable(TerminalTableState.CLOSED_POSITIONS);
              }}
            >
              {t("terminal.messages.market-order-partially-closed-button")}
            </Toast.Action>
          ),
        });
      } else {
        toast({
          title: t("terminal.messages.market-order-opened", marketMessageInfo)!,
          description: <>#{marketMessageInfo.id}</>,
          icon: <SymbolIcon size="md" symbol={marketMessageInfo.symbol} />,
          action: (
            <Toast.Action
              altText={t("terminal.messages.market-order-opened-button")}
              onClick={() => {
                changeTable(TerminalTableState.OPEN);
              }}
            >
              {t("terminal.messages.market-order-opened-button")}
            </Toast.Action>
          ),
        });
      }
    }
    return updatedList;
  }

  if (!isMarket) {
    if (newOrder.event === TerminalDealEventType.Delete) {
      const oldPending = isPendingOrder(oldOrder?.type);
      const newPending = isPendingOrder(newOrder.type);
      if (oldPending && newPending) {
        delete updatedList[newOrder.id];
        return updatedList;
      } else {
        return updatedList;
      }
    }
    if (oldOrder) {
      toast({ text: t("terminal.messages.pending-order-modified", pendingMessageInfo), icon: ToastIcons.SUCCESS });
    } else {
      toast({
        text: t("terminal.messages.pending-order-placed", pendingMessageInfo),
        icon: ToastIcons.SUCCESS,
        action: (
          <Toast.Action
            altText={t("terminal.messages.pending-order-placed-button")}
            onClick={() => {
              changeTable(TerminalTableState.PENDING);
            }}
          >
            {t("terminal.messages.pending-order-placed-button")}
          </Toast.Action>
        ),
      });
    }
    updatedList[newOrder.id] = {
      ...oldOrder,
      ...newOrder,
    };
  }

  return updatedList;
};
