import { cva } from "class-variance-authority";
import { FC, ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react";

import { CloseIcon } from "@/components/icons";
import { UnstyledButton } from "@/components/unstyled-button.tsx";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useProfileHeaderQuery } from "@/state/server/profile";

import * as Styled from "./Header.styled";

const headerStyles = cva(
  "font-gilroy text-[24px] font-semibold leading-[32px] text-contrast-primary md:text-[32px] md:leading-[19px]",
)();

type Props = {
  title: ReactNode;
  onClose?: () => void;
};

export const Header: FC<Props> = ({ title, onClose }) => {
  const { isMobile } = useScreenSize();
  const headRef = useRef<HTMLDivElement>(null);
  const [sticky, setSticky] = useState(false);
  const { data: profile } = useProfileHeaderQuery();

  useLayoutEffect(() => {
    const header = headRef.current?.getBoundingClientRect();

    const handleScrollEvent = () => {
      if (isMobile) {
        if (header) {
          const topHalf = header.top - header.top / 2;

          setSticky(window.scrollY >= topHalf);
        }
      }
    };

    window.addEventListener("scroll", handleScrollEvent, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const header = headRef.current?.getBoundingClientRect();

    const handleEnd = () => {
      if (header) {
        const topHalf = header.top - header.top / 4;

        // NOTE: по какой-то причине тут header.top не статичный в отличии от useLayoutEffect,
        // поэтому мы не можем завязываться на него, в следвие чего приходиться хардкотить числом
        // top: 32 потому что
        if (window.scrollY >= topHalf) {
          if (!sticky) {
            window.scroll({
              top: 32,
              left: 0,
              behavior: "smooth",
            });
          }
        } else {
          window.scroll({
            top: -32,
            left: 0,
            behavior: "smooth",
          });
        }
      }
    };
    document.addEventListener("touchend", handleEnd);

    return () => {
      document.removeEventListener("touchend", handleEnd);
    };
  }, [sticky]);

  return (
    <Styled.HeaderWrapper ref={headRef}>
      {isMobile && window.scrollY > 16 && (
        <Styled.HeadStatic animate={sticky}>
          <h1 className={headerStyles}>{title}</h1>
          <UnstyledButton onClick={onClose}>
            <CloseIcon />
          </UnstyledButton>
        </Styled.HeadStatic>
      )}
      <Styled.Head animate={sticky}>
        <h1 className={headerStyles}>{title}</h1>
        {!profile?.featuresInfo?.mandatorySurvey && isMobile && (
          <UnstyledButton onClick={onClose}>
            <CloseIcon />
          </UnstyledButton>
        )}
      </Styled.Head>
    </Styled.HeaderWrapper>
  );
};
