import { type FC } from "react";

import { useTerminalAccountSummary } from "../../account-summary/context";
import { useTerminalAccountContext } from "../../contexts/account.context";
import { useCurrentSymbolContext } from "../../contexts/current-symbol-context";
import { useExtendedOrdersContext } from "../../contexts/extended-orders.context";
import { useOrdersContext } from "../../contexts/orders.context";
import { useLayoutContext } from "../../layout/context";
import { OpenOrdersTable } from "../desktop/open-orders/table";
import { OpenOrdersCardsContainer } from "../mobile/open-orders/cards.container";

type Props = {
  onClose?: () => void;
};

const OpenOrdersContainer: FC<Props> = ({ onClose }) => {
  const { extendedOpenOrders } = useExtendedOrdersContext();

  const { ordersIsLoading } = useOrdersContext();

  const { pnl, swaps } = useTerminalAccountSummary();

  const { account } = useTerminalAccountContext();

  const { setSymbol } = useCurrentSymbolContext();
  const { openWatchlist, isMobile } = useLayoutContext();

  return isMobile ? (
    <OpenOrdersCardsContainer
      pnl={pnl}
      swaps={swaps}
      onClose={onClose}
      openWatchlist={openWatchlist}
      ordersIsLoading={ordersIsLoading}
      orders={extendedOpenOrders}
      accountId={account.id!}
      currency={account.currency!}
      currencyDecimalScale={account.digits!}
    />
  ) : (
    <OpenOrdersTable
      openWatchlist={openWatchlist}
      ordersIsLoading={ordersIsLoading}
      orders={extendedOpenOrders}
      accountId={account.id!}
      currency={account.currency!}
      currencyDecimalScale={account.digits!}
      setSymbol={setSymbol}
    />
  );
};

export { OpenOrdersContainer };
