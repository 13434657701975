import dayjs from "dayjs";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/contexts/auth.context";
import { useGoogleCaptcha } from "@/hooks/captcha.hook";
import { useClientId } from "@/hooks/client-id.hook";
import { useCookie } from "@/hooks/cookie.hook";
import { analyticsClickProperty } from "@/hooks/marketing-cookies.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { getProfile } from "@/services/profile";
import { useSignUpMutation } from "@/state/server/auth";
import { usePlatformData } from "@/state/server/platform/platform.hooks";

import { AuthFormContainer } from "../auth-form.container";
import { ISignUpFormValues, SingUpForm } from "./signup.form";

export const SignUpContainer: FC = () => {
  const { t } = useTranslation();

  const clientId = useClientId();
  const [_, setCookie, getCookie] = useCookie();
  const { captchaToken, disabledSubmit, hasCaptcha, onVerify, refreshCaptcha, refreshReCaptchaValue } =
    useGoogleCaptcha();
  const navigate = useNavigate();
  const platformInfo = usePlatformData();
  const clickId = getCookie(analyticsClickProperty);

  const registerUserPlatform = useCallback(() => {
    getProfile();
  }, []);

  const navigateToOnboarding = useCallback(() => {
    navigate(onboardingRoutes.onboarding);
  }, [navigate]);
  const { login } = useAuth();

  const { mutateAsync: signUp } = useSignUpMutation();
  const submitSignUp = useCallback(
    (values: ISignUpFormValues) => {
      const captcha = hasCaptcha ? { captcha: { challengeGoogleRecaptchaV3: { token: captchaToken } } } : {};
      return signUp(
        { signUpRequest: { ...values, ...captcha }, email: values.email },
        {
          onSuccess: async data => {
            login(data);
            const { id: userID } = await getProfile();
            refreshCaptcha();
            window.dataLayer?.push({
              event: "gtm_events",
              custom_timestamp: dayjs().valueOf(),
              userID,
              user_id: userID,
              client_id: clientId,
              ec: "register",
              ea: "register",
              el: "email",
              ga4_event_name: "register",
            });
            navigateToOnboarding();
            registerUserPlatform();

            if (clickId) setCookie(analyticsClickProperty, "");
          },
          onError: () => {
            if (hasCaptcha) {
              refreshCaptcha();
            }
          },
        },
      );
    },
    [hasCaptcha, captchaToken, signUp, login, refreshCaptcha, clientId, navigateToOnboarding, registerUserPlatform],
  );

  return (
    <AuthFormContainer title={t("auth.sign-up.title")} backLink>
      <SingUpForm
        platformInfo={platformInfo}
        onSubmit={submitSignUp}
        disabledSubmit={disabledSubmit}
        onVerify={onVerify}
        refreshReCaptchaValue={refreshReCaptchaValue}
      />
    </AuthFormContainer>
  );
};
