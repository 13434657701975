/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ActivityRequest } from '../models';
// @ts-ignore
import { ConfirmUpdateEmailRequest } from '../models';
// @ts-ignore
import { EmailRequest } from '../models';
// @ts-ignore
import { ErrorResult } from '../models';
// @ts-ignore
import { FcmToken } from '../models';
// @ts-ignore
import { ProfileHeader } from '../models';
// @ts-ignore
import { ProfileSettings } from '../models';
// @ts-ignore
import { ProfileSettingsRequest } from '../models';
/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm user email
         * @param {ConfirmUpdateEmailRequest} [confirmUpdateEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserEmail: async (confirmUpdateEmailRequest?: ConfirmUpdateEmailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/update/email/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmUpdateEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get profile header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.1/profile/header`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get profile settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove FCM token
         * @param {FcmToken} [fcmToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFcmToken: async (fcmToken?: FcmToken, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/push/token/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fcmToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request to delete a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/requests/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ActivityRequest} [activityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEvent: async (activityRequest?: ActivityRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable terminal target price dialog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceDialogDisable: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/options/target-price/dialog/disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable terminal target price dialog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceDialogEnable: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/options/target-price/dialog/enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable terminal target price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceDisable: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/options/target-price/disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable terminal target price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceEnable: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/options/target-price/enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update FCM token
         * @param {FcmToken} [fcmToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFcmToken: async (fcmToken?: FcmToken, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/push/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fcmToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set profile settings
         * @param {ProfileSettingsRequest} [profileSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileSettings: async (profileSettingsRequest?: ProfileSettingsRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileSettingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user email
         * @param {EmailRequest} [emailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEmail: async (emailRequest?: EmailRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/profile/update/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Confirm user email
         * @param {ConfirmUpdateEmailRequest} [confirmUpdateEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmUserEmail(confirmUpdateEmailRequest?: ConfirmUpdateEmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmUserEmail(confirmUpdateEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirm profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmUserProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmUserProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get profile header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileHeader>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get profile settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove FCM token
         * @param {FcmToken} [fcmToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFcmToken(fcmToken?: FcmToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFcmToken(fcmToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request to delete a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ActivityRequest} [activityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEvent(activityRequest?: ActivityRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEvent(activityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disable terminal target price dialog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async targetPriceDialogDisable(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.targetPriceDialogDisable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable terminal target price dialog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async targetPriceDialogEnable(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.targetPriceDialogEnable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disable terminal target price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async targetPriceDisable(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.targetPriceDisable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable terminal target price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async targetPriceEnable(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.targetPriceEnable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update FCM token
         * @param {FcmToken} [fcmToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFcmToken(fcmToken?: FcmToken, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFcmToken(fcmToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set profile settings
         * @param {ProfileSettingsRequest} [profileSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfileSettings(profileSettingsRequest?: ProfileSettingsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfileSettings(profileSettingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user email
         * @param {EmailRequest} [emailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserEmail(emailRequest?: EmailRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserEmail(emailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary Confirm user email
         * @param {ConfirmUpdateEmailRequest} [confirmUpdateEmailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserEmail(confirmUpdateEmailRequest?: ConfirmUpdateEmailRequest, options?: any): AxiosPromise<string> {
            return localVarFp.confirmUserEmail(confirmUpdateEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmUserProfile(options?: any): AxiosPromise<void> {
            return localVarFp.confirmUserProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get profile header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<ProfileHeader> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get profile settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileSettings(options?: any): AxiosPromise<ProfileSettings> {
            return localVarFp.getProfileSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove FCM token
         * @param {FcmToken} [fcmToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFcmToken(fcmToken?: FcmToken, options?: any): AxiosPromise<void> {
            return localVarFp.removeFcmToken(fcmToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request to delete a user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestDelete(options?: any): AxiosPromise<void> {
            return localVarFp.requestDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ActivityRequest} [activityRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEvent(activityRequest?: ActivityRequest, options?: any): AxiosPromise<void> {
            return localVarFp.saveEvent(activityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable terminal target price dialog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceDialogDisable(options?: any): AxiosPromise<void> {
            return localVarFp.targetPriceDialogDisable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable terminal target price dialog
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceDialogEnable(options?: any): AxiosPromise<void> {
            return localVarFp.targetPriceDialogEnable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable terminal target price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceDisable(options?: any): AxiosPromise<void> {
            return localVarFp.targetPriceDisable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable terminal target price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        targetPriceEnable(options?: any): AxiosPromise<void> {
            return localVarFp.targetPriceEnable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update FCM token
         * @param {FcmToken} [fcmToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFcmToken(fcmToken?: FcmToken, options?: any): AxiosPromise<void> {
            return localVarFp.updateFcmToken(fcmToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set profile settings
         * @param {ProfileSettingsRequest} [profileSettingsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfileSettings(profileSettingsRequest?: ProfileSettingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateProfileSettings(profileSettingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user email
         * @param {EmailRequest} [emailRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEmail(emailRequest?: EmailRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateUserEmail(emailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for confirmUserEmail operation in ProfileApi.
 * @export
 * @interface ProfileApiConfirmUserEmailRequest
 */
export interface ProfileApiConfirmUserEmailRequest {
    /**
     * 
     * @type {ConfirmUpdateEmailRequest}
     * @memberof ProfileApiConfirmUserEmail
     */
    readonly confirmUpdateEmailRequest?: ConfirmUpdateEmailRequest
}

/**
 * Request parameters for removeFcmToken operation in ProfileApi.
 * @export
 * @interface ProfileApiRemoveFcmTokenRequest
 */
export interface ProfileApiRemoveFcmTokenRequest {
    /**
     * 
     * @type {FcmToken}
     * @memberof ProfileApiRemoveFcmToken
     */
    readonly fcmToken?: FcmToken
}

/**
 * Request parameters for saveEvent operation in ProfileApi.
 * @export
 * @interface ProfileApiSaveEventRequest
 */
export interface ProfileApiSaveEventRequest {
    /**
     * 
     * @type {ActivityRequest}
     * @memberof ProfileApiSaveEvent
     */
    readonly activityRequest?: ActivityRequest
}

/**
 * Request parameters for updateFcmToken operation in ProfileApi.
 * @export
 * @interface ProfileApiUpdateFcmTokenRequest
 */
export interface ProfileApiUpdateFcmTokenRequest {
    /**
     * 
     * @type {FcmToken}
     * @memberof ProfileApiUpdateFcmToken
     */
    readonly fcmToken?: FcmToken
}

/**
 * Request parameters for updateProfileSettings operation in ProfileApi.
 * @export
 * @interface ProfileApiUpdateProfileSettingsRequest
 */
export interface ProfileApiUpdateProfileSettingsRequest {
    /**
     * 
     * @type {ProfileSettingsRequest}
     * @memberof ProfileApiUpdateProfileSettings
     */
    readonly profileSettingsRequest?: ProfileSettingsRequest
}

/**
 * Request parameters for updateUserEmail operation in ProfileApi.
 * @export
 * @interface ProfileApiUpdateUserEmailRequest
 */
export interface ProfileApiUpdateUserEmailRequest {
    /**
     * 
     * @type {EmailRequest}
     * @memberof ProfileApiUpdateUserEmail
     */
    readonly emailRequest?: EmailRequest
}

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @summary Confirm user email
     * @param {ProfileApiConfirmUserEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public confirmUserEmail(requestParameters: ProfileApiConfirmUserEmailRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).confirmUserEmail(requestParameters.confirmUpdateEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public confirmUserProfile(options?: any) {
        return ProfileApiFp(this.configuration).confirmUserProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get profile header
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfile(options?: any) {
        return ProfileApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get profile settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfileSettings(options?: any) {
        return ProfileApiFp(this.configuration).getProfileSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove FCM token
     * @param {ProfileApiRemoveFcmTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public removeFcmToken(requestParameters: ProfileApiRemoveFcmTokenRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).removeFcmToken(requestParameters.fcmToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request to delete a user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public requestDelete(options?: any) {
        return ProfileApiFp(this.configuration).requestDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProfileApiSaveEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public saveEvent(requestParameters: ProfileApiSaveEventRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).saveEvent(requestParameters.activityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable terminal target price dialog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public targetPriceDialogDisable(options?: any) {
        return ProfileApiFp(this.configuration).targetPriceDialogDisable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable terminal target price dialog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public targetPriceDialogEnable(options?: any) {
        return ProfileApiFp(this.configuration).targetPriceDialogEnable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable terminal target price
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public targetPriceDisable(options?: any) {
        return ProfileApiFp(this.configuration).targetPriceDisable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable terminal target price
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public targetPriceEnable(options?: any) {
        return ProfileApiFp(this.configuration).targetPriceEnable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update FCM token
     * @param {ProfileApiUpdateFcmTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateFcmToken(requestParameters: ProfileApiUpdateFcmTokenRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).updateFcmToken(requestParameters.fcmToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set profile settings
     * @param {ProfileApiUpdateProfileSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateProfileSettings(requestParameters: ProfileApiUpdateProfileSettingsRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).updateProfileSettings(requestParameters.profileSettingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user email
     * @param {ProfileApiUpdateUserEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateUserEmail(requestParameters: ProfileApiUpdateUserEmailRequest = {}, options?: any) {
        return ProfileApiFp(this.configuration).updateUserEmail(requestParameters.emailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
