import { FC } from "react";

import { TradingAccount } from "@/services/openapi";
import { useBonusesQuery } from "@/state/server/bonuses";

import { TransferForm } from "./transfer.form";

type Props = {
  accounts: TradingAccount[];
};

export const TransferContainer: FC<Props> = ({ accounts }) => {
  const { data: bonusData } = useBonusesQuery();

  if (!bonusData?.items) return null;

  return <TransferForm bonus={bonusData.items} accounts={accounts} />;
};
