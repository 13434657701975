import { FC } from "react";

import { Box } from "@/components/box";
import { CheckboxField, EmailField, PasswordField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Link } from "@/components/link";
import { FacebookButton, GoogleButton } from "@/components/social-button/social-button";
import { Text } from "@/components/text";
import { withCaptcha } from "@/decorators";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { authRoutes } from "@/routes/auth.routes";
import { PlatformInfo } from "@/services/openapi";

import { AuthBox, AuthSeparator, SocialButtonsBox } from "../styles";

enum SignInFields {
  EMAIL = "email",
  PASSWORD = "password",
  REMEMBER_ME = "rememberMe",
}

export interface ISignInFormValues {
  [SignInFields.EMAIL]: string;
  [SignInFields.PASSWORD]: string;
  [SignInFields.REMEMBER_ME]: boolean;
}

interface Props {
  onSubmit: (values: ISignInFormValues) => void;
  disabledSubmit: boolean;
  platformInfo: PlatformInfo;
}

const _SignInForm: FC<Props> = ({ onSubmit, disabledSubmit, platformInfo }) => {
  const { t } = useTranslation();
  const form = useHookForm<ISignInFormValues>({
    defaultValues: {
      [SignInFields.EMAIL]: "",
      [SignInFields.PASSWORD]: "",
      [SignInFields.REMEMBER_ME]: false,
    },
  });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      {platformInfo.signIn!.facebook!.isEnabled && platformInfo.signIn!.google!.isEnabled && (
        <>
          <SocialButtonsBox>
            <GoogleButton css={{ "@bp3": { width: "100%" } }} />
            <FacebookButton css={{ "@bp3": { width: "100%" } }} />
          </SocialButtonsBox>
          <AuthSeparator css={{ mt: "22px", mb: "22px" }}>
            <Box css={{ background: "#efefef", zIndex: 1, p: 8 }}>{t("auth.use-mail")}</Box>
          </AuthSeparator>
        </>
      )}
      <AuthBox css={{ mt: "22px", mb: "20px" }}>
        <EmailField name={SignInFields.EMAIL} bottomOffset="1" data-test="sign-in-email-input" />
        <PasswordField name={SignInFields.PASSWORD} bottomOffset="2" data-test="sign-in-password-input" />
        <CheckboxField name={SignInFields.REMEMBER_ME} label={t("auth.sign-in.remember-me")} />
      </AuthBox>
      <AuthBox css={{ display: "flex", justifyContent: "center", mb: "10px" }}>
        <SubmitButton wide checkValid={false} checkDirty={false} disabled={disabledSubmit} data-test="sign-in-btn">
          {t("auth.sign-in.title")}
        </SubmitButton>
      </AuthBox>
      <AuthBox css={{ mb: "24px" }}>
        <Text weight="bold" align="center">
          {t("auth.sign-up.account")}{" "}
          <Link to={authRoutes.signUp} color="primary" data-test="sign-in-sign-up-link">
            {t("auth.sign-up.sign-up")}
          </Link>
        </Text>
      </AuthBox>
      <AuthBox css={{ mb: "40px" }}>
        <Text weight="bold" align="center">
          <Link to={authRoutes.forgotPassword}>{t("auth.forgot-password.forgot-password")}</Link>
        </Text>
      </AuthBox>
    </HookForm>
  );
};

export const SignInForm = withCaptcha(_SignInForm);
