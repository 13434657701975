import { FC } from "react";

import { styled } from "@/styles";

import { LanguageSwitcherContainer } from "./container";

const Button = styled("button", {
  display: "flex",
  alignItems: "center",
  gap: "10px",
  p: "14px 18px",
  cursor: "pointer",
  borderRadius: "40px",
  elementTransition: "background",
  background: "none",
  border: "none",
  width: "100%",
  color: "$lite",
  whiteSpace: "nowrap",
  fontFamily: "$gilroy",
  fontWeight: "$bold",

  fontSize: "16px",

  "&:hover": {
    background: "#034D11",
  },

  variants: {
    light: {
      true: {
        background: "none",
        color: "$black",
        elementTransition: "color",

        "&:hover": {
          background: "none",
          color: "$darkGray",
        },
      },
    },
  },
});

const Icon = styled("img", {
  minWidth: "25px",
  height: "25px",
  borderRadius: "$round",
});

type Props = {
  onOpen: () => void;
  light?: boolean;
};

export const LanguageSwitcherButton: FC<Props> = ({ onOpen, light = false }) => {
  return (
    <LanguageSwitcherContainer>
      {({ icon, name }) => (
        <Button type="button" light={light} onClick={onOpen} data-test="language-switcher-button">
          <Icon src={icon!} alt={name!} />
          <div>{name}</div>
        </Button>
      )}
    </LanguageSwitcherContainer>
  );
};
