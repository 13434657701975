import { type FC, useState } from "react";

import { Stack } from "@/components/stack";
import { Text } from "@/components/text";
import { CompleteProfileInfo } from "@/features/deposit/components/info/complete-profile-info";
import { PaymentMethodItem } from "@/features/deposit/components/payment-method/payment-method-item";
import { useTranslation } from "@/hooks/translator.hook";
import { PaymentMethod } from "@/services/openapi";
import { useWithdrawWidgetsQuery } from "@/state/server/payment";

type Props = {
  choosePaymentMethod: (method: PaymentMethod) => void;
  items: {
    available: PaymentMethod[] | undefined;
    unavailable: PaymentMethod[] | undefined;
  };
};

export const PaymentMethodGrid: FC<Props> = ({ choosePaymentMethod, items }) => {
  const { t } = useTranslation();
  const [showPaymentComponent, setShowPaymentComponent] = useState<boolean>(true);
  const { data: widgetData } = useWithdrawWidgetsQuery();

  const onMethodClick = (method: PaymentMethod) => {
    choosePaymentMethod(method);
    setShowPaymentComponent(false);
  };

  const isAnyPaymentMethodAvailable = items.available && items.available.length > 0;

  if (!showPaymentComponent) return null;

  return (
    <div className="px-[25px] lg:px-0">
      <Stack>
        {widgetData && <CompleteProfileInfo {...widgetData} />}
        {isAnyPaymentMethodAvailable && (
          <div>
            <Text family="roboto" lineHeight="3" size={2} css={{ mb: "8px" }}>
              {t("withdrawal.payments.title")}
            </Text>
            <Text family="roboto" lineHeight="3" size={2} color="dimmed" css={{ mb: "16px" }}>
              {t("withdrawal.payments.description")}
            </Text>
          </div>
        )}
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          {items.available?.map(item => (
            <PaymentMethodItem item={item} key={`${item.id} ${item.reccuringId}`} onMethodClick={onMethodClick} />
          ))}
        </div>
      </Stack>
      {items.unavailable && items.unavailable.length > 0 && (
        <Stack css={{ mt: "32px" }}>
          <Text family="roboto" lineHeight="3" size={2} css={{ mb: "8px" }}>
            {t("withdrawal.payments.unavailable.title")}
          </Text>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {items.unavailable?.map(item => (
              <PaymentMethodItem item={item} key={`${item.id} ${item.reccuringId}`} onMethodClick={onMethodClick} />
            ))}
          </div>
        </Stack>
      )}
    </div>
  );
};
