import { useTheme } from "@/contexts/theme.context";
import { TerminalEventType } from "@/services/openapi";

import { useTerminalAccountContext } from "../contexts/account.context";
import { useCurrentSymbolContext } from "../contexts/current-symbol-context";
import { useSymbolsContext } from "../contexts/symbols.context";
import { filterTerminalSocket } from "../helpers/socket.commands";
import { useTerminalSocket } from "../hooks/socket.hook";
import { useLayoutContext } from "../layout/context";
import { useSignalContext } from "../signal/context";
import { Chart } from "./chart";
import { useChartContext } from "./context";
import { ChartLoader } from "./loader";

const Container: React.FC = () => {
  const { symbolInfo } = useCurrentSymbolContext();
  const { isMobile, isChartExpanded, setIsChartExpanded } = useLayoutContext();
  const { symbolsList } = useSymbolsContext();
  const { account } = useTerminalAccountContext();
  const { chartInfo, setChartInfo, lastInterval, chartDataIsLoaded } = useChartContext();
  const {
    hasSignal,
    openSignalForm,
    closeSignalChartDialog,
    isSignalChartDialogShown,
    chartLines,
    isTerminalTargetPricesEnabled,
    isSignalLinesShown,
    showSignalChartDialog,
  } = useSignalContext();

  const { isDarkTheme } = useTheme();

  const { lastJsonMessage: tickMessage } = useTerminalSocket({
    filter: e => filterTerminalSocket(e, [TerminalEventType.Tick]),
  });

  if (!chartDataIsLoaded) {
    return <ChartLoader />;
  }

  return (
    <Chart
      chartLines={chartLines}
      isTerminalTargetPricesEnabled={isTerminalTargetPricesEnabled}
      isSignalLinesShown={isSignalLinesShown}
      showSignalChartDialog={showSignalChartDialog}
      symbolInfo={symbolInfo}
      isMobile={isMobile}
      symbolsList={symbolsList}
      accountId={account.id!}
      accountCurrency={account.currency!}
      tradingServerId={account.serverId!}
      chartInfo={chartInfo}
      isChartExpanded={isChartExpanded}
      tickMessage={tickMessage}
      isDarkTheme={isDarkTheme}
      setChartInfo={setChartInfo}
      setIsChartExpanded={setIsChartExpanded}
      lastInterval={lastInterval}
      currencyDecimalScale={account.digits!}
      hasSignal={hasSignal}
      closeSignalChartDialog={closeSignalChartDialog}
      isSignalChartDialogShown={isSignalChartDialogShown}
      openSignalForm={openSignalForm}
    />
  );
};

Container.displayName = "ChartContainer";

export { Container as ChartContainer };
