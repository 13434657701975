import dayjs from "dayjs";

import {
  TerminalDealType,
  TerminalOpenOrderVolumeOriginal,
  TerminalOpenOrderVolumeOriginalType,
  TradingAccountTradeMode,
} from "@/services/openapi";

import { usePlaceOrderContext } from "./context";

// TODO: move to utils
const terminalFormatDate = (date: string) => {
  return dayjs(date).format("DD.MM.YY HH:mm:ss");
};

const getOriginalVolume = ({
  volumeLots,
  volumeMargin,
  volumeMode,
}: {
  volumeMode: TradingAccountTradeMode;
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  volumeMargin: ReturnType<typeof usePlaceOrderContext>["volumeMargin"];
}): TerminalOpenOrderVolumeOriginal => {
  if (volumeMode === TradingAccountTradeMode.Margin) {
    return { type: TerminalOpenOrderVolumeOriginalType.Margin, amount: volumeMargin! };
  }

  return { type: TerminalOpenOrderVolumeOriginalType.Lots, amount: volumeLots! };
};

const getPlaceOrderType = ({
  openPrice,
  ask,
  bid,
  isPendingOrder,
  isBuyOrder,
}: {
  isPendingOrder: ReturnType<typeof usePlaceOrderContext>["isPendingOrder"];
  isBuyOrder: ReturnType<typeof usePlaceOrderContext>["isBuyOrder"];
  ask: ReturnType<typeof usePlaceOrderContext>["ask"];
  bid: ReturnType<typeof usePlaceOrderContext>["bid"];
  openPrice: ReturnType<typeof usePlaceOrderContext>["openPrice"];
}): TerminalDealType => {
  if (!isPendingOrder || !openPrice) {
    return isBuyOrder ? TerminalDealType.Buy : TerminalDealType.Sell;
  }

  const currentPrice = isBuyOrder ? ask : bid;

  if (openPrice > currentPrice) {
    return isBuyOrder ? TerminalDealType.BuyStop : TerminalDealType.SellLimit;
  }

  return isBuyOrder ? TerminalDealType.BuyLimit : TerminalDealType.SellStop;
};

export { getOriginalVolume, getPlaceOrderType, terminalFormatDate };
