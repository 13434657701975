import { FC, useMemo } from "react";
import { useController } from "react-hook-form";

import { SubmitButton } from "@/app/form";
import { Box } from "@/components/box";
import { Flex } from "@/components/flex";
import { HookForm } from "@/components/form/hook-form";
import { InfoIcon } from "@/components/icons";
import { Text, TextBad } from "@/components/text";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { TradingAccountType, TradingServer, TradingServerPlatform } from "@/services/openapi";
import { ButtonGroup, Select } from "@/shared/ui";
import { Badge } from "@/shared/ui";

enum CreateAccountFields {
  TYPE = "type",
  PLATFORM = "platform",
  CURRENCY = "currency",
}

export interface ICreateAccountFormValues {
  [CreateAccountFields.TYPE]: TradingAccountType;
  [CreateAccountFields.PLATFORM]: TradingServerPlatform;
  [CreateAccountFields.CURRENCY]: string;
}

type Props = {
  type: TradingAccountType;
  servers: TradingServer[];
  onSubmit: (values: ICreateAccountFormValues) => void;
};

export const CreateAccountForm: FC<Props> = ({ onSubmit, type, servers }) => {
  const { t } = useTranslation();

  const defaultCurrency = servers
    .find(({ platformType }) => platformType === TradingServerPlatform.MetaTrader5)
    ?.accounts?.find(({ type }) => type === type)?.defaultCurrency;

  const form = useHookForm<ICreateAccountFormValues>({
    defaultValues: {
      [CreateAccountFields.TYPE]: type,
      [CreateAccountFields.PLATFORM]: TradingServerPlatform.MetaTrader5,
      [CreateAccountFields.CURRENCY]: defaultCurrency!,
    },
  });

  const { control, watch } = form;

  const { platform } = watch();

  const { field: typeField } = useController({ name: CreateAccountFields.TYPE, control });
  const { field: platformField } = useController({ name: CreateAccountFields.PLATFORM, control });
  const { field: platformCurrency } = useController({ name: CreateAccountFields.CURRENCY, control });

  const currencies = useMemo(
    () =>
      servers
        ?.find(
          currency =>
            currency?.platformType === platformField.value &&
            currency?.accounts?.find(({ type }) => type === typeField.value),
        )
        ?.accounts?.find(({ type }) => type === typeField.value)
        ?.currenciesInfo?.map(({ currency }) => ({
          value: currency,
          label: currency,
          icon: `${import.meta.env.VITE_CURRENCY_IMAGE_URL}/${currency}.png`,
        })),
    [platformField.value, servers, typeField.value],
  );

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <div className="flex max-w-[460px] flex-col">
        <ButtonGroup
          value={typeField.value}
          onValueChange={typeField.onChange}
          data-test="create-account-type-switcher"
        >
          <ButtonGroup.Item value={TradingAccountType.Real}>{t("common.real")}</ButtonGroup.Item>
          <ButtonGroup.Item value={TradingAccountType.Demo}>{t("common.demo")}</ButtonGroup.Item>
        </ButtonGroup>

        <Text css={{ fontSize: "16px", lineHeight: 1.2, mt: 32, mb: 16, fontFamily: "$roboto" }}>
          {t("accounts.account-currency")}
        </Text>
        <Select value={platformCurrency.value} onValueChange={platformCurrency.onChange} size="lg" className="w-full">
          {currencies?.map(({ label, value, icon }) => (
            <Select.Item key={value} value={value!}>
              <div className="flex gap-3">
                <img width={20} height={20} src={icon} className="object-cover" />
                {label}
              </div>
            </Select.Item>
          ))}
        </Select>

        <Text css={{ fontSize: "16px", lineHeight: 1.2, mt: 32, mb: 16, fontFamily: "$roboto" }}>
          {t("accounts.trading-platform")}
        </Text>
        <Select
          value={platformField.value}
          onValueChange={platformField.onChange}
          size="lg"
          className="w-full"
          data-test="create-account-platform-switcher"
        >
          <Select.Item value={TradingServerPlatform.MetaTrader5} asChild>
            <div className="flex w-full items-center justify-between">
              Doto ∙ MetaTrader 5
              <Select.ItemHiddenValue>
                <Badge variant="green" className="mr-3">
                  {t("common.recommended")}
                </Badge>
              </Select.ItemHiddenValue>
            </div>
          </Select.Item>
          <Select.Item value={TradingServerPlatform.MetaTrader4}>MetaTrader 4</Select.Item>
        </Select>

        {/* TODO: create separate component */}
        <Flex css={{ gap: 12, mt: 16 }} className="rounded-2xl border border-solid border-control-border p-4">
          <Box css={{ color: "$textSecondary" }}>
            <InfoIcon />
          </Box>
          <TextBad className="text-[14px] md:text-[16px]" color="dimmed" family="roboto" lineHeight="3">
            {platform === TradingServerPlatform.MetaTrader5
              ? t("accounts.servers.doto-mt5-description")
              : t("accounts.servers.mt-description", { version: 4 })}
          </TextBad>
        </Flex>

        <SubmitButton data-test="create-account-btn-action" className="mt-[32px]" variant="secondary">
          {t("button.create")}
        </SubmitButton>
      </div>
    </HookForm>
  );
};
