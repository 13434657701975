import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useScreenSize } from "@/hooks/screen-size.hook";
import { usePlatformLocalesQuery } from "@/state/server/platform";
import { useUpdateUserSettingMutation } from "@/state/server/profile/profile.mutations";

import { LanguageSwitcherBottomSheet } from "./bottom-sheet/language-switcher-bottom-sheet";
import { LanguageSwitcherDialog } from "./dialogs/language-switcher-dialog";

type Props = {
  open: boolean;
  onOpenChange: (value: boolean) => void;
};

export const LanguageSwitcherContainer: FC<Props> = ({ open, onOpenChange }) => {
  const { i18n } = useTranslation();
  const { data: platformLocales } = usePlatformLocalesQuery();
  const { isMobile } = useScreenSize();
  const { mutate } = useUpdateUserSettingMutation();

  const onChangeHandler = (localeCode: string) => {
    if (i18n.resolvedLanguage === localeCode) {
      onOpenChange(false);
    } else {
      i18n.changeLanguage(localeCode);

      mutate(
        { profileSettingsRequest: { languageCode: localeCode } },
        {
          onSuccess: () => {
            window.location.reload();
          },
        },
      );
    }
  };

  if (!platformLocales) return null;

  return (
    <>
      {!isMobile && (
        <LanguageSwitcherDialog
          open={open}
          onOpenChange={onOpenChange}
          languagesInfo={platformLocales}
          onLocaleChange={onChangeHandler}
        />
      )}

      {isMobile && (
        <LanguageSwitcherBottomSheet
          open={open}
          onOpenChange={onOpenChange}
          languagesInfo={platformLocales}
          onLocaleChange={onChangeHandler}
        />
      )}
    </>
  );
};
