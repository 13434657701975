import { type FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { TerminalCloseOrderType, TerminalDealType } from "@/services/openapi";
import { Button, toast, ToastIcons } from "@/shared/ui";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

import { getOrderTypeText } from "../../components/trading-table/order-type";

type Props = {
  accountId: string;
  orderId: number;
  orderType: TerminalDealType;
  volume: number;
  symbol: string;
  volumeDecimalScale: number;
};

const ClosePendingOrderButton: FC<Props> = ({ accountId, orderId, symbol, orderType, volume, volumeDecimalScale }) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useCloseTerminalOrdersMutation({
    onSuccess: () => {
      toast({
        icon: ToastIcons.SUCCESS,
        text: t("terminal.messages.pending-order-cancelled", {
          id: orderId,
          type: getOrderTypeText(t, orderType),
          volume: volume.toFixed(volumeDecimalScale),
          symbol,
        }),
      });
    },
  });

  const closeOrder = () => {
    mutate({
      tradingAccountId: accountId,
      terminalCloseOrderRequest: { id: orderId, type: TerminalCloseOrderType.Limit },
    });
  };

  return (
    <Button onClick={closeOrder} pending={isLoading} variant="flat" size="sm">
      {t("terminal.orders.delete-order")}
    </Button>
  );
};

export { ClosePendingOrderButton };
