import * as Collapsible from "@radix-ui/react-collapsible";
import { cva } from "class-variance-authority";
import { ComponentProps, type FC, type ReactNode } from "react";

import { cn } from "@/shared/styles";
import { Switch } from "@/shared/ui";

const captionText = cva("font-roboto text-[14px] leading-normal text-contrast-secondary")();

const Caption: FC<{ label: string; value: ReactNode; valueClassName?: string }> = ({
  label,
  value,
  valueClassName,
}) => {
  return (
    <div className="mt-2 flex items-center gap-1">
      <div className={captionText}>{label}</div>
      <div className={cn(captionText, valueClassName)}>{value}</div>
    </div>
  );
};

type Props = Pick<ComponentProps<typeof Switch>, "checked" | "onCheckedChange"> & {
  label: string;
  children: ReactNode;
  isMobile: boolean;
  dimmed?: boolean;
};

const PlaceOrderFieldBlock: FC<Props> = ({ children, label, checked, onCheckedChange, isMobile, dimmed }) => {
  return (
    <Collapsible.Root
      open={checked}
      className={cn("rounded-[16px] border border-card-border bg-card-bg py-3", dimmed && "border-0 bg-card-bg-accent")}
    >
      <label className="flex items-center justify-between gap-4 px-4">
        <div className="font-gilroy text-[16px] font-semibold leading-[1.2] text-contrast-primary lg:font-roboto lg:text-[14px] lg:font-normal lg:leading-normal">
          {label}
        </div>
        <Switch size={isMobile ? "md" : "sm"} checked={checked} onCheckedChange={onCheckedChange} />
      </label>
      <Collapsible.Content className="collapsible">
        {/* fixes box-shadow overflow */}
        <div className="mb-0.5 mt-3 px-4">{children}</div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

const Component = Object.assign(PlaceOrderFieldBlock, { Caption });

export { Component as PlaceOrderFieldBlock };
