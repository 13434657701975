import { type FC, type ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "@/contexts/auth.context";
import { LocaleInfo } from "@/services/openapi";
import { usePlatformLocalesQuery } from "@/state/server/platform";
import { useProfileSettingsQuery } from "@/state/server/profile";

type Props = {
  children: (locale: LocaleInfo) => ReactNode;
};

const LanguageSwitcherContainer: FC<Props> = ({ children }) => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<LocaleInfo>();
  const { isAuthenticated } = useAuth();
  const { data: platformLocales } = usePlatformLocalesQuery();
  const { data: settings } = useProfileSettingsQuery({ enabled: isAuthenticated });

  useEffect(() => {
    if (settings) {
      setCurrentLanguage(settings.locale);
    } else {
      platformLocales?.items?.find(item => {
        if (item.code === i18n.resolvedLanguage) setCurrentLanguage(item);
      });
    }
  }, [settings, platformLocales, i18n.resolvedLanguage]);

  if (!platformLocales || !currentLanguage) return null;

  return children(currentLanguage) as JSX.Element;
};

export { LanguageSwitcherContainer };
