import { useEffect } from "react";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";

import { useLayoutContext } from "../../layout/context";
import { useChartContext } from "../context";

// https://www.tradingview.com/charting-library-docs/latest/ui_elements/Toolbars/
// https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.IChartingLibraryWidget/#createbutton
// https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.CreateHTMLButtonOptions

export const useChartToggleButton = ({
  isMobile,
  isChartExpanded,
  setChartInfo,
  setIsChartExpanded,
}: {
  isMobile: boolean;
  isChartExpanded: boolean;
  setIsChartExpanded: ReturnType<typeof useLayoutContext>["setIsChartExpanded"];
  setChartInfo: ReturnType<typeof useChartContext>["setChartInfo"];
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isMobile) {
      window.tvWidget.headerReady().then(() => {
        const button = window.tvWidget.createButton({ align: "right", useTradingViewStyle: false });
        button.addEventListener("click", () => {
          setIsChartExpanded(prev => !prev);

          window.tvWidget.save(state =>
            setChartInfo({ data: state, range: window.tvWidget.activeChart().getVisibleRange() }),
          );
        });

        button.innerHTML = isChartExpanded
          ? renderToString(
              <button className="doto-collapse-chart-button">
                {/* FIXME: */}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.52827 7.52827C7.78862 7.26792 8.21073 7.26792 8.47108 7.52827L11.9997 11.0569L15.5283 7.52827C15.7886 7.26792 16.2107 7.26792 16.4711 7.52827C16.7314 7.78862 16.7314 8.21073 16.4711 8.47108L12.9425 11.9997L16.4711 15.5283C16.7314 15.7886 16.7314 16.2107 16.4711 16.4711C16.2107 16.7314 15.7886 16.7314 15.5283 16.4711L11.9997 12.9425L8.47108 16.4711C8.21073 16.7314 7.78862 16.7314 7.52827 16.4711C7.26792 16.2107 7.26792 15.7886 7.52827 15.5283L11.0569 11.9997L7.52827 8.47108C7.26792 8.21073 7.26792 7.78862 7.52827 7.52827Z"
                    fill="currentColor"
                  />
                </svg>
              </button>,
            )
          : renderToString(
              <button className="doto-expand-mode-button">
                {t("terminal.chart.expand-button")}
                {/* FIXME: */}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M13.5 6H18M18 6V10.5M18 6L6 18M6 18V13.5M6 18H10.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>,
            );
      });
    }
  }, []);
};
