import { Flex } from "@/components/flex";
import { styled } from "@/styles";

export const HeaderWrapper = styled("div", {
  scrollBehavior: "smooth",
});

export const HeadMain = styled(Flex, {
  alignItems: "center",
  justifyContent: "space-between",
  margin: "0 -24px",
  padding: "0 24px",

  h1: {
    transition: "all 0.5s cubic-bezier(0.22, 1, 0.36, 1)",
  },
});

export const Head = styled(HeadMain, {
  background: "transparent",

  h1: {
    margin: 0,
    opacity: 1,
  },

  "@bp2": { margin: "0", padding: "0" },

  variants: {
    animate: {
      true: {
        h1: {
          opacity: 0,
        },
      },
    },
  },
});

export const HeadStatic = styled(HeadMain, {
  position: "fixed",
  top: "0px",
  width: "100%",
  paddingBottom: "0",
  backgroundColor: "$bgBack",
  borderBottom: "1px solid transparent",
  transition: "all 0.5s cubic-bezier(0.22, 1, 0.36, 1)",
  willChange: "transform",

  h1: {
    margin: "0 auto",
    transform: "scale(0.6)",
    opacity: 0,
  },

  button: {
    transition: "all 0.5s cubic-bezier(0.22, 1, 0.36, 1)",
    opacity: 0,
  },

  variants: {
    animate: {
      true: {
        paddingBottom: "9px",
        borderBottom: "1px solid $bgBorder",

        h1: {
          opacity: 1,
        },

        button: {
          opacity: 1,
        },
      },
    },
  },
});
