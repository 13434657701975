import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { Trans } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { SelectOptionType } from "@/components/form";
import { NumberField } from "@/components/form/fields";
import { AccountSelectDataType } from "@/components/form/fields/account-select";
import { ErrorMessage } from "@/domains/ui-kit";
import { WithdrawalFormFields } from "@/features/withdrawal/components/form/withdrawal.page.form.constants";
import * as Styled from "@/features/withdrawal/components/form/withdrawal.page.form.styled";
import { useTranslation } from "@/hooks/translator.hook";
import { ExternalKycStatus, MaximumLimitDescription, PaymentMethod, TradingAccount } from "@/services/openapi";
import { Tooltip } from "@/shared/ui";
import { useWithdrawFeeQuery } from "@/state/server/payment";
import { useProfileData } from "@/state/server/profile/profile.hooks";

type Props = {
  paymentMethod: PaymentMethod;
  withdrawLimit?: MaximumLimitDescription;
  accountsOptions: Array<SelectOptionType<AccountSelectDataType>>;
  account: TradingAccount;
  paymentId: any;
  currentLimit: number | undefined;
};

export const FieldAmount: FC<Props> = ({
  paymentId,
  account,
  accountsOptions,
  paymentMethod,
  withdrawLimit,
  currentLimit,
}) => {
  const { t } = useTranslation();

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { kycStatus } = useProfileData();

  const amount = watch(WithdrawalFormFields.AMOUNT);
  const { data: fee } = useWithdrawFeeQuery(
    {
      paymentMethodId: paymentId,
      accountId: account?.id,
      amount: amount?.toString(),
    },
    {
      enabled: !!paymentId && !!account && !!amount,
    },
  );

  const renderFee = fee?.value ? fee?.value : 0;
  const renderTotal = amount - renderFee;
  const isErrorAmountMax = errors[WithdrawalFormFields.AMOUNT]?.type === "max";
  const isErrorAmountRequired = errors[WithdrawalFormFields.AMOUNT]?.type === "required";
  const limits = useMemo(
    () => paymentMethod?.details?.limits?.find(({ currency }) => currency === account?.currency),
    [account?.currency, paymentMethod?.details?.limits],
  );
  const max = useMemo(() => withdrawLimit?.maxPaymentMethodAmount, [withdrawLimit?.maxPaymentMethodAmount]);
  const amountNotSave = !!(withdrawLimit?.safeToWithdrawAmount && renderTotal >= withdrawLimit?.safeToWithdrawAmount);
  const maxSaveAmount =
    max && withdrawLimit?.safeToWithdrawAmount && max > withdrawLimit?.safeToWithdrawAmount
      ? withdrawLimit?.safeToWithdrawAmount
      : max;
  const kycIsApproved = kycStatus === ExternalKycStatus.Approved;

  const handleSetAmount = (value?: number | null) =>
    value && setValue(WithdrawalFormFields.AMOUNT, value, { shouldValidate: true });

  const MinMax = () => (
    <Trans
      t={t}
      i18nKey="withdrawal.form.amount.minMax"
      values={{
        amount_from: limits?.from,
        currency_from: account?.currency,
        amount_to: max,
        currency_to: account?.currency,
      }}
      components={{
        minSpan: <Styled.ClickSpan onClick={() => handleSetAmount(limits?.from)} />,
        maxSpan: <Styled.ClickSpan onClick={() => handleSetAmount(max)} />,
      }}
    />
  );

  return (
    <>
      <Tooltip
        open={amountNotSave}
        defaultOpen={amountNotSave}
        side="right"
        content={
          <Styled.TooltipSaveAmount>
            <Trans
              t={t}
              i18nKey="withdrawal.form.amount.tooltip"
              values={{
                symbol: account?.currency,
              }}
              components={{
                click: <Styled.ClickSpan onClick={() => handleSetAmount(withdrawLimit?.safeToWithdrawAmount)} />,
                value: <NumberFormat value={withdrawLimit?.safeToWithdrawAmount} />,
              }}
            />
          </Styled.TooltipSaveAmount>
        }
      >
        <div>
          <NumberField
            name={WithdrawalFormFields.AMOUNT}
            thousandSeparator=","
            placeholder={`0 ${account?.currency ? account.currency : ""}`}
            rules={{
              required: t("form-errors.required-error")!,
              min: limits?.from?.toString() || undefined,
              max: max,
            }}
          />
        </div>
      </Tooltip>

      {errors[WithdrawalFormFields.AMOUNT] ? (
        !isErrorAmountRequired ? (
          <Styled.ErrorMessage as={ErrorMessage} hasError>
            {isErrorAmountMax ? (
              max ? (
                <MinMax />
              ) : (
                <Trans
                  t={t}
                  i18nKey="withdrawal.form.amount.errorMax"
                  values={{ amount: max, currency: account?.currency }}
                  components={{
                    clickSpan: <Styled.ClickSpan onClick={() => handleSetAmount(max)} />,
                  }}
                />
              )
            ) : (
              <MinMax />
            )}
          </Styled.ErrorMessage>
        ) : null
      ) : max && kycIsApproved ? (
        <Styled.MinMax css={{ mt: "8px" }}>
          {amount && amount > 0 ? (
            paymentMethod.fields && paymentMethod.fields.length > 0 ? (
              t("withdrawal.form.amount.amountPayoutFee", {
                fee_amount: renderFee,
                fee_currency: account?.currency,
                amount_amount: renderTotal.toFixed(2),
                amount_currency: account?.currency,
              })
            ) : (
              t("withdrawal.form.amount.amountPayoutNoFee", {
                amount_amount: renderTotal.toFixed(2),
                amount_currency: account?.currency,
              })
            )
          ) : (
            <MinMax />
          )}
        </Styled.MinMax>
      ) : null}

      {kycStatus != ExternalKycStatus.Approved && currentLimit! < amount && (
        <Styled.MinMax>{t("withdrawal.form.amount.verify")}</Styled.MinMax>
      )}
    </>
  );
};
