import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { NumberFormat } from "@/app/components";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { Button } from "@/shared/ui";
import { useMaximumWithdrawAmountQuery } from "@/state/server/payment";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { Props } from "./types";
import { DepositVerificationBottomSheet } from "./verification-bottomsheet";
import { DepositVerificationDialog } from "./verification-dialog";

export const fromDepositState = "fromDeposit";

export const VerificationContainer: FC<Props> = ({
  open,
  onOpenChange,
  submitDeposit,
  account,
  recurringId,
  paymentMethodId,
  depositLimit,
  isSurveyCompleted,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const { featuresInfo } = useProfileData();
  const isSurveyMandatory = featuresInfo?.mandatorySurvey;
  const verificationRoute = isSurveyCompleted ? onboardingRoutes.kyc : onboardingRoutes.onboarding;

  const { data: withdrawLimit } = useMaximumWithdrawAmountQuery(
    {
      accountId: account.id!,
      paymentMethodId: paymentMethodId,
      recurringId,
    },
    {
      enabled: !paymentMethodId && !!open && !!depositLimit,
    },
  );

  const title = !isSurveyMandatory
    ? t("deposit.verification.dialog.mandatory.title")
    : t("deposit.verification.dialog.title");

  const description = !isSurveyMandatory ? (
    t("deposit.verification.dialog.mandatory.description")
  ) : (
    <Trans
      i18nKey="deposit.verification.dialog.description"
      values={{
        currency: account.currency,
      }}
      components={{
        depositAmount: <NumberFormat value={depositLimit} decimalScale={account.digits} />,
        withdrawAmount: (
          <NumberFormat value={withdrawLimit?.extendedInfo?.withdrawCurrentLimitNoKyc} decimalScale={account.digits} />
        ),
      }}
    />
  );

  if (!featuresInfo && !withdrawLimit) return null;

  if (isMobile)
    return (
      <DepositVerificationBottomSheet open={open} onOpenChange={onOpenChange} title={title} description={description}>
        <>
          {isSurveyMandatory && (
            <Button variant="secondary" className="w-full" onClick={submitDeposit}>
              {t("deposit.verification.dialog.button-payment")}
            </Button>
          )}

          <Button variant="tertiary" className="mt-3 w-full" asChild>
            <Link to={verificationRoute} state={fromDepositState}>
              {t("deposit.verification.dialog.button-verify")}
            </Link>
          </Button>
        </>
      </DepositVerificationBottomSheet>
    );

  return (
    <DepositVerificationDialog open={open} onOpenChange={onOpenChange} title={title} description={description}>
      {isSurveyMandatory && (
        <Button variant="secondary" className="w-full" onClick={submitDeposit}>
          {t("deposit.verification.dialog.button-payment")}
        </Button>
      )}

      <Button variant="tertiary" className="mt-3 w-full" asChild>
        <Link to={verificationRoute} state={fromDepositState}>
          {t("deposit.verification.dialog.button-verify")}
        </Link>
      </Button>
    </DepositVerificationDialog>
  );
};
