import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useGoogleCaptcha } from "@/hooks/captcha.hook";
import { useLocalStorage } from "@/hooks/localstorage.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { authRoutes } from "@/routes/auth.routes";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { ErrorCodes } from "@/services/openapi";
import { toast, ToastIcons } from "@/shared/ui";
import { useSignInMutation } from "@/state/server/auth";
import { usePlatformData } from "@/state/server/platform/platform.hooks";

import { AuthFormContainer } from "../auth-form.container";
import {
  getTwoFactorSearchParams,
  TwoFactorLocalStorageSignInType,
  TwoFactorMode,
} from "../two-factor/two-factor.helpers";
import { ISignInFormValues, SignInForm } from "./signin.form";

export const SignInContainer: FC = () => {
  const { t } = useTranslation();
  const { captchaToken, disabledSubmit, hasCaptcha, onVerify, refreshCaptcha, refreshReCaptchaValue } =
    useGoogleCaptcha();
  const navigate = useNavigate();
  const platformInfo = usePlatformData();
  const [_, setLocalStorage] = useLocalStorage<TwoFactorLocalStorageSignInType>(TwoFactorMode.SIGN_IN, {});

  const { mutateAsync: signIn } = useSignInMutation({
    showErrorMessage: false,
  });

  const submitSignIn = useCallback(
    (values: ISignInFormValues) => {
      const captcha = hasCaptcha ? { captcha: { challengeGoogleRecaptchaV3: { token: captchaToken } } } : {};
      return signIn(
        { logInRequest: { ...values, ...captcha }, email: values.email },
        {
          onSuccess: async () => {
            refreshCaptcha();
            navigate(cabinetRoutes.dashboard);
          },
          onError: (error, requestValues) => {
            if (hasCaptcha) {
              refreshCaptcha();
            }
            if (error.code === ErrorCodes.RequiresTwoFactor) {
              const { logInRequest } = requestValues;
              const { captcha, ...restValues } = logInRequest!;
              setLocalStorage({ ...restValues, captcha: { token: error.payload } });
              navigate({ pathname: authRoutes.twoFactor, search: getTwoFactorSearchParams(TwoFactorMode.SIGN_IN) });
              return;
            }
            if (error.errorMessage) toast({ text: error.errorMessage, icon: ToastIcons.ERROR });
          },
        },
      );
    },
    [signIn, captchaToken, hasCaptcha, navigate, refreshCaptcha, setLocalStorage],
  );

  return (
    <AuthFormContainer title={t("auth.sign-in.title")} backLink>
      <SignInForm
        platformInfo={platformInfo}
        onSubmit={submitSignIn}
        disabledSubmit={disabledSubmit}
        onVerify={onVerify}
        refreshReCaptchaValue={refreshReCaptchaValue}
      />
    </AuthFormContainer>
  );
};
