import { FC } from "react";

import { NoContentMessage } from "@/components/no-content-message";
import { useTranslation } from "@/hooks/translator.hook";
import { TradingAccountType } from "@/services/openapi";

import { CreateAccountButton } from "../../components/create-account/create-account-button";

type Props = {
  onCreateAccount: (accountType: TradingAccountType) => void;
};

const RealAccountsEmptyState: FC<Props> = ({ onCreateAccount }) => {
  const { t } = useTranslation();

  return (
    <NoContentMessage
      title={t("accounts.no-items.no-real-title")}
      description={t("accounts.no-items.no-real-description")}
    >
      <CreateAccountButton type={TradingAccountType.Real} onClick={onCreateAccount} />
    </NoContentMessage>
  );
};

export { RealAccountsEmptyState };
