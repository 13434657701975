import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import logoDark from "@/assets/images/LogoDark.svg";
import { Flex } from "@/components/flex";
import { headerStyles, headerWrapperStyles } from "@/components/header/styles";
import { Link } from "@/components/link";
import { useOndoardingContext } from "@/contexts/onboarding,context";
import { fromDepositState } from "@/features/deposit/components/verification";
import { LanguageSwitcherButton, LanguageSwitcherContainer } from "@/features/language-switcher";
import { onExitDialog } from "@/features/onboarding/components/exit-dialog";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTerminalAutoRoute } from "@/hooks/terminal-auto-route.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { Button } from "@/shared/ui";
import { useProfileHeaderQuery } from "@/state/server/profile";
import { styled } from "@/styles";

const Wrapper = styled("div", headerWrapperStyles);

const StyledHeader = styled("header", headerStyles);

export const OnboardingHeader: FC = () => {
  const { isMobile } = useScreenSize();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { currentStepIndex } = useOndoardingContext();
  const { open: openTerminal } = useTerminalAutoRoute();
  const { data: profile } = useProfileHeaderQuery();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [exitCount, setExitCount] = useState(1);
  const isFromDeposit = state === fromDepositState;
  const isSurveyCompleted = profile?.options?.isSurveyCompleted;
  const isSurveyMandatory = profile?.featuresInfo?.mandatorySurvey;
  const isCloseButtonVisible = !isSurveyMandatory || isSurveyCompleted;

  const closeButtonAction = () => {
    if (!isFromDeposit) {
      track(amplitudeEvents.kyc.close(currentStepIndex + 1));
      openTerminal();
    } else if (exitCount < 2) {
      onExitDialog(true);
      setExitCount(prevState => prevState + 1);
    } else {
      track(amplitudeEvents.kyc.close(currentStepIndex + 1));
      navigate(-1);
    }
  };

  return (
    <Wrapper>
      <StyledHeader>
        <Flex css={{ alignItems: "center" }}>
          {isSurveyMandatory && !isSurveyCompleted ? (
            <img alt="doto" src={logoDark} height="28" width="102" />
          ) : (
            <Link to={cabinetRoutes.dashboard} css={{ display: "flex" }}>
              {/* TODO: separate component */}
              <img alt="doto" src={logoDark} height="28" width="102" />
            </Link>
          )}
        </Flex>
        <Flex css={{ alignItems: "center", gap: "20px" }}>
          {!isMobile && (
            <>
              <LanguageSwitcherButton light onOpen={() => setOpen(true)} />
              <LanguageSwitcherContainer onOpenChange={setOpen} open={open} />
            </>
          )}

          {isCloseButtonVisible && (
            <Button variant="secondary" size="sm" onClick={closeButtonAction}>
              {t("button.close")}
            </Button>
          )}
        </Flex>
      </StyledHeader>
    </Wrapper>
  );
};
