import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { TerminalAccountSymbols, TerminalSymbols } from "@/services/openapi";

import { useAddChartSymbol } from "./hooks/add-chart-symbol.hook";

const isValidSymbol = ({ symbol, symbols }: { symbol: string | null; symbols: TerminalSymbols }) => {
  const symbolsStrings = symbols.symbols!.map(({ symbol }) => symbol!);

  return !!(symbol && symbolsStrings.includes(symbol));
};

const useTerminalHandleSymbol = ({
  favorites,
  symbols,
  accountId,
}: {
  favorites: TerminalAccountSymbols;
  symbols: TerminalSymbols;
  accountId: string;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const symbolUrl = searchParams.get("symbol");

  const [symbol, setSymbol] = useState<string>();
  const [isValidating, setIsValidating] = useState<boolean>(true);

  const changeSymbol = useCallback(
    (symbol: string) => {
      setSymbol(symbol);
      setSearchParams(
        prevParams => {
          prevParams.set("symbol", symbol);
          return prevParams;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const { isLoading, addChartSymbol } = useAddChartSymbol({ setSymbol: changeSymbol, accountId });

  useEffect(() => {
    const handleSymbol = () => {
      // if url symbol is not valid or empty
      if (!isValidSymbol({ symbol: symbolUrl, symbols })) {
        if (symbols.symbols!.map(({ symbol }) => symbol).includes(favorites.chartsSelected)) {
          changeSymbol(favorites.chartsSelected!);
          return;
        }

        changeSymbol(symbols.symbols![0].symbol!);
        return;
      }

      // if url symbol exists in favorites
      if (favorites.charts!.includes(symbolUrl!)) {
        setSymbol(symbolUrl!);
        return;
      }

      // if url symbol is not in favorites and valid
      changeSymbol(symbolUrl!);
      addChartSymbol(symbolUrl!);
    };
    handleSymbol();
    setIsValidating(false);
  }, []);

  return {
    changeSymbol,
    symbol,
    isLoading: isValidating || isLoading,
  };
};

export { useTerminalHandleSymbol };
