import Big from "big.js";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { NumberFormat } from "@/app/components";
import { HookForm } from "@/app/form";
import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { BalanceFormat } from "@/components/balance-format";
import { Box } from "@/components/box";
import { SelectOptionType } from "@/components/form";
import { NumberField, SelectField } from "@/components/form/fields";
import { AccountSelect, getAccountOptions } from "@/components/form/fields/account-select/account-select";
import { Tab, Tabs } from "@/components/tabs";
import { Text } from "@/components/text";
import { useDepositContext } from "@/contexts/deposit.context";
import { getBonusMainPercent } from "@/entities/bonuses/helpers";
import { BonusTooltip } from "@/entities/bonuses/tooltip";
import { calculateBonus } from "@/features/deposit/helpers/helpers";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { BonusUserPlatform, PaymentMethod, TradingAccount } from "@/services/openapi";
import { Switch } from "@/shared/ui";
import { Button } from "@/shared/ui";
import { useDepositFeeQuery, useMaximumDepositAmountQuery } from "@/state/server/payment";
import { useRateQuery } from "@/state/server/rate";

import { VerificationContainer } from "../verification";

enum DepositFormFields {
  PAYMENT_METHOD_ID = "paymentMethodId",
  AMOUNT = "amount",
  ACCOUNT_ID = "accountId",
  IS_BONUS_ACCEPTED = "isBonusAccepted",
}

export interface DepositFormValues {
  [DepositFormFields.PAYMENT_METHOD_ID]: string;
  [DepositFormFields.AMOUNT]: string;
  [DepositFormFields.ACCOUNT_ID]: string;
  [DepositFormFields.IS_BONUS_ACCEPTED]: boolean;
}

type Props = {
  accounts: TradingAccount[];
  onSubmit: (values: DepositFormValues) => void;
  paymentMethods: PaymentMethod[];
  defaultAccount: TradingAccount;
  bonus: BonusUserPlatform[];
  isSurveyCompleted: boolean;
  isKycCompleted: boolean;
  amountChips: { [key: string]: number[] };
  noKycAmountLimit: boolean;
};

export const DepositForm: FC<Props> = ({
  accounts,
  onSubmit: onDeposit,
  paymentMethods,
  defaultAccount,
  isSurveyCompleted,
  bonus,
  amountChips,
  isKycCompleted,
  noKycAmountLimit,
}) => {
  const { t } = useTranslation();
  const context = useDepositContext();
  const { paymentMethod, setPaymentMethod, paymentOptions } = context;
  const [amountTab, setAmountTab] = useState("");
  const [isBonusAccepted, setIsbonusAccepted] = useState(true);
  const [
    isVerificationDialogVisible,
    { onOpenChange: setIsVerificationDialogVisible, close: closeVerificationDialog },
  ] = useDisclosure();

  const selectedOption = useMemo(
    () =>
      paymentOptions?.find(item => {
        return item.value === `${paymentMethod!.id}${paymentMethod!.reccuringId}`;
      }),
    [paymentMethod, paymentOptions],
  );

  const form = useHookForm<DepositFormValues>({
    defaultValues: {
      [DepositFormFields.PAYMENT_METHOD_ID]: `${selectedOption?.value}` || "",
      [DepositFormFields.ACCOUNT_ID]: `${defaultAccount?.id}` || "",
      [DepositFormFields.AMOUNT]: "",
    },
  });
  const { setValue, watch, trigger } = form;

  const { accountId: accountIdValue, paymentMethodId: paymentMethodIdValue, amount: amountValue } = watch();
  const amountNumber = new Big(Number(amountValue));
  const currentAccount = useMemo(
    () => accounts.find(account => account.id === accountIdValue),
    [accountIdValue, accounts],
  );

  const { data: fee } = useDepositFeeQuery(
    {
      paymentMethodId: selectedOption?.data,
      accountId: accountIdValue,
      amount: amountValue,
    },
    {
      enabled: !!paymentMethodIdValue && !!accountIdValue && !!amountValue,
    },
  );
  const { data: depositLimit } = useMaximumDepositAmountQuery(
    {
      accountId: accountIdValue,
      paymentMethodId: selectedOption?.data,
      recurringId: paymentMethod?.reccuringId!,
    },
    {
      enabled: noKycAmountLimit && !!accountIdValue && !!selectedOption?.data,
    },
  );
  const currentLimitBeforeKyc = depositLimit?.extendedInfo?.depositCurrentLimitNoKyc;

  const accountsOptions: SelectOptionType[] = useMemo(() => getAccountOptions(accounts), [accounts]);

  const { data: rateData } = useRateQuery(
    { from: "USD", to: currentAccount?.currency! },
    {
      enabled: !!currentAccount?.currency && currentAccount?.currency !== "USD",
    },
  );

  const onChangeTab = (value: string) => {
    setAmountTab(value);

    track(amplitudeEvents.deposit.amount);
    setValue(DepositFormFields.AMOUNT, value, { shouldValidate: true, shouldTouch: true });
  };

  useEffect(() => {
    const changedMethod = paymentMethods.find(item => `${item.id}${item.reccuringId}` === paymentMethodIdValue);
    setPaymentMethod(changedMethod!);
  }, [paymentMethodIdValue, paymentMethods]);

  useEffect(() => {
    if (amountValue === amountTab || amountTab === "") return;
    else setAmountTab("");
  }, [amountValue]);

  const bonusPercent = useMemo(() => {
    if (currentAccount?.currency === "USD") return calculateBonus(amountValue, bonus[0]);
    else if (rateData?.rate) {
      const value = (+amountValue / +rateData?.rate).toString();
      return calculateBonus(value, bonus[0]);
    }
  }, [amountValue, bonus, currentAccount?.currency, rateData?.rate]);

  const amountOptions = amountChips[currentAccount?.currency!];

  const limits = useMemo(
    () => paymentMethod?.details?.limits?.find(({ currency }) => currency === currentAccount?.currency),
    [currentAccount?.currency, paymentMethod?.details?.limits],
  );

  const needVerifyToDeposit =
    noKycAmountLimit &&
    currentLimitBeforeKyc != undefined &&
    amountValue != null &&
    amountNumber.gt(currentLimitBeforeKyc);

  useEffect(() => {
    trigger(DepositFormFields.AMOUNT);
  }, [paymentMethod]);

  const onSubmit = useCallback(
    (values: DepositFormValues) => {
      if (isKycCompleted) return onDeposit({ ...values, isBonusAccepted: isBonusAccepted });

      return setIsVerificationDialogVisible(true);
    },
    [isBonusAccepted, isKycCompleted, onDeposit, setIsVerificationDialogVisible],
  );

  return (
    <HookForm form={form} onSubmit={onSubmit} className="xl:flex xl:gap-8">
      <div className="flex w-full max-w-[460px] flex-col gap-6 md:gap-8">
        <div>
          <Text family="roboto" lineHeight="3" css={{ mb: "16px" }}>
            {t("deposit.form.payment.title")}
          </Text>
          <div data-test="deposit-payment-option-select">
            <SelectField
              name={DepositFormFields.PAYMENT_METHOD_ID}
              options={paymentOptions!}
              rules={{ required: t("form-errors.required-error")! }}
            />
          </div>
        </div>
        <div>
          <Text family="roboto" lineHeight="3" css={{ mb: "16px" }}>
            {t("deposit.form.account.title")}
          </Text>
          <div data-test="deposit-account-select">
            <AccountSelect
              name={DepositFormFields.ACCOUNT_ID}
              options={accountsOptions}
              resetFieldOnOptionsChanged={false}
              rules={{ required: t("form-errors.required-error")! }}
            />
          </div>
        </div>
        <div>
          <Text family="roboto" lineHeight="3" css={{ mb: "16px" }}>
            {t("deposit.form.amount.title")}
          </Text>
          <Tabs value={amountTab} onChange={onChangeTab} variant="tile" stretch css={{ mb: 16, gap: "16px" }}>
            {amountOptions.map(option => (
              <Tab
                key={option}
                numeric
                rounded
                value={option.toString()}
                label={`${option}`}
                data-test={`deposit-tab-${option}`}
                currency={currentAccount?.currency!}
              />
            ))}
          </Tabs>
          <div>
            <NumberField
              name={DepositFormFields.AMOUNT}
              thousandSeparator=","
              placeholder={`${t("deposit.form.amount.minimumPayment")!}: ${limits?.from} ${currentAccount?.currency!}`}
              rules={{
                required: `${t("form-errors.min-payment")} ${limits?.from?.toString()} ${currentAccount?.currency!}`,
                min: {
                  message: `${t("form-errors.min-payment")} ${limits?.from?.toString()} ${currentAccount?.currency!}`,
                  value: limits?.from?.toString()!,
                },
                max: {
                  message: `${t("form-errors.max-payment")} ${limits?.to?.toString()} ${currentAccount?.currency!}`,
                  value: limits?.to?.toString()!,
                },
              }}
              data-test="deposit-amount-input"
            />

            {bonus.length != 0 && !!bonusPercent && (
              <div className="mt-4 flex-col gap-1 ">
                <div className="flex items-center justify-between rounded-[16px] border bg-white px-4 py-3">
                  <label className="flex w-full items-center justify-between gap-3 font-roboto text-[16px] leading-[1.5] text-text">
                    <div className="flex items-center gap-2">
                      {t("deposit.form.get-bonus")}
                      <BonusTooltip
                        bonus={bonus[0]}
                        actionText={t("deposit.bonus.button")}
                        content={t("deposit.bonus.description", {
                          percent: getBonusMainPercent(bonus[0]!),
                        })}
                      />
                    </div>
                    <Switch size="sm" checked={isBonusAccepted} onCheckedChange={setIsbonusAccepted} />
                  </label>
                </div>
              </div>
            )}
          </div>
          <Box css={{ mt: 8 }}>
            {!!amountValue &&
              (needVerifyToDeposit ? (
                <Text as="p" family="roboto" color="gray" css={{ mb: 8 }} size={{ "@initial": "1", "@bp3": "2" }}>
                  {t("deposit.form.amount.verify")}
                </Text>
              ) : (
                <Text as="p" color="gray" css={{ mb: 8 }} size={{ "@initial": "1", "@bp3": "2" }}>
                  <Text family="roboto" lineHeight="3" css={{ display: "inline-block", color: "inherit" }}>
                    {`${t("deposit.form.amount.toBeDeposited")}:`}&nbsp;
                  </Text>
                  <Text
                    css={{
                      display: "inline-block",
                      fontSize: "inherit",
                      "& span": {
                        fontSize: "inherit",
                        color: "$textSecondary",
                        fontWeight: "$normal",
                        fontFamily: "$roboto",
                      },
                    }}
                  >
                    <BalanceFormat value={+amountValue || 0} currency={currentAccount?.currency!} />
                  </Text>
                  .{" "}
                  <Text
                    as="p"
                    color="gray"
                    family="roboto"
                    lineHeight="3"
                    data-test="deposit-fee"
                    css={{ display: "inline-block" }}
                    size={{ "@initial": "1", "@bp3": "2" }}
                  >
                    {`${t("deposit.form.amount.fee")}: ${fee?.value ? fee?.value : 0}%`}
                  </Text>
                </Text>
              ))}
          </Box>
        </div>

        {needVerifyToDeposit ? (
          <div>
            <Button size="sm" data-test="deposit-btn-verify" asChild>
              <Link to={onboardingRoutes.kyc}>{t("button.go-to-verify")}</Link>
            </Button>
          </div>
        ) : (
          <div>
            <Button size="sm" data-test="deposit-btn" type="submit">
              {t("button.go-to-payment")}
            </Button>
          </div>
        )}

        {!isKycCompleted && (
          <VerificationContainer
            open={isVerificationDialogVisible}
            submitDeposit={() => {
              onDeposit({
                accountId: accountIdValue,
                amount: amountValue,
                paymentMethodId: paymentMethodIdValue,
                isBonusAccepted: isBonusAccepted,
              });
              closeVerificationDialog();
            }}
            depositLimit={currentLimitBeforeKyc}
            onOpenChange={setIsVerificationDialogVisible}
            account={currentAccount!}
            paymentMethodId={selectedOption?.data}
            recurringId={paymentMethod?.reccuringId!}
            isSurveyCompleted={isSurveyCompleted}
          />
        )}
      </div>

      {bonus && !!bonusPercent && isBonusAccepted && (
        <>
          <div className="my-6 h-px w-full bg-gray xl:my-0 xl:h-auto xl:w-px xl:grow" />

          <div className="w-full">
            <div className="mb-3 flex items-center justify-between font-roboto">
              <div className="flex items-center gap-2">{t("deposit.bonus.text")}</div>
              <div>+{Math.floor(bonusPercent)}%</div>
            </div>

            <div className="flex items-center justify-between font-gilroy font-semibold">
              <div>{t("deposit.bonus.total")}</div>
              <div className="text-end">
                {Number(amountValue) !== 0 && (
                  <div className="mb-1 block text-text-placeholder line-through">
                    <NumberFormat
                      value={amountValue}
                      currency={currentAccount?.currency!}
                      decimalScale={currentAccount?.digits}
                    />
                  </div>
                )}
                <NumberFormat
                  value={amountNumber.plus(amountNumber.mul(bonusPercent).div(100)).toNumber()}
                  currency={currentAccount?.currency!}
                  decimalScale={currentAccount?.digits}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </HookForm>
  );
};
