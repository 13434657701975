import { useStore } from "@nanostores/react";
import { Trans } from "react-i18next";

import { IconTimeRound } from "@/domains/icons";
import { useCurrentSymbolContext } from "@/features/terminal/contexts/current-symbol-context";
import { Alert } from "@/shared/ui";

import { $serverTime } from "../../helpers/server-time";
import { useTradingUnavailableInfo } from "../../helpers/symbols";

const TradingUnavailableAlert = () => {
  const { symbolInfo, currentSymbol } = useCurrentSymbolContext();

  const currentDate = useStore($serverTime);

  const { date, isDate, isTime, isToday, time, timeLeftMinutes, timezone } = useTradingUnavailableInfo({
    periodDateEnd: symbolInfo.periodDateEnd!,
    currentDate: currentDate!,
  });

  return (
    <Alert
      icon={<IconTimeRound />}
      text={
        <>
          {isDate && (
            <Trans
              i18nKey="terminal.trading-unavailable.reopen-message.date"
              values={{ currentSymbol, date, time, timezone }}
              components={{
                strong: <Alert.Strong />,
              }}
            />
          )}
          {isToday && (
            <Trans
              i18nKey="terminal.trading-unavailable.reopen-message.today-at"
              values={{ currentSymbol, time, timezone }}
              components={{
                strong: <Alert.Strong />,
              }}
            />
          )}
          {isTime && (
            <Trans
              i18nKey="terminal.trading-unavailable.reopen-message.time"
              values={{ currentSymbol, time: timeLeftMinutes }}
              components={{
                strong: <Alert.Strong />,
              }}
            />
          )}
        </>
      }
    />
  );
};

export { TradingUnavailableAlert };
