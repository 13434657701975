import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { DialogButtons } from "@/components/dialog";
import { InfoIcon } from "@/components/icons";
import { Stack } from "@/components/stack";
import { Text } from "@/components/text";
import { TradingAccount } from "@/services/openapi";

import { PlatformCopyButton } from "./platform-copy-button";

type Props = {
  account: TradingAccount;
  children?: ReactNode;
};

export const AccountPlatformInfo: FC<Props> = ({ children, account }) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack gap="4">
        <PlatformCopyButton title={t("common.server")} value={account.serverAddress!} />
        <PlatformCopyButton title={t("common.login")} value={account.login!.toString()} />
      </Stack>
      <div className="mt-6 flex items-start gap-3">
        <div className="mt-[1px] h-[24px] w-[24px] text-text-secondary">
          <InfoIcon />
        </div>
        <Text color="dimmed" family="roboto" lineHeight="3">
          {t("accounts.metatrader.password-sent", { platform: account.platformTitle })}
        </Text>
      </div>
      <DialogButtons>{children}</DialogButtons>
    </>
  );
};
