import { TerminalSymbolSignal, TradingCentralSignalType } from "@/services/openapi";

import { formatInputNumberValue } from "../helpers/formatting";

const getInitialTpPrice = ({
  isSignal,
  priceDecimalScale,
  signal,
}: {
  isSignal: boolean;
  priceDecimalScale: number;
  signal: TerminalSymbolSignal | undefined;
}) => {
  if (isSignal && signal) {
    return formatInputNumberValue(signal.priceTarget, priceDecimalScale);
  }
  return "";
};

const getInitialSlPrice = ({
  isSignal,
  priceDecimalScale,
  signal,
  currentPrice,
}: {
  isSignal: boolean;
  priceDecimalScale: number;
  signal: TerminalSymbolSignal | undefined;
  currentPrice: number;
}) => {
  if (isSignal && signal) {
    const isUpSignal = signal.type === TradingCentralSignalType.StrongUp || signal.type === TradingCentralSignalType.Up;

    if (isUpSignal) {
      const price = currentPrice > signal.priceSupport1! ? signal.priceSupport1 : signal.priceSupport2;

      return formatInputNumberValue(price, priceDecimalScale);
    }

    const price = currentPrice < signal.priceResistance1! ? signal.priceResistance1 : signal.priceResistance2;

    return formatInputNumberValue(price, priceDecimalScale);
  }
  return "";
};

export { getInitialTpPrice, getInitialSlPrice };
