import { ComponentPropsWithoutRef, FC } from "react";
import { useNavigate } from "react-router-dom";

import { ArrowLeftIcon } from "@/components/icons/arrow-left-icon";
import { Button } from "@/shared/ui";

type Props = Pick<ComponentPropsWithoutRef<"button">, "onClick"> & {
  text: string;
};

export const CabinetBack: FC<Props> = ({ text, onClick }) => {
  const navigate = useNavigate();

  return (
    <Button
      variant="flat"
      className="flex gap-[16px] p-0 font-gilroy text-2xl font-semibold lg:text-3xl"
      onClick={e => {
        onClick ? onClick(e) : navigate(-1);
      }}
    >
      <ArrowLeftIcon />
      {text}
    </Button>
  );
};
