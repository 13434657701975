import { type FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { TerminalSymbolWidgetBuzzing, TradingCentralBuzzingType } from "@/services/openapi";

import { WidgetBlock } from "../../../widgets/block";
import { Gauge } from "./gauge";

type Props = {
  buzzing: TerminalSymbolWidgetBuzzing;
};

const NewsVolumeWidget: FC<Props> = ({ buzzing }) => {
  const { t } = useTranslation();

  const { mentionsVolume24, trend } = buzzing;

  return (
    <WidgetBlock
      title={t("terminal.insights.widgets.news-volume.title")}
      description={t("terminal.insights.widgets.news-volume.description")}
    >
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-col gap-2">
          <div className="font-gilroy text-[16px] font-semibold leading-[1.2] text-contrast-primary">
            {trend === TradingCentralBuzzingType.Buzzing && t("terminal.insights.widgets.news-volume.buzzing")}
            {trend === TradingCentralBuzzingType.AboveAverage &&
              t("terminal.insights.widgets.news-volume.above-average")}
            {trend === TradingCentralBuzzingType.Average && t("terminal.insights.widgets.news-volume.average")}
            {trend === TradingCentralBuzzingType.BelowAverage &&
              t("terminal.insights.widgets.news-volume.below-average")}
          </div>
          <div className="font-roboto text-[14px] leading-normal text-contrast-secondary">
            {t("terminal.insights.widgets.news-volume.mentions", { number: mentionsVolume24 })}
          </div>
        </div>
        <Gauge buzzingTrend={trend!} />
      </div>
    </WidgetBlock>
  );
};

const Component = memo(NewsVolumeWidget);

export { Component as NewsVolumeWidget };
