import dayjs from "dayjs";
import { useMemo } from "react";

import {
  TerminalAccountSymbols,
  TerminalEventSymbolSessionStateType,
  TerminalEventSymbolWidgetBuzzing,
  TerminalEventSymbolWidgetSupportAndResistance,
  TerminalEventSymbolWidgetTechnicalSummaryScore,
  TerminalEventSymbolWidgetTrend,
  TerminalSymbol,
  TerminalSymbolUpdate,
  TerminalSymbolWidget,
  TerminalSymbolWidgetBuzzing,
  TerminalSymbolWidgetSummaryScore,
  TerminalSymbolWidgetSupportAndResistance,
  TerminalSymbolWidgetTrend,
  TradingCentralBuzzingType,
  TradingCentralSignalTrend,
  TradingServerSymbolType,
} from "@/services/openapi";

import { getDecimalScaleFromTickSize } from "./formatting";
import { calculatePipSize } from "./formulas";

const convertSocketWidget = ({
  b,
  sr,
  ss,
  t,
}: {
  b: TerminalEventSymbolWidgetBuzzing | undefined;
  sr: TerminalEventSymbolWidgetSupportAndResistance | undefined;
  ss: TerminalEventSymbolWidgetTechnicalSummaryScore | undefined;
  t: TerminalEventSymbolWidgetTrend | undefined;
}): TerminalSymbolWidget => {
  const newSupportAndResistance: TerminalSymbolWidgetSupportAndResistance | undefined = sr
    ? { support: sr.s, resistance: sr.r }
    : undefined;
  const newTrend: TerminalSymbolWidgetTrend | undefined = t
    ? { dateNextCalculation: t.c, trend: t.t as any as TradingCentralSignalTrend }
    : undefined;
  const newBuzzing: TerminalSymbolWidgetBuzzing | undefined = b
    ? { mentionsVolume24: b.m, trend: b.b as any as TradingCentralBuzzingType }
    : undefined;
  const newSummaryScore: TerminalSymbolWidgetSummaryScore | undefined = ss
    ? {
        scoreLong: { trend: ss.l!.t as any as TradingCentralSignalTrend, score: ss.l!.s },
        scoreIntermediate: { trend: ss.i!.t as any as TradingCentralSignalTrend, score: ss.i!.s },
        scoreShort: { trend: ss.s!.t as any as TradingCentralSignalTrend, score: ss.s!.s },
      }
    : undefined;

  return {
    buzzing: newBuzzing,
    summaryScore: newSummaryScore,
    supportAndResistance: newSupportAndResistance,
    trend: newTrend,
  };
};

const getInstrumentType = (type: TradingServerSymbolType): "forex" | "cfd" => {
  if (type === TradingServerSymbolType.Forex || type === TradingServerSymbolType.ForexNoLeverage) {
    return "forex";
  }

  return "cfd";
};

const normalizeSymbolsList = (list: TerminalSymbol[]) => {
  const initObject: SymbolsListType = {};
  const newList = JSON.parse(JSON.stringify(list)) as TerminalSymbol[];
  newList.forEach(
    item =>
      (initObject[item.symbol!] = {
        ...item,
        volumeDecimalScale: getDecimalScaleFromTickSize(item.volumeStep!),
        pipSize: calculatePipSize({ priceDecimalScale: item.digits! }),
        priceDecimalScale: item.digits!,
      }),
  );
  return initObject;
};

type SymbolsListType = Record<string, MergedTerminalSymbol>;

type MergedTerminalSymbol = TerminalSymbol &
  TerminalSymbolUpdate & {
    pipSize: number;
    priceDecimalScale: number;
    volumeDecimalScale: number;
    isFavorite?: boolean;
    isChartFavorite?: boolean;
    eventTime?: number;
    isExcluded?: boolean;
  };

enum StaticSymbolGroup {
  FAVORITES = "favorites",
  POPULAR = "popular",
}

type SymbolGroupType = string | StaticSymbolGroup;

const filterExcluded = (symbol: MergedTerminalSymbol): boolean => {
  return !symbol.isExcluded;
};

const filterFavorites = (symbol: MergedTerminalSymbol): boolean => {
  return filterExcluded(symbol) && !!symbol.isFavorite;
};

const filterGroup =
  (group: SymbolGroupType) =>
  (symbol: MergedTerminalSymbol): boolean => {
    return filterExcluded(symbol) && symbol.group === group;
  };

const filterPopular = (symbol: MergedTerminalSymbol, popular: string[]): boolean => {
  return filterExcluded(symbol) && popular.includes(symbol.symbol!);
};

const getSymbolsFilteringFunction = ({
  group,
  popular,
  hasSearch,
}: {
  group: SymbolGroupType;
  popular: string[];
  hasSearch: boolean;
}): ((symbol: MergedTerminalSymbol) => boolean) => {
  if (hasSearch) {
    return () => true;
  }
  if (group === StaticSymbolGroup.FAVORITES) {
    return filterFavorites;
  }
  if (group === StaticSymbolGroup.POPULAR) {
    return symbol => filterPopular(symbol, popular);
  }
  return filterGroup(group);
};

const createSymbolsCompareFn = (
  { symbol: symbolFirst }: MergedTerminalSymbol,
  { symbol: symbolSecond }: MergedTerminalSymbol,
  sortArray: string[],
): number => {
  return sortArray.indexOf(symbolFirst!) > sortArray.indexOf(symbolSecond!) ? 1 : -1;
};

const sortWatchlistSymbols = ({
  symbols,
  group,
  favoriteData,
  hasSearch,
}: {
  symbols: MergedTerminalSymbol[];
  group: SymbolGroupType;
  favoriteData: TerminalAccountSymbols;
  hasSearch: boolean;
}): MergedTerminalSymbol[] => {
  if (hasSearch) {
    return symbols;
  }

  if (group === StaticSymbolGroup.POPULAR) {
    return symbols.toSorted((a, b) => createSymbolsCompareFn(a, b, favoriteData.popular!));
  }

  return symbols;
};

const filterSymbolsSearch =
  (query: string) =>
  ({ symbol, description }: MergedTerminalSymbol): boolean => {
    if (!query) return true;
    return (
      symbol!.toLowerCase().includes(query.toLowerCase()) || description!.toLowerCase().includes(query.toLowerCase())
    );
  };

const isSymbolAvailable = (state: TerminalEventSymbolSessionStateType) => {
  return (
    state === TerminalEventSymbolSessionStateType.InProcessOpened ||
    state === TerminalEventSymbolSessionStateType.Opened
  );
};

const useTradingUnavailableInfo = ({
  periodDateEnd,
  currentDate,
}: {
  periodDateEnd: string | null;
  currentDate: number | null;
}) => {
  return useMemo(() => {
    const openDate = periodDateEnd ? dayjs(periodDateEnd) : dayjs();
    const timeLeftHours = openDate.diff(currentDate, "hour");
    const timeLeftMinutes = dayjs(openDate.diff(currentDate)).format("mm");
    const date = openDate.format("MMM D");
    const time = openDate.format("HH:mm");
    const timezone = openDate.format("Z");

    const isDate = timeLeftHours > 24;
    const isToday = timeLeftHours >= 1 && timeLeftHours <= 24;
    const isTime = timeLeftHours === 0;

    return { date, time, timezone, timeLeftMinutes, isDate, isToday, isTime };
  }, [currentDate, periodDateEnd]);
};

export {
  convertSocketWidget,
  isSymbolAvailable,
  getInstrumentType,
  normalizeSymbolsList,
  StaticSymbolGroup,
  getSymbolsFilteringFunction,
  sortWatchlistSymbols,
  filterSymbolsSearch,
  useTradingUnavailableInfo,
};
export type { SymbolsListType, MergedTerminalSymbol, SymbolGroupType };
