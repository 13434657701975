import { FC } from "react";
import { ScrollRestoration, useNavigate, useSearchParams } from "react-router-dom";

import { amplitudeEvents } from "@/app/libs/amplitude";
import { SelectOptionType } from "@/components/form";
import { useDepositContext } from "@/contexts/deposit.context";
import { BonusBanner } from "@/entities/bonuses/banner";
import { getShowBonusBanner } from "@/entities/bonuses/helpers";
import { PaymentMethodGrid } from "@/features/deposit/components/payment-method/payment-method-grid";
import { useTrackVisitPage } from "@/hooks/track-visit-page.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { PaymentMethod } from "@/services/openapi";
import { useBonusesQuery } from "@/state/server/bonuses";
import { useDepositMethodQuery } from "@/state/server/payment";

export const DepositPage: FC = () => {
  const { data: allPaymentMethods } = useDepositMethodQuery();
  const navigate = useNavigate();
  const { setPaymentMethod, setAccountId, setPaymentOptions } = useDepositContext();
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get("accountId");
  const { data } = useBonusesQuery();

  const availablePaymentMethods = allPaymentMethods?.items?.filter(method => method.isAvailable);
  const unavailablePaymentMethods = allPaymentMethods?.items?.filter(method => !method.isAvailable);

  const choosePaymentMethod = (method: PaymentMethod & { amount?: number }) => {
    setPaymentOptions(paymentOptions);
    setPaymentMethod(method);

    if (accountId) setAccountId(accountId);

    navigate(cabinetRoutes.depositDetails);
  };

  const paymentOptions: SelectOptionType[] | undefined = availablePaymentMethods?.map(item => ({
    value: `${item.id}${item.reccuringId!}`,
    label: item.title!,
    data: item.id,
  }));

  useTrackVisitPage(amplitudeEvents.deposit.visit);

  if (!allPaymentMethods?.items) return null;

  return (
    <>
      <ScrollRestoration />

      {data && data.items![0] && getShowBonusBanner(data.items![0], true) && (
        <BonusBanner bonus={data.items![0]} showCloseButton={false} />
      )}

      <div className="mt-8">
        <PaymentMethodGrid
          choosePaymentMethod={choosePaymentMethod}
          items={{ available: availablePaymentMethods, unavailable: unavailablePaymentMethods }}
        />
      </div>
    </>
  );
};
