import { type FC, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { amplitudeEvents, amplitudeSetUserId, track } from "@/app/libs/amplitude";
import { Footer } from "@/components/footer";
import { NoAccountsDialog } from "@/components/no-accounts-dialog";
import { Sidebar } from "@/components/sidebar";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { useProfileData } from "@/state/server/profile/profile.hooks";

export const CabinetLayout: FC = () => {
  const { id: userId } = useProfileData();
  const { isDesktop } = useScreenSize();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      userId: userId,
    });

    if (userId) {
      amplitudeSetUserId(userId);
    }
  }, [userId]);

  const analyticsEventOnDeposit = () => {
    if (pathname === cabinetRoutes.dashboard) {
      track(amplitudeEvents.deposit.fromMyAccountsMenu);
    }
  };

  return (
    <>
      <div className="grid h-full">
        {isDesktop && <Sidebar onDeposit={analyticsEventOnDeposit} />}

        <div
          className="grid bg-bg-back lg:ms-expanded-sidebar lg:grid-rows-[1fr_auto]"
          style={{ boxShadow: "0px 10px 40px -20px rgba(0, 0, 0, 0.1)" }}
        >
          <main className="max-w-[100vw] px-4 pb-[30px] lg:max-w-[calc(100vw-var(--sidebar-expanded))] lg:px-16 lg:py-10">
            <Outlet />
          </main>
          <Footer />
        </div>
      </div>
      <NoAccountsDialog onCreateNewAccount={() => navigate(cabinetRoutes.dashboard)} />
    </>
  );
};
