import { FC } from "react";

import { IconPlus } from "@/domains/icons";
import { useTranslation } from "@/hooks/translator.hook";
import { TradingAccountType } from "@/services/openapi";
import { Button } from "@/shared/ui";
import { useServersQuery } from "@/state/server/accounts";

type Props = {
  type: TradingAccountType;
  secondary?: boolean;
  onClick: (accountType: TradingAccountType) => void;
};

export const CreateAccountButton: FC<Props> = ({ type, secondary, onClick }) => {
  const { t } = useTranslation();

  const { data: servers } = useServersQuery();

  if (!servers) {
    return null;
  }

  return (
    <>
      {secondary ? (
        <Button
          variant="tertiary"
          size="sm"
          startSection={<IconPlus />}
          data-test="create-account-btn-trigger"
          onClick={() => onClick(type)}
        >
          {t("accounts.create.button")}
        </Button>
      ) : (
        <Button size="sm" startSection={<IconPlus />} onClick={() => onClick(type)}>
          {t("accounts.create.button")}
        </Button>
      )}
    </>
  );
};
