import { FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { TerminalPopover } from "@/features/terminal/components/popover";
import { useCurrentSymbolContext } from "@/features/terminal/contexts/current-symbol-context";
import { PendingOrder } from "@/features/terminal/contexts/extended-orders.context";
import { useAddChartSymbol } from "@/features/terminal/hooks/add-chart-symbol.hook";
import { terminalLocators } from "@/features/terminal/locators";
import { terminalFormatDate } from "@/features/terminal/place-order/place-order.helpers";

import { OrderType } from "../../components/trading-table/order-type";
import { OpeningPriceContainer } from "../../modify-order/opening-price/opening-price.container";
import { ModifyPendingOrderStopLossContainer } from "../../modify-order/stop-loss/pending-order-stop-loss.container";
import { ModifyPendingOrderTakeProfitContainer } from "../../modify-order/take-profit/pending-order-take-profit.container";
import { ModifyOrderButton } from "../modify-order/button";
import { TradingTable } from "../table";
import { ClosePendingOrderButton } from "./close-button";

type Props = {
  order: PendingOrder;
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  setSymbol: ReturnType<typeof useCurrentSymbolContext>["setSymbol"];
};

const _PendingOrdersBodyRow: FC<Props> = ({ order, accountId, setSymbol, currency, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const {
    symbol,
    type,
    volume,
    price,
    stopLoss,
    takeProfit,
    id,
    date,
    currentPrice,
    ask,
    bid,
    priceDecimalScale,
    volumeDecimalScale,
    baseCurrency,
    quoteCurrency,
    contractSize,
    margin,
  } = order;

  const { addChartSymbol } = useAddChartSymbol({ setSymbol, accountId });

  return (
    <TradingTable.Row>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.symbol}>
        <TradingTable.Symbol symbol={symbol} onClick={() => addChartSymbol(symbol)} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.type}>
        <OrderType type={type} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.volume}>
        <NumberFormat value={volume} decimalScale={volumeDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.margin}>
        <NumberFormat value={margin} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.openPrice}>
        <ModifyOrderButton value={<NumberFormat value={price} decimalScale={priceDecimalScale} />}>
          {onClose => (
            <TerminalPopover tooltipText={t("terminal.opening-price-desc")!} title={t("terminal.open-price")}>
              <OpeningPriceContainer
                onClose={onClose}
                openPrice={price}
                orderType={type}
                orderId={id}
                priceDecimalScale={priceDecimalScale}
                stopLoss={stopLoss}
                takeProfit={takeProfit}
                ask={ask}
                bid={bid}
                accountId={accountId}
                currentPrice={currentPrice!}
                symbol={symbol}
                volume={volume}
                volumeDecimalScale={volumeDecimalScale}
              />
            </TerminalPopover>
          )}
        </ModifyOrderButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.currentPrice}>
        {currentPrice ? <NumberFormat value={currentPrice} decimalScale={priceDecimalScale} /> : "—"}
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.takeProfit}>
        <ModifyOrderButton
          value={takeProfit ? <NumberFormat value={takeProfit} decimalScale={priceDecimalScale} /> : null}
        >
          {onClose => (
            <TerminalPopover tooltipText={t("terminal.take-profit-desc")!} title={t("terminal.take-profit")}>
              <ModifyPendingOrderTakeProfitContainer
                onClose={onClose}
                openPrice={price}
                orderType={type}
                orderId={id}
                priceDecimalScale={priceDecimalScale}
                stopLoss={stopLoss}
                takeProfit={takeProfit}
                ask={ask}
                bid={bid}
                accountId={accountId}
                baseCurrency={baseCurrency}
                quoteCurrency={quoteCurrency}
                contractSize={contractSize}
                volume={volume}
                currency={currency}
                currentPrice={currentPrice!}
                symbol={symbol}
                currencyDecimalScale={currencyDecimalScale}
              />
            </TerminalPopover>
          )}
        </ModifyOrderButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.stopLoss}>
        <ModifyOrderButton value={stopLoss ? <NumberFormat value={stopLoss} decimalScale={priceDecimalScale} /> : null}>
          {onClose => (
            <TerminalPopover tooltipText={t("terminal.stop-loss-desc")!} title={t("terminal.stop-loss")}>
              <ModifyPendingOrderStopLossContainer
                onClose={onClose}
                openPrice={price}
                orderType={type}
                orderId={id}
                priceDecimalScale={priceDecimalScale}
                stopLoss={stopLoss}
                takeProfit={takeProfit}
                ask={ask}
                bid={bid}
                accountId={accountId}
                baseCurrency={baseCurrency}
                quoteCurrency={quoteCurrency}
                contractSize={contractSize}
                volume={volume}
                currency={currency}
                currentPrice={currentPrice!}
                symbol={symbol}
                currencyDecimalScale={currencyDecimalScale}
              />
            </TerminalPopover>
          )}
        </ModifyOrderButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.pending.placementTime}>
        {terminalFormatDate(date)}
      </TradingTable.Cell>
      <TradingTable.Cell align="end" data-test={terminalLocators.tradingTables.pending.delete}>
        <ClosePendingOrderButton
          accountId={accountId}
          orderId={id}
          symbol={symbol}
          orderType={type}
          volume={volume}
          volumeDecimalScale={volumeDecimalScale}
        />
      </TradingTable.Cell>
    </TradingTable.Row>
  );
};

const Component = memo(_PendingOrdersBodyRow);

export { Component as PendingOrdersBodyRow };
