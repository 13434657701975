import { Overrides } from "public/charting_library/charting_library";

import { getSymbolTimezone } from "../datafeed/timezone";
import { ChartColors } from "./colors";

// https://www.tradingview.com/charting-library-docs/latest/customization/overrides/
// https://www.tradingview.com/charting-library-docs/latest/customization/overrides/chart-overrides/
// https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.ChartPropertiesOverrides/

// TODO:
const getChartOverrides = ({
  chartColors,
  isChartExpanded,
}: {
  chartColors: ChartColors;
  isChartExpanded: boolean;
}): Overrides => ({
  timezone: getSymbolTimezone(),
  "paneProperties.background": chartColors.background,
  "paneProperties.backgroundType": "solid",

  // "paneProperties.vertGridProperties.color": "",
  // "paneProperties.horzGridProperties.color": "",

  // "paneProperties.separatorColor": "",

  "paneProperties.legendProperties.showSeriesTitle": isChartExpanded,
  "paneProperties.legendProperties.showSeriesOHLC": isChartExpanded,
  "paneProperties.legendProperties.showBarChange": isChartExpanded,
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  "scalesProperties.textColor": chartColors.text,
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  "mainSeriesProperties.priceLineColor": chartColors.text,
  // https://www.tradingview.com/charting-library-docs/latest/customization/overrides/chart-overrides/#chart-styles
  "mainSeriesProperties.lineStyle.color": chartColors.positive,

  "mainSeriesProperties.baselineStyle.topLineColor": chartColors.positive,
  "mainSeriesProperties.baselineStyle.bottomLineColor": chartColors.negative,

  "mainSeriesProperties.barStyle.upColor": chartColors.positive,
  "mainSeriesProperties.barStyle.downColor": chartColors.negative,

  "mainSeriesProperties.candleStyle.upColor": chartColors.positive,
  "mainSeriesProperties.candleStyle.downColor": chartColors.negative,
  "mainSeriesProperties.candleStyle.wickUpColor": chartColors.positive,
  "mainSeriesProperties.candleStyle.wickDownColor": chartColors.negative,
  "mainSeriesProperties.candleStyle.borderUpColor": chartColors.positive,
  "mainSeriesProperties.candleStyle.borderDownColor": chartColors.negative,

  "mainSeriesProperties.areaStyle.color1": chartColors.area,
  "mainSeriesProperties.areaStyle.color2": chartColors.positive,
  "mainSeriesProperties.areaStyle.linecolor": chartColors.positive,

  // FIXME:
  "mainSeriesProperties.hlcAreaStyle.closeLineColor": "rgba(0, 0, 0, 0.3)",
  "mainSeriesProperties.hlcAreaStyle.closeLowFillColor": chartColors.hlcAreaLowColor,
  "mainSeriesProperties.hlcAreaStyle.highCloseFillColor": chartColors.hlcAreaHighColor,
  "mainSeriesProperties.hlcAreaStyle.highLineColor": chartColors.positive,
  "mainSeriesProperties.hlcAreaStyle.lowLineColor": chartColors.negative,

  "mainSeriesProperties.lineWithMarkersStyle.color": chartColors.positive,

  // doesn't exist
  // "mainSeriesProperties.stepLineStyle.lineColor": chartColors.positive,

  "mainSeriesProperties.hollowCandleStyle.upColor": chartColors.positive,
  "mainSeriesProperties.hollowCandleStyle.downColor": chartColors.negative,
  "mainSeriesProperties.hollowCandleStyle.wickUpColor": chartColors.positive,
  "mainSeriesProperties.hollowCandleStyle.wickDownColor": chartColors.negative,
  "mainSeriesProperties.hollowCandleStyle.borderUpColor": chartColors.positive,
  "mainSeriesProperties.hollowCandleStyle.borderDownColor": chartColors.negative,

  "mainSeriesProperties.haStyle.upColor": chartColors.positive,
  "mainSeriesProperties.haStyle.downColor": chartColors.negative,
  "mainSeriesProperties.haStyle.wickUpColor": chartColors.positive,
  "mainSeriesProperties.haStyle.wickDownColor": chartColors.negative,
  "mainSeriesProperties.haStyle.borderUpColor": chartColors.positive,
  "mainSeriesProperties.haStyle.borderDownColor": chartColors.negative,

  "mainSeriesProperties.columnStyle.upColor": chartColors.positive,
  "mainSeriesProperties.columnStyle.downColor": chartColors.negative,

  "mainSeriesProperties.hiloStyle.borderColor": chartColors.positive,
  "mainSeriesProperties.hiloStyle.color": chartColors.positive,
  "mainSeriesProperties.hiloStyle.labelColor": chartColors.positive,
});

export { getChartOverrides };
