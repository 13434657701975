import { type FC, useCallback, useState } from "react";

import { Pagination } from "@/components/pagination";
import { FundsTx } from "@/services/openapi";

import { TransactionCard } from "./transaction-card";

interface TransactionGridProps {
  items: FundsTx[];
}

const PAGE_SIZE = 5;

export const TransactionGrid: FC<TransactionGridProps> = ({ items }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const visibleItems = items.slice(startIndex, endIndex);

  return (
    <div className="mt-4 flex flex-col gap-2">
      {visibleItems.map((item: FundsTx) => (
        <TransactionCard key={item.id} transaction={item} />
      ))}
      <div className="mt-6 grid place-items-center">
        <Pagination changePage={onPageChange} currentPage={currentPage} pageSize={PAGE_SIZE} total={items?.length} />
      </div>
    </div>
  );
};
