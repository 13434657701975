import { type FC } from "react";

import { useTerminalAccountContext } from "../../contexts/account.context";
import { useCurrentSymbolContext } from "../../contexts/current-symbol-context";
import { useExtendedOrdersContext } from "../../contexts/extended-orders.context";
import { useOrdersContext } from "../../contexts/orders.context";
import { useLayoutContext } from "../../layout/context";
import { PendingOrdersTable } from "../desktop/pending-orders/table";
import { PendingOrdersCardsContainer } from "../mobile/pending-orders/cards.container";

type Props = {
  onClose?: () => void;
};

const PendingOrdersContainer: FC<Props> = ({ onClose }) => {
  const { ordersIsLoading } = useOrdersContext();

  const { account } = useTerminalAccountContext();

  const { setSymbol } = useCurrentSymbolContext();
  const { openWatchlist, isMobile } = useLayoutContext();
  const { extendedPendingOrders } = useExtendedOrdersContext();

  return isMobile ? (
    <PendingOrdersCardsContainer
      onClose={onClose}
      orders={extendedPendingOrders}
      accountId={account.id!}
      currency={account.currency!}
      currencyDecimalScale={account.digits!}
      openWatchlist={openWatchlist}
      ordersIsLoading={ordersIsLoading}
    />
  ) : (
    <PendingOrdersTable
      orders={extendedPendingOrders}
      accountId={account.id!}
      setSymbol={setSymbol}
      currency={account.currency!}
      currencyDecimalScale={account.digits!}
      openWatchlist={openWatchlist}
      ordersIsLoading={ordersIsLoading}
    />
  );
};

export { PendingOrdersContainer };
