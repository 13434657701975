import { produce } from "immer";
import { useCallback } from "react";
import { QueryClient, useQueryClient } from "react-query";

import { TerminalAccountSymbols } from "@/services/openapi";
import { terminalQueryKeys, useAddSymbolToChartsMutation } from "@/state/server/terminal";

const updateFavoritesData = ({
  queryClient,
  accountId,
  symbol,
  replaceSymbol,
}: {
  queryClient: QueryClient;
  accountId: string;
  symbol: string;
  replaceSymbol?: boolean;
}) => {
  const favoritesData = queryClient.getQueryData<TerminalAccountSymbols>(
    terminalQueryKeys.symbolsFavorites(accountId),
  )!;

  const isSymbolChartFavorite = favoritesData.charts!.includes(symbol);

  if (isSymbolChartFavorite) {
    queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
      return produce(oldData!, draft => {
        draft.chartsSelected = symbol;
      });
    });
    return;
  }

  if (replaceSymbol) {
    queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
      const replacedSymbolIndex = oldData!.charts!.findIndex(s => s === oldData!.chartsSelected);
      return produce(oldData!, draft => {
        draft.charts![replacedSymbolIndex] = symbol;
        draft.chartsSelected = symbol;
      });
    });
    return;
  }

  queryClient.setQueryData<TerminalAccountSymbols>(terminalQueryKeys.symbolsFavorites(accountId), oldData => {
    const newChartFavorites = [symbol, ...oldData!.charts!.filter(s => s !== symbol)].slice(0, 10);
    return produce(oldData!, draft => {
      draft.chartsSelected = symbol;
      draft.charts = newChartFavorites;
    });
  });
};

type Props = {
  accountId: string;
  setSymbol: (symbol: string) => void;
  replaceSymbol?: boolean;
  callback?: () => void;
};

const useAddChartSymbol = ({ accountId, setSymbol, callback, replaceSymbol }: Props) => {
  const queryClient = useQueryClient();

  const { mutate: addFavorite, isLoading } = useAddSymbolToChartsMutation();

  const addChartSymbol = useCallback(
    (symbol: string) => {
      if (isLoading) {
        return;
      }

      updateFavoritesData({ queryClient, accountId, symbol, replaceSymbol });

      addFavorite({ symbol, tradingAccountId: accountId, isReplace: replaceSymbol });
      setSymbol(symbol);
      callback && callback();
    },
    [accountId, addFavorite, isLoading, queryClient, setSymbol, callback, replaceSymbol],
  );

  return { addChartSymbol, isLoading };
};

export { useAddChartSymbol };
