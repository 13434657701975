import { cabinetRoutes } from "@/routes/cabinet.routes";
import { useAccountQuery } from "@/state/server/accounts";
import { useBonusesQuery } from "@/state/server/bonuses";
import { useDateQuery } from "@/state/server/platform";
import {
  useSymbolsUpdatesQuery,
  useTerminalSymbolsFavoritesQuery,
  useTerminalSymbolsQuery,
  useTerminalTokenQuery,
} from "@/state/server/terminal";

import { useTerminalNavigate } from "./helpers";
import { setServerTime } from "./helpers/server-time";

const useTerminalHandle = ({
  accountIdUrl,
  isMobileAppMode,
}: {
  accountIdUrl: string | null;
  isMobileAppMode: boolean;
}) => {
  const navigate = useTerminalNavigate(isMobileAppMode);

  // account
  const { data: account } = useAccountQuery(accountIdUrl!, {
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onError: () => {
      navigate(cabinetRoutes.dashboard, { replace: true });
    },
  });
  const { data: socketToken } = useTerminalTokenQuery(accountIdUrl!, {
    cacheTime: 0,
    onError: () => {
      navigate(cabinetRoutes.dashboard, { replace: true });
    },
  });

  // symbols
  const { data: symbols } = useTerminalSymbolsQuery(account?.serverId!, {
    enabled: !!account,
  });
  const { data: symbolsUpdates } = useSymbolsUpdatesQuery(
    { tradingServerId: account?.serverId! },
    { cacheTime: 0, enabled: !!account },
  );
  const { data: favorites } = useTerminalSymbolsFavoritesQuery(accountIdUrl!, {
    cacheTime: 0,
  });

  // other
  const { data: bonuses } = useBonusesQuery();

  const { isSuccess: dateIsSuccess } = useDateQuery({
    onSuccess: res => {
      setServerTime(res as string);
    },
  });

  const showLoader =
    !socketToken || !account || !symbolsUpdates || !symbols || !favorites || !bonuses || !dateIsSuccess;

  return {
    socketToken: socketToken!,
    account: account!,
    symbolsUpdates: symbolsUpdates!,
    symbols: symbols!,
    favorites: favorites!,
    bonuses: bonuses!,
    showLoader,
  };
};

export { useTerminalHandle };
