import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { useDepositContext } from "@/contexts/deposit.context";
import { DepositForm, DepositFormValues } from "@/features/deposit/components/form/deposit.form";
import { getDepositRedirectSearchParam } from "@/features/deposit/deposit-redirect.page";
import { useClientId } from "@/hooks/client-id.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { AvailabilityStatus, TradingAccountSorting, TradingAccountType } from "@/services/openapi";
import { getProfile } from "@/services/profile";
import { useAllAccountsQuery, useLastAccountQuery } from "@/state/server/accounts";
import { useBonusesQuery } from "@/state/server/bonuses";
import { useDepositMethodQuery, useDepositMutation, usePaymentInfo } from "@/state/server/payment";
import { useProfileData } from "@/state/server/profile/profile.hooks";
import { isFirefox, isIos, isSafari } from "@/utils/helpers";

export const DepositContainer = () => {
  const navigate = useNavigate();
  const uniqueId = useMemo(() => uuidv4(), [uuidv4]);
  const clientId = useClientId();
  const { paymentOptions, paymentMethod, accountId } = useDepositContext();
  const { data: paymentMethods } = useDepositMethodQuery();
  const { mutateAsync: deposit } = useDepositMutation();
  const { data: accounts } = useAllAccountsQuery({
    type: TradingAccountType.Real,
    sorting: TradingAccountSorting.Newest,
    status: AvailabilityStatus.Active,
  });
  const { data: latestAccount, isLoading } = useLastAccountQuery({
    status: AvailabilityStatus.Active,
    type: TradingAccountType.Real,
  });
  const profileData = useProfileData();
  const { data: paymentInfo } = usePaymentInfo();

  const account = useMemo(() => accounts?.find(account => account.id === accountId), [accountId, accounts]);
  const { data: bonusData } = useBonusesQuery();

  const defaultAccount = useMemo(() => {
    if (accounts && latestAccount && !isLoading) {
      if (account?.id) return account;
      else if (latestAccount?.id) return latestAccount;
      else return accounts[0];
    }
  }, [account?.id, accounts, latestAccount?.id, isLoading]);

  const onSubmit = useCallback(
    async (values: DepositFormValues) => {
      const paymentId = paymentOptions?.find(item => item.value === values.paymentMethodId)?.data;
      const { id: userID } = await getProfile();

      const payload = {
        accountId: values.accountId,
        id: uniqueId,
        deviceType: "desktop",
        paymentMethodId: paymentId,
        amount: values.amount,
        isBonusesRejected: !values.isBonusAccepted,
      };

      const data =
        paymentMethod!.reccuringId !== null ? { ...payload, recurringId: paymentMethod!.reccuringId } : payload;

      return deposit(data, {
        onSuccess: res => {
          // TODO: handle all dataLayer events with a custom hook
          window.dataLayer?.push({
            event: "gtm_events",
            custom_timestamp: dayjs().valueOf(),
            userID,
            user_id: userID,
            client_id: clientId,
            ec: "attempt_purch",
            ea: "no_error",
            el: "no_error",
            ga4_event_name: "attempt_purch",
          });

          if (isIos() || isSafari() || isFirefox()) {
            navigate({ pathname: cabinetRoutes.depositRedirect, search: getDepositRedirectSearchParam(res) });
          } else {
            navigate(cabinetRoutes.transactionHistory);
            window.open(res, "_blank");
          }
        },
        onError: res => {
          window.dataLayer?.push({
            event: "gtm_events",
            custom_timestamp: dayjs().valueOf(),
            userID,
            user_id: userID,
            client_id: clientId,
            ec: "attempt_purch",
            ea: "error",
            el: res.code,
            ga4_event_name: "attempt_purch",
          });
        },
      });
    },
    [deposit, paymentMethod],
  );

  if (
    !accounts ||
    !paymentMethods?.items ||
    !defaultAccount ||
    !bonusData?.items ||
    !paymentInfo?.depositInfo?.uiAmountsInfo?.amounts ||
    !profileData.options ||
    !profileData.featuresInfo
  )
    return null;

  return (
    <DepositForm
      bonus={bonusData.items}
      paymentMethods={paymentMethods.items}
      accounts={accounts}
      defaultAccount={defaultAccount}
      onSubmit={onSubmit}
      isKycCompleted={profileData.options.isKycCompleted!}
      amountChips={paymentInfo.depositInfo.uiAmountsInfo.amounts}
      isSurveyCompleted={profileData.options.isSurveyCompleted!}
      noKycAmountLimit={profileData.featuresInfo?.depositScreenNoKycAmountLimit!}
    />
  );
};
