import {
  TerminalSymbolSignal,
  TerminalSymbolWidget,
  TradingCentralBuzzingType,
  TradingCentralSignalTrend,
} from "@/services/openapi";

const canShowSignalWidget = ({
  hasSignal,
  signal,
}: {
  hasSignal: boolean;
  signal: TerminalSymbolSignal | undefined;
}) => {
  return !!(signal && hasSignal);
};

const canShowSentimentWidget = ({ widget }: { widget: TerminalSymbolWidget | undefined }) => {
  return !!(widget && widget.trend && widget.trend.trend !== TradingCentralSignalTrend.None);
};

const canShowTechnicalsSummaryScoreWidget = ({ widget }: { widget: TerminalSymbolWidget | undefined }) => {
  return !!(widget && widget.supportAndResistance && widget.summaryScore);
};

const canShowNewsVolumeWidget = ({ widget }: { widget: TerminalSymbolWidget | undefined }) => {
  return !!(widget && widget.buzzing && widget.buzzing.trend !== TradingCentralBuzzingType.NoNews);
};

const canShowAnalyticsTab = ({
  showSignalWidget,
  showSentimentWidget,
  showTechnicalsSummaryScoreWidget,
  showNewsVolumeWidget,
}: {
  showSignalWidget: boolean;
  showSentimentWidget: boolean;
  showTechnicalsSummaryScoreWidget: boolean;
  showNewsVolumeWidget: boolean;
}) => {
  return showSignalWidget || showSentimentWidget || showTechnicalsSummaryScoreWidget || showNewsVolumeWidget;
};

export {
  canShowSignalWidget,
  canShowSentimentWidget,
  canShowTechnicalsSummaryScoreWidget,
  canShowNewsVolumeWidget,
  canShowAnalyticsTab,
};
