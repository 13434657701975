import { type FC } from "react";

import { PnlFormat, PositiveNumberFormat } from "@/app/components";
import { getNumberTextColor } from "@/app/ui/colors";
import { SetBalanceButton } from "@/features/dashboard/components/set-balance-button/set-balance-button";
import { TradeButton } from "@/features/dashboard/components/trade-button/trade-button";
import { useTranslation } from "@/hooks/translator.hook";
import { TradingAccount, TradingAccountType, TradingServerPlatform } from "@/services/openapi";
import { Label } from "@/shared/ui";

import { AccountBadge } from "../badge";
import { AccountCard } from "../card";

type Props = {
  account: TradingAccount;
};

const DemoAccountCard: FC<Props> = ({ account }) => {
  const { currency, marginFree, equity, digits, pnL, actions, platform, platformTitle } = account;

  const { t } = useTranslation();

  return (
    <AccountCard>
      <AccountCard.Header account={account} />
      <AccountCard.Badges>
        <AccountBadge type={TradingAccountType.Demo} data-test="account-badge-demo" />

        {platform! !== TradingServerPlatform.MetaTrader5 ? (
          <Label>{platformTitle}</Label>
        ) : (
          <>
            <Label>Doto</Label>
            <Label>{platformTitle}</Label>
          </>
        )}
      </AccountCard.Badges>
      <AccountCard.DataList>
        <AccountCard.DataListItem
          label={t("accounts.list.equity")}
          value={<PositiveNumberFormat value={equity} decimalScale={digits} currency={currency!} />}
        />
        <AccountCard.DataListItem
          label={t("accounts.list.free-margin")}
          value={<PositiveNumberFormat value={marginFree} decimalScale={digits} currency={currency!} />}
        />
        <AccountCard.DataListItem
          label={t("accounts.list.pnl")}
          valueColor={getNumberTextColor(pnL!)}
          value={<PnlFormat value={pnL} decimalScale={digits} currency={currency!} />}
        />
      </AccountCard.DataList>
      <AccountCard.Footer>
        <TradeButton account={account} />
        {actions!.canMakeDemoDeposit && <SetBalanceButton account={account} />}
      </AccountCard.Footer>
    </AccountCard>
  );
};

export { DemoAccountCard };
