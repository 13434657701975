import { useStore } from "@nanostores/react";
import { FC, useEffect } from "react";

import { useKycInfoQuery } from "@/state/server/kyc";
import { useProfileHeaderQuery } from "@/state/server/profile";
import { useProfileSettigsData } from "@/state/server/profile/profile.hooks";

import { $exitDialogOpen, ExitOnboardingDialog, onExitDialog } from "./components/exit-dialog";
import { Onboarding } from "./onboarding";

export const OnboardingPage: FC = () => {
  const { data: kycInfo, refetch: refetchKyc } = useKycInfoQuery();
  const { data: profile } = useProfileHeaderQuery();
  const { data: settings } = useProfileSettigsData();
  const exitDialogOpen = useStore($exitDialogOpen);

  useEffect(() => {
    refetchKyc();
  }, [settings?.locale]);

  if (!kycInfo || !profile) {
    return null;
  }

  return (
    <>
      <Onboarding kycInfo={kycInfo} profileHeader={profile} />
      <ExitOnboardingDialog open={exitDialogOpen} onOpenChange={onExitDialog} />
    </>
  );
};
