/* tslint:disable */
/* eslint-disable */
/**
 * DoTo CRM API v1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResult } from '../models';
// @ts-ignore
import { RateResult } from '../models';
// @ts-ignore
import { RatesResult } from '../models';
/**
 * RateApi - axios parameter creator
 * @export
 */
export const RateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get rate
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRate: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get rates
         * @param {Array<string>} [from] 
         * @param {Array<string>} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRates: async (from?: Array<string>, to?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1.0/rate/multi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from) {
                localVarQueryParameter['from'] = from;
            }

            if (to) {
                localVarQueryParameter['to'] = to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RateApi - functional programming interface
 * @export
 */
export const RateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get rate
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRate(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RateResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRate(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get rates
         * @param {Array<string>} [from] 
         * @param {Array<string>} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRates(from?: Array<string>, to?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RatesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRates(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RateApi - factory interface
 * @export
 */
export const RateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RateApiFp(configuration)
    return {
        /**
         * 
         * @summary Get rate
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRate(from?: string, to?: string, options?: any): AxiosPromise<RateResult> {
            return localVarFp.getRate(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get rates
         * @param {Array<string>} [from] 
         * @param {Array<string>} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRates(from?: Array<string>, to?: Array<string>, options?: any): AxiosPromise<RatesResult> {
            return localVarFp.getRates(from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRate operation in RateApi.
 * @export
 * @interface RateApiGetRateRequest
 */
export interface RateApiGetRateRequest {
    /**
     * 
     * @type {string}
     * @memberof RateApiGetRate
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof RateApiGetRate
     */
    readonly to?: string
}

/**
 * Request parameters for getRates operation in RateApi.
 * @export
 * @interface RateApiGetRatesRequest
 */
export interface RateApiGetRatesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RateApiGetRates
     */
    readonly from?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof RateApiGetRates
     */
    readonly to?: Array<string>
}

/**
 * RateApi - object-oriented interface
 * @export
 * @class RateApi
 * @extends {BaseAPI}
 */
export class RateApi extends BaseAPI {
    /**
     * 
     * @summary Get rate
     * @param {RateApiGetRateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RateApi
     */
    public getRate(requestParameters: RateApiGetRateRequest = {}, options?: any) {
        return RateApiFp(this.configuration).getRate(requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get rates
     * @param {RateApiGetRatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RateApi
     */
    public getRates(requestParameters: RateApiGetRatesRequest = {}, options?: any) {
        return RateApiFp(this.configuration).getRates(requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }
}
