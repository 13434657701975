import { RequestParamsWithPaging } from "@/components/pagination";
import {
  getLastAccount,
  getServers,
  getTradingAccount,
  getTradingAccounts,
  getTradingAccountsTotalBalance,
} from "@/services/accounts";
import { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { useBaseQuery, usePagingQuery } from "..";
import { QueryOptionsType } from "../types";
import { accountsQueryKeys } from "./accounts.keys";

const transformAccounts = (data: ApiResponseType<typeof getTradingAccounts>) => data.items!;
export const useAllAccountsQuery = (
  filters: ApiRequestParamsType<typeof getTradingAccounts>,
  options?: QueryOptionsType<ApiResponseType<typeof getTradingAccounts>, ReturnType<typeof transformAccounts>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getTradingAccounts>, ReturnType<typeof transformAccounts>>({
    queryKey: accountsQueryKeys.accounts(filters),
    queryFn: () => getTradingAccounts(filters),
    select: transformAccounts,
    ...options,
  });
};

export const useAccountsQuery = (
  filters: RequestParamsWithPaging<ApiRequestParamsType<typeof getTradingAccounts>>,
  options?: QueryOptionsType<ApiResponseType<typeof getTradingAccounts>>,
) => {
  return usePagingQuery<ApiResponseType<typeof getTradingAccounts>, ApiRequestParamsType<typeof getTradingAccounts>>(
    filters,
    {
      queryKey: accountsQueryKeys.accounts(filters),
      queryFn: getTradingAccounts,
      ...options,
    },
  );
};

export const useAccountQuery = (id: string, options?: QueryOptionsType<ApiResponseType<typeof getTradingAccount>>) => {
  return useBaseQuery<ApiResponseType<typeof getTradingAccount>>({
    queryKey: accountsQueryKeys.account(id),
    queryFn: () => getTradingAccount({ id }),
    ...options,
  });
};

const transformServers = (data: ApiResponseType<typeof getServers>) => data.items!;
export const useServersQuery = (
  options?: QueryOptionsType<ApiResponseType<typeof getServers>, ReturnType<typeof transformServers>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getServers>, ReturnType<typeof transformServers>>({
    queryKey: accountsQueryKeys.servers,
    queryFn: getServers,
    select: transformServers,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    ...options,
  });
};

export const useAccountsTotalBalanceQuery = (
  filters: ApiRequestParamsType<typeof getTradingAccountsTotalBalance>,
  options?: QueryOptionsType<ApiResponseType<typeof getTradingAccountsTotalBalance>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getTradingAccountsTotalBalance>>({
    queryKey: accountsQueryKeys.totalBalance(filters),
    queryFn: () => getTradingAccountsTotalBalance(filters),
    ...options,
  });
};

export const useLastAccountQuery = (
  filters: ApiRequestParamsType<typeof getLastAccount>,
  options?: QueryOptionsType<ApiResponseType<typeof getLastAccount>>,
) => {
  return useBaseQuery<ApiResponseType<typeof getLastAccount>>({
    queryKey: accountsQueryKeys.last(),
    queryFn: () => getLastAccount(filters),
    cacheTime: 0,
    ...options,
  });
};
