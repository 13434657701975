import { FC } from "react";

import { DialogDescriptionText } from "@/components/dialog/styles";
import { Flex } from "@/components/flex";
import { EmailField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { Stack } from "@/components/stack";
import { AccountDialogContent } from "@/features/dashboard/components/dialogs/styles";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { Dialog } from "@/shared/ui";
import { useUpdateUserEmailMutation } from "@/state/server/profile/profile.mutations";

enum ChangeEmailFields {
  EMAIL = "email",
}

interface IChangeEmailFormValues {
  [ChangeEmailFields.EMAIL]: string;
}

type Props = {
  code?: string;
  userId?: string;
};

export const ChangeEmailDialogContent: FC<Props> = ({ code, userId }) => {
  const { t } = useTranslation();

  const { isSuccess, mutateAsync: updateEmail } = useUpdateUserEmailMutation();

  const form = useHookForm<IChangeEmailFormValues>();

  const handleSubmit = ({ email }: IChangeEmailFormValues) => {
    return updateEmail({ emailRequest: { email, code, userId } });
  };

  return !isSuccess ? (
    <HookForm form={form} onSubmit={handleSubmit}>
      <AccountDialogContent title={t("profile-settings.change-email.title")}>
        <Stack>
          <Dialog.Description asChild>
            <DialogDescriptionText align="left">{t("profile-settings.change-email.text-1")}</DialogDescriptionText>
          </Dialog.Description>
          <Dialog.Description asChild>
            <DialogDescriptionText align="left">{t("profile-settings.change-email.text-2")}</DialogDescriptionText>
          </Dialog.Description>
          <EmailField name={ChangeEmailFields.EMAIL} placeholder={t("fields.email.your-new-email")!} />
        </Stack>
        <Flex css={{ justifyContent: "center", mt: "20px" }}>
          <SubmitButton>{t("button.continue")}</SubmitButton>
        </Flex>
      </AccountDialogContent>
    </HookForm>
  ) : (
    <AccountDialogContent title={t("profile-settings.change-email.confirm-new-email")}>
      <Stack>
        <Dialog.Description asChild>
          <DialogDescriptionText>{t("profile-settings.change-email.confirm-text-1")}</DialogDescriptionText>
        </Dialog.Description>
        <Dialog.Description asChild>
          <DialogDescriptionText>{t("profile-settings.change-email.confirm-text-2")}</DialogDescriptionText>
        </Dialog.Description>
        <Dialog.Description asChild>
          <DialogDescriptionText>{t("profile-settings.change-email.confirm-text-3")}</DialogDescriptionText>
        </Dialog.Description>
      </Stack>
    </AccountDialogContent>
  );
};
