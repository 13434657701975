import { FC } from "react";

import { PopoverClose } from "@/components/popover";
import { BonusUserPlatform, TradingAccount } from "@/services/openapi";

import { useTerminalAccountContext } from "../contexts/account.context";
import { AccountsSelectInfo } from "./info";
import { AccountsSelectRow } from "./row";

type Props = {
  currentAccount: TradingAccount;
  setAccount: ReturnType<typeof useTerminalAccountContext>["setAccount"];
  accounts: TradingAccount[];
  onClose: () => void;
  showBonus: boolean;
  bonus: BonusUserPlatform | null;
};

const PopoverContent: FC<Props> = ({ accounts, currentAccount, setAccount, onClose, showBonus, bonus }) => {
  return (
    <>
      <AccountsSelectRow active account={currentAccount} />
      <AccountsSelectInfo onClose={onClose} showBonus={showBonus} bonus={bonus} />
      {accounts.map(account => (
        <PopoverClose asChild key={account.id}>
          <AccountsSelectRow isButton account={account} onClick={() => setAccount(account.id!)} />
        </PopoverClose>
      ))}
    </>
  );
};

export { PopoverContent as AccountsSelectPopoverContent };
