import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { NewDialogContent } from "@/components/new-dialog";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { TradingAccount, TradingServerPlatform } from "@/services/openapi";
import { Button } from "@/shared/ui";
import { useUpdateLastAccountMutation } from "@/state/server/accounts";

import { MetatraderInstallButton } from "./metatrader-install-button";
import { AccountPlatformInfo } from "./platform-info";

type Props = {
  account: TradingAccount;
};

export const TradeButtonDialogContent: FC<Props> = ({ account }) => {
  const { t } = useTranslation();
  const { mutateAsync: updateLastAccount } = useUpdateLastAccountMutation();
  const navigate = useNavigate();

  const version = account.platform === TradingServerPlatform.MetaTrader5 ? 5 : 4;

  const onClick = () => {
    if (account.platform === TradingServerPlatform.MetaTrader5) {
      navigate(getTerminalRoute(account.id!));
    } else {
      updateLastAccount({ id: account.id! });
      navigate(cabinetRoutes.mt4Terminal);
    }
  };

  return (
    <NewDialogContent title={account.platformTitle!}>
      <AccountPlatformInfo account={account}>
        <Button onClick={onClick}>{t("accounts.metatrader.trade-in-browser", { version })}</Button>
        <MetatraderInstallButton version={version} account={account} />
      </AccountPlatformInfo>
    </NewDialogContent>
  );
};
