import * as Popover from "@radix-ui/react-popover";
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  type FC,
  forwardRef,
  type ReactNode,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { PositiveNumberFormat } from "@/app/components";
import { SidebarButton } from "@/components/sidebar";
import { IconChevronDown } from "@/domains/icons";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useLocalStorage } from "@/hooks/localstorage.hook";
import { useTerminalAutoRoute } from "@/hooks/terminal-auto-route.hook";
import { useToPageState } from "@/hooks/to-page-state.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { Button } from "@/shared/ui";
import { useAccountsTotalBalanceQuery } from "@/state/server/accounts";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { CurrencySelectPopoverContent, LOCAL_STORAGE_CURRENCY } from "./popover-content";

const TradeButton = forwardRef<ElementRef<typeof Button>, ComponentPropsWithoutRef<typeof Button>>(
  ({ onClick, ...props }, ref) => {
    const { t } = useTranslation();
    const { isLoading, open } = useTerminalAutoRoute();

    return (
      <Button
        pending={isLoading}
        onClick={e => {
          onClick?.(e);
          open();
        }}
        size="sm"
        data-test="trade-btn"
        ref={ref}
        {...props}
      >
        {t("button.trade")}
      </Button>
    );
  },
);

const DepositButton = forwardRef<ElementRef<typeof Button>, ComponentPropsWithoutRef<typeof Button>>((props, ref) => {
  const { t } = useTranslation();
  const { toPageState } = useToPageState("header");

  return (
    <Button asChild variant="secondary" size="sm" data-test="deposit-btn" ref={ref} {...props}>
      <Link to={cabinetRoutes.deposit} state={toPageState}>
        {t("cabinet.navigation.deposit")}
      </Link>
    </Button>
  );
});

type Props = {
  title: string;
  children?: ReactNode;
  showBalance?: boolean;
};

const CabinetTitle: FC<Props> = ({ title, children, showBalance = true }) => {
  const [localCurrency, setLocalCurrency] = useLocalStorage(LOCAL_STORAGE_CURRENCY, "USD");
  const [opened, { onOpenChange }] = useDisclosure();
  const { currencies } = useProfileData();
  const [currency, setCurrency] = useState(localCurrency);
  const { data: balance } = useAccountsTotalBalanceQuery({ currency: currency });
  const shouldRenderBalance = showBalance && !!balance;

  const onSelect = (currency: string) => {
    setLocalCurrency(currency);
    setCurrency(currency);
  };

  useEffect(() => {
    if (currencies?.length === 1) {
      onSelect(currencies[0]);
    }
  }, [currencies]);

  return (
    <div className="flex flex-wrap items-center justify-between gap-4 pt-4 lg:px-0 lg:py-5">
      <div className="flex items-center gap-4">
        <SidebarButton />
        <h1 className="font-gilroy text-[24px] font-semibold leading-[1.2] text-text lg:text-[32px]">{title}</h1>
      </div>
      {shouldRenderBalance && (
        <div className="font-roboto text-[14px] leading-[1.5] text-text lg:hidden">
          <PositiveNumberFormat value={balance.amount} currency={balance.currency!} />
        </div>
      )}

      <div className="hidden items-center gap-4 lg:flex">
        {shouldRenderBalance &&
          (currencies && currencies.length > 1 ? (
            <div className="font-gilroy text-[22px] font-semibold leading-[1.2] text-text">
              <Popover.Root modal open={opened} onOpenChange={onOpenChange}>
                <Popover.Trigger asChild>
                  <button>
                    <span className="flex items-center gap-1">
                      {currency !== balance.currency ? (
                        <PositiveNumberFormat
                          value={balance.amount}
                          currency={currency ?? balance.currency!}
                          decimalScale={2}
                        />
                      ) : (
                        <PositiveNumberFormat value={balance.amount} currency={currency ?? balance.currency} />
                      )}
                      <div className="hidden lg:block">
                        <IconChevronDown />
                      </div>
                    </span>
                  </button>
                </Popover.Trigger>
                <Popover.Portal>
                  <Popover.Content sideOffset={16} align="end">
                    <CurrencySelectPopoverContent
                      defaultCurrency={currency}
                      onSelect={onSelect}
                      currencies={currencies}
                    />
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            </div>
          ) : (
            <div className="font-gilroy text-[22px] font-semibold leading-[1.2] text-text">
              <PositiveNumberFormat value={balance.amount} currency={balance.currency!} />
            </div>
          ))}
        {children}
      </div>
    </div>
  );
};

const Component = Object.assign(CabinetTitle, { TradeButton, DepositButton });

export { Component as CabinetTitle };
