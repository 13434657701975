import { ComponentProps, FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { TradingAccountType } from "@/services/openapi";
import { Badge } from "@/shared/ui";

type Props = ComponentProps<typeof Badge> & {
  accountType: TradingAccountType;
  fullTitle?: boolean;
};

export const AccountBadge: FC<Props> = ({ accountType, fullTitle, ...props }) => {
  const { t } = useTranslation();

  return accountType === TradingAccountType.Real ? (
    <Badge variant="blue" {...props}>
      {fullTitle ? t("common.real-account") : t("common.real")}
    </Badge>
  ) : (
    <Badge variant="orange" {...props}>
      {fullTitle ? t("common.demo-account") : t("common.demo")}
    </Badge>
  );
};
