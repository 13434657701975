import * as DialogPrimitive from "@radix-ui/react-dialog";

const Component = Object.assign(DialogPrimitive.Root, {
  Trigger: DialogPrimitive.Trigger,
  Portal: DialogPrimitive.Portal,
  Overlay: DialogPrimitive.Overlay,
  Content: DialogPrimitive.Content,
  Title: DialogPrimitive.Title,
  Description: DialogPrimitive.Description,
  Close: DialogPrimitive.Close,
});

export { Component as Dialog };
