import { createContext, memo, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { logError } from "@/app/libs/sentry";
import { TradingCentralSignalType } from "@/services/openapi";

import { terminalCommands } from "../helpers/socket.commands";
import { convertSocketWidget, isSymbolAvailable, MergedTerminalSymbol } from "../helpers/symbols";
import { useTerminalSocket } from "../hooks/socket.hook";
import { useSymbolsContext } from "./symbols.context";

type ContextProps = {
  symbolInfo: MergedTerminalSymbol;
  currentSymbol: string;
  setSymbol: (symbol: string) => void;
  isTradingAvailable: boolean;
};

const Context = createContext<ContextProps | undefined>(undefined);

const Provider = memo(
  ({
    children,
    symbol,
    changeSymbol,
  }: {
    children: ReactNode;
    symbol: string;
    changeSymbol: (symbol: string) => void;
  }) => {
    const { symbolsList } = useSymbolsContext();
    const [symbolInfo, setSymbolInfo] = useState<MergedTerminalSymbol>(() => symbolsList[symbol]!);

    const { sendJsonMessage, lastJsonMessage: socketMessage } = useTerminalSocket();

    const updateSymbol: ContextProps["setSymbol"] = useCallback(
      symbol => {
        const symbolInfo = symbolsList[symbol]!;
        changeSymbol(symbol);
        setSymbolInfo(symbolInfo);
        sendJsonMessage(terminalCommands.tickSubscribe(symbol));
      },
      [changeSymbol, symbolsList, sendJsonMessage],
    );

    useEffect(() => {
      sendJsonMessage(terminalCommands.tickSubscribe(symbol));
    }, []);

    useEffect(() => {
      if (!socketMessage || !socketMessage.dt) return;
      try {
        const { dt } = socketMessage;
        if (symbolInfo.symbol === dt.s) {
          setSymbolInfo(prev => ({
            ...prev,
            eventTime: dt.t,
            priceAsk: dt.a,
            priceBid: dt.b,
          }));
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    useEffect(() => {
      try {
        if (!socketMessage || !socketMessage.dlp) return;

        const symbolUpdate = socketMessage.dlp.p!.find(({ s }) => s === symbolInfo.symbol);

        if (symbolUpdate) {
          setSymbolInfo(prev => ({
            ...prev,
            priceLast24H: symbolUpdate.p!,
            eventTime: socketMessage.dlp!.t!,
          }));
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    useEffect(() => {
      try {
        if (!socketMessage || !socketMessage.dss) return;

        const symbolUpdate = socketMessage.dss.p!.find(({ s }) => s === symbolInfo.symbol)!;

        if (symbolUpdate) {
          const { pr1, pr2, ps1, ps2, pt, t } = symbolUpdate;
          setSymbolInfo(prev => ({
            ...prev,
            signal: {
              type: t as any as TradingCentralSignalType,
              priceTarget: pt,
              priceResistance1: pr1,
              priceResistance2: pr2,
              priceSupport1: ps1,
              priceSupport2: ps2,
            },
            eventTime: socketMessage.dss!.t!,
          }));
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    useEffect(() => {
      try {
        if (!socketMessage || !socketMessage.dsw) return;

        const symbolUpdate = socketMessage.dsw.w!.find(({ s }) => s === symbolInfo.symbol)!;

        if (symbolUpdate) {
          const { b, sr, ss, t } = symbolUpdate;
          setSymbolInfo(prev => ({
            ...prev,
            widget: convertSocketWidget({ b, sr, ss, t }),
            eventTime: socketMessage.dsw!.t!,
          }));
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    useEffect(() => {
      try {
        if (!socketMessage || !socketMessage.dse) return;

        const symbolUpdate = socketMessage.dse.c!.find(({ s }) => s === symbolInfo.symbol)!;

        if (symbolUpdate) {
          const { d, e, t } = symbolUpdate;
          setSymbolInfo(prev => ({
            ...prev,
            state: t,
            periodDateEnd: e,
            periodDuration: d,
            eventTime: socketMessage.dse!.t!,
          }));
        }
      } catch (error) {
        logError(error);
      }
    }, [socketMessage]);

    const isTradingAvailable: ContextProps["isTradingAvailable"] = useMemo(
      () => isSymbolAvailable(symbolInfo.state!),
      [symbolInfo.state],
    );

    const value: ContextProps = useMemo(
      () => ({
        currentSymbol: symbol,
        symbolInfo,
        setSymbol: updateSymbol,
        isTradingAvailable,
      }),
      [symbolInfo, symbol, isTradingAvailable, updateSymbol],
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
  },
);

Provider.displayName = "TerminalCurrentSymbolContextProvider";

const useCurrentSymbolContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useCurrentSymbolContext must be used within a TerminalCurrentSymbolContextProvider");
  }

  return context;
};

export { Provider as TerminalCurrentSymbolContextProvider, useCurrentSymbolContext };
