import { FC } from "react";
import { Link } from "react-router-dom";

import { track } from "@/app/libs/amplitude";
import { Button } from "@/components/button";
import { Text, TextBad } from "@/components/text";
import { styled } from "@/styles";

import { Props, WidgetButtonProps } from "./types";

const StyledText = styled(Text, {
  lineHeight: "$3",
  color: "$darkGray !important",
});

const WidgetButton: FC<WidgetButtonProps> = ({ style, action, text }) => {
  const { web, analyticsAction, type } = action;

  return (
    <Link to={web!} onClick={() => track(analyticsAction ?? "")}>
      <Button
        variant={style === "Secondary" ? "secondary" : "primary"}
        size="small"
        css={{ width: "100%", "@bp2": { width: "auto" } }}
      >
        {text?.title}
      </Button>
    </Link>
  );
};

export const CompleteProfileInfo: FC<Props> = ({ text, action, children }) => {
  return (
    <div className="flex flex-col items-center justify-center rounded-[16px] bg-bg px-8 py-[60px] text-center md:mb-[30px]">
      <TextBad className="mb-2 text-[16px] leading-[22px] md:text-[22px] md:leading-[24px]" weight="bold">
        {text?.title}
      </TextBad>
      <StyledText
        family="roboto"
        size={{ "@initial": "1", "@bp2": "2" }}
        lineHeight={{ "@initial": "2", "@bp2": "3" }}
        css={{ mb: 24 }}
      >
        {text?.subtitle}
      </StyledText>

      <div className="flex flex-col items-center gap-2">
        {children?.map((child, index) => {
          if (child.type === "Button") return <WidgetButton key={index} {...child} />;
          else return null;
        })}
      </div>
    </div>
  );
};
