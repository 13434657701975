import dayjs from "dayjs";
import Calendar from "dayjs/plugin/calendar";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(Calendar);
dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export const convertDateToISOString = (date: dayjs.ConfigType, toDate?: boolean) => {
  if (!date) {
    return null;
  }

  const dateString = dayjs(date).startOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  if (toDate) {
    return new Date(dateString);
  }

  return dateString;
};

export const isYoungerThan = (date: dayjs.ConfigType, minAge: number) => {
  const minDateOfBirth = dayjs().subtract(minAge, "year");
  return dayjs(date).isAfter(minDateOfBirth);
};
