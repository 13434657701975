import { type FC, lazy, Suspense, useCallback } from "react";

import { CheckboxField, CountryField, DateField, TextField, TextPhoneField } from "@/components/form/fields";
import { HookForm } from "@/components/form/hook-form";
import { SubmitButton } from "@/components/form/submit-button";
import { useHookForm } from "@/hooks/form.hook";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { AddressInfo, FeatureTours, PlatformCountry } from "@/services/openapi";
import { ProfileInfo } from "@/services/openapi/models/profile-info";
import { usePlatformData } from "@/state/server/platform/platform.hooks";
import { isYoungerThan } from "@/utils/dates";

import { OnboardingContainer } from "../../onboarding.container";
import { NavigationTypes } from "../navigation/navigation.helpers";
import { OnboardingMobileNavigation } from "../navigation/onboarding-mobile-navigation";

const LazyOnboardingFooter = lazy(() =>
  import("../footer/onboarding-footer").then(module => ({
    default: module.OnboardingFooter,
  })),
);

export enum AddressFields {
  PHONE_NUMBER = "phoneNumber",
  POSTAL_CODE = "postalCode",
  STREET = "street",
  CITY = "city",
  COUNTRY = "country",
  IS_NOT_USA_RESIDENT = "isNotUSAResident",
  PHONE_COUNTRY = "phoneCountry",
  PHONE_CODE = "phoneCode",
}

enum ProfileFields {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  PHONE_NUMBER = "phoneNumber",
  DATE_OF_BIRTH = "dateOfBirth",
  COUNTRY = "country",
}

export interface IProfileFormValues {
  [ProfileFields.FIRST_NAME]: string;
  [ProfileFields.LAST_NAME]: string;
  [AddressFields.PHONE_NUMBER]: string;
  [AddressFields.PHONE_COUNTRY]: string;
  [AddressFields.PHONE_CODE]: string;
  [ProfileFields.COUNTRY]: string;
  [ProfileFields.DATE_OF_BIRTH]: string;
  [AddressFields.IS_NOT_USA_RESIDENT]: boolean;
}

interface Props {
  profile: ProfileInfo;
  address: AddressInfo;
  navigationParams: NavigationTypes;
  featureTourInfo: FeatureTours;
  unsupportedCountries: PlatformCountry[];
  onSubmit: (values: IProfileFormValues) => void;
}

export const ProfileForm: FC<Props> = ({ profile, address, navigationParams, unsupportedCountries, onSubmit }) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const { validation } = usePlatformData();
  const ageMin = validation!.user!.ageMin;
  const phoneNumber = address?.phoneCode ? address?.phoneNumber?.replace(address?.phoneCode, "") ?? "" : "";

  const form = useHookForm<IProfileFormValues>({
    mode: "onChange",
    shouldFocusError: !isMobile,
    defaultValues: {
      [ProfileFields.FIRST_NAME]: profile.firstName || "",
      [ProfileFields.LAST_NAME]: profile.lastName || "",
      [ProfileFields.DATE_OF_BIRTH]: profile.dateOfBirth || undefined,
      [AddressFields.PHONE_NUMBER]: phoneNumber,
      [AddressFields.IS_NOT_USA_RESIDENT]: address?.isNotUSAResident || false,
      [ProfileFields.COUNTRY]: address.country || undefined,
      [AddressFields.PHONE_CODE]: address.phoneCode || undefined,
      [AddressFields.PHONE_COUNTRY]: address.phoneCountry || undefined,
    },
  });

  const { watch } = form;
  const countryValue = watch(ProfileFields.COUNTRY);
  const phoneCountryValue = watch(AddressFields.PHONE_COUNTRY);

  const showUnsupportedCountry = useCallback(
    (country: string) => {
      const isInvalidCountry = unsupportedCountries.map(({ country }) => country).includes(country);

      return isInvalidCountry ? t("form-errors.unsupported-country-error") : true;
    },
    [unsupportedCountries, t],
  );

  const validateDateOfBirthMain = useCallback(
    (date: string) => {
      const isValidDate = !!date && !isNaN(Date.parse(date));
      const isInvalidDate = !isValidDate || isYoungerThan(date, ageMin!);
      return isInvalidDate ? t("form-errors.min-age-error") : true;
    },
    [ageMin, t],
  );

  const validateDateOfBirth = useCallback(
    (date: string) => {
      // NOTE - валидация при вводе отрабатывает только когда год введен полнотсью YYYY
      if (date.split("-")[0].length === 4) {
        return validateDateOfBirthMain(date);
      }
    },
    [validateDateOfBirthMain],
  );

  const onBlurValidationDateOfBirth = useCallback(
    (date: string | null | undefined) => {
      if (date) {
        // NOTE - валидация отрабывает при onBlur с компонента DateField
        const validateMessage = validateDateOfBirthMain(date);

        if (typeof validateMessage === "string") {
          form.setError("dateOfBirth", { type: "string", message: validateMessage }, { shouldFocus: true });
        }
      }
    },
    [form, validateDateOfBirthMain],
  );

  const handleSubmit = (values: IProfileFormValues) => {
    const normalizePhoneNumber = values.phoneNumber.replaceAll(" ", "");

    onSubmit({ ...values, phoneNumber: normalizePhoneNumber });
  };

  return (
    <HookForm form={form} onSubmit={handleSubmit}>
      <OnboardingContainer
        title={t("onboarding.profile.header")}
        description={t("onboarding.profile.description")!}
        {...navigationParams}
      >
        <div className="flex flex-col gap-8 lg:max-w-[446px]">
          <TextField
            name={ProfileFields.FIRST_NAME}
            label={t("onboarding.profile.first-name")}
            placeholder={t("onboarding.profile.first-name-placeholder")!}
            registerOptions={{
              required: t("form-errors.required-error")!,
              /* pattern: { value: /^[A-z ]+$/, message: t("form-errors.name-error") }, */
            }}
          />
          <TextField
            name={ProfileFields.LAST_NAME}
            label={t("onboarding.profile.last-name")}
            placeholder={t("onboarding.profile.last-name-placeholder")!}
            registerOptions={{
              required: t("form-errors.required-error")!,
              /* pattern: { value: /^[A-z ]+$/, message: t("form-errors.name-error") }, */
            }}
          />
          <DateField
            name={ProfileFields.DATE_OF_BIRTH}
            label={t("fields.date.date-of-birth")}
            showIcon={false}
            rules={{ required: t("form-errors.required-error")!, validate: validateDateOfBirth }}
            onBlurValidation={onBlurValidationDateOfBirth}
          />
          <TextPhoneField
            namePhone={AddressFields.PHONE_NUMBER}
            nameCode={AddressFields.PHONE_CODE}
            nameCountry={AddressFields.PHONE_COUNTRY}
            label={t("fields.phone-number.label")}
            defaultCountry={phoneCountryValue || countryValue}
            errors={{
              required: t("fields.phone-number.required-error")!,
              minLength: t("fields.phone-number.valid-error")!,
            }}
            inputProps={{
              placeholder: t("fields.phone-number.placeholder")!,
            }}
            comboBoxProps={{
              emptyMessage: t("fields.phone-number.search-empty-message"),
              inputProps: {
                placeholder: t("fields.phone-number.search-placeholder"),
              },
              avoidCollisions: false,
            }}
          />
          <CountryField
            isSearchable
            name={ProfileFields.COUNTRY}
            label={t("fields.citizenship.citizenship")}
            placeholder={t("fields.citizenship.citizenship", { country: countryValue })}
            rules={{ required: t("form-errors.required-error")!, validate: showUnsupportedCountry }}
          />
          <CheckboxField
            name={AddressFields.IS_NOT_USA_RESIDENT}
            label={t("onboarding.address.us-citizen-checkbox")}
            registerOptions={{ required: t("form-errors.confirm-to-continue")! }}
          />
        </div>
      </OnboardingContainer>
      <Suspense fallback={<></>}>
        {!isMobile ? (
          <LazyOnboardingFooter
            right={
              <SubmitButton checkDirty={false} disabled={false} checkValid={true}>
                {t("button.next")}
              </SubmitButton>
            }
          />
        ) : (
          <OnboardingMobileNavigation {...navigationParams} />
        )}
      </Suspense>
    </HookForm>
  );
};
