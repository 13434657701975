import { cva, VariantProps } from "class-variance-authority";
import { forwardRef, HtmlHTMLAttributes } from "react";

import { NumberFormat } from "@/app/components";
import { AccountBadge } from "@/components/badge/account-badge";
import { TradingAccount } from "@/services/openapi";
import { cn } from "@/shared/styles";

const buttonStyles = cva(
  "flex h-[60px] w-full items-center gap-4 bg-bg px-4 outline-none transition-colors hover:bg-card-bg-hover lg:h-16",
  {
    variants: {
      active: {
        true: "!bg-card-bg-active",
      },
    },
  },
);

const textStyles = cva(
  "whitespace-nowrap font-roboto text-[14px] leading-[1.5] text-contrast-primary lg:text-[16px]",
)();

type Props = Pick<HtmlHTMLAttributes<HTMLElement>, "onClick" | "children" | "className"> &
  VariantProps<typeof buttonStyles> & {
    account: TradingAccount;
    isButton?: boolean;
  };

const AccountsSelectRow = forwardRef<HTMLElement, Props>(({ account, active, className, isButton, ...props }, ref) => {
  const { type, name, login, currency, equity, digits } = account;

  const Comp = isButton ? "button" : "div";

  return (
    <Comp ref={ref as any} className={cn(buttonStyles({ active }), className)} {...props}>
      <AccountBadge accountType={type!} />
      <div className="flex grow flex-col items-start truncate lg:gap-0.5">
        <div className={cn(textStyles, "text-[12px] text-contrast-secondary lg:text-[14px]")}>#{login}</div>
      </div>
      <div className={textStyles}>
        <NumberFormat currency={currency!} value={equity} decimalScale={digits} />
      </div>
    </Comp>
  );
});

export { AccountsSelectRow };
