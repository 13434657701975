import { createSearchParams, To } from "react-router-dom";

export const cabinetRoutes = {
  dashboard: "/my-accounts",
  createAccount: "/my-accounts/create-account",
  deposit: "/deposit",
  depositDetails: "/deposit/details",
  withdrawal: "/withdrawal",
  transfer: "/transfer",
  transactionHistory: "/transaction-history",
  trading: "/web-trading",
  mt4Terminal: "/web-trading/mt4",
  mt5Terminal: "/web-trading/mt5",
  notifications: "/notifications",
  profileSettings: "/profile",
  depositRedirect: "/deposit/redirect",
};

const getDepositRoute = (accountId: string): To => ({
  pathname: cabinetRoutes.deposit,
  search: createSearchParams({ accountId }).toString(),
});

export { getDepositRoute };
