import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { track } from "@/app/libs/amplitude";

/**
 *  Sends the required properties for the specified Amplitude page visit event
 */

const useTrackVisitPage = (eventName: string) => {
  const { state } = useLocation();

  useEffect(() => {
    track(eventName, {
      entryPage: state?.from?.entryPage,
      entryPoint: state?.from?.entryPoint,
    });
  }, [state?.from]);
};

export { useTrackVisitPage };
