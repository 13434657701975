import { type FC, Fragment, type ReactNode, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { GlobalLoader } from "@/components/loader";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { terminalAccountParamsName, terminalRoutes } from "@/routes/terminal.routes";

import { TerminalProvider } from "./contexts/terminal.provider";
import { useTerminalHandleSymbol } from "./handle-symbol.hook";
import { TerminalDialogsContainer } from "./layout/dialogs/container";
import { TerminalDesktopSkeleton } from "./loaders/desktop-skeleton";
import { TerminalMobileSkeleton } from "./loaders/mobile-skeleton";
import { useTerminalHandle } from "./terminal-handle.hook";

const Symbol: FC<
  { children: (value: { symbol: string; changeSymbol: (symbol: string) => void }) => ReactNode } & Parameters<
    typeof useTerminalHandleSymbol
  >[0]
> = ({ children, ...props }) => {
  const { symbol, changeSymbol, isLoading } = useTerminalHandleSymbol(props);

  if (isLoading) {
    return null;
  }

  return children({ symbol: symbol!, changeSymbol }) as JSX.Element;
};

const TerminalContainer: FC<{ children?: ReactNode; simpleLoader?: boolean }> = ({ children, simpleLoader }) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const accountId = searchParams.get(terminalAccountParamsName);

  const { isDesktop } = useScreenSize();

  const isMobile = !isDesktop;
  const isPositionsPage = terminalRoutes.terminalPositionsMobile === location.pathname;
  const isMobileSymbolPage = terminalRoutes.terminalSymbolMobile === location.pathname;

  const isMobileAppMode = useMemo(
    () => location.pathname === terminalRoutes.terminalMobile || searchParams.has("isWebView"),
    [location, searchParams],
  );

  const { socketToken, account, showLoader, bonuses, favorites, symbolsUpdates, symbols } = useTerminalHandle({
    accountIdUrl: accountId,
    isMobileAppMode,
  });

  if (showLoader) {
    if (simpleLoader) {
      return <GlobalLoader />;
    }

    return isMobile ? <TerminalMobileSkeleton isMobileSymbolPage={isMobileSymbolPage} /> : <TerminalDesktopSkeleton />;
  }

  return (
    <Fragment key={accountId + socketToken}>
      <Symbol favorites={favorites} symbols={symbols} accountId={account.id!}>
        {({ symbol, changeSymbol }) => (
          <TerminalProvider
            isPositionsPage={isPositionsPage}
            isMobileSymbolPage={isMobileSymbolPage}
            isMobile={isMobile}
            account={account}
            bonuses={bonuses.items!}
            token={socketToken}
            favorites={favorites}
            symbolsUpdates={symbolsUpdates}
            symbols={symbols}
            changeSymbol={changeSymbol}
            symbol={symbol}
            isMobileAppMode={isMobileAppMode}
          >
            {children}
            <TerminalDialogsContainer />
          </TerminalProvider>
        )}
      </Symbol>
    </Fragment>
  );
};

TerminalContainer.displayName = "TerminalContainer";

export { TerminalContainer };
