import { FC, ReactNode } from "react";

import { Text } from "@/components/text";
import { CSS } from "@/styles";

interface Props {
  as?: any;
  css?: CSS;
  children: ReactNode;
}

export const OnboardingTitle: FC<Props> = ({ children, as = "h3", css }) => (
  <Text weight="bold" size="4" as={as} css={css}>
    {children}
  </Text>
);

export const OnboardingDescription: FC<Props> = ({ children, as = "p", css }) => (
  <Text family="roboto" as={as} css={css} color="dimmed">
    {children}
  </Text>
);

export const OnboardingFieldWrapper: FC<{ children?: ReactNode }> = ({ children }) => (
  <div className="lg:max-w-[60%]">{children}</div>
);
