import { IPositionLineAdapter } from "public/charting_library/charting_library";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { numericFormatter } from "react-number-format";

import { TerminalCloseOrderType } from "@/services/openapi";
import { useCloseTerminalOrdersMutation } from "@/state/server/terminal";

import { OpenOrder } from "../../contexts/extended-orders.context";
import { getOrderTypeText, getTypeColor } from "../../trading-tables/components/trading-table/order-type";
import { ChartColors } from "../config/colors";
import { chartLineFont } from "../config/fonts";

const formatPnl = ({ value, currency, decimalScale }: { value: number; currency: string; decimalScale: number }) => {
  return numericFormatter(String(value), {
    prefix: value > 0 ? "+" : undefined,
    fixedDecimalScale: true,
    thousandSeparator: ",",
    suffix: ` ${currency}`,
    decimalScale,
  });
};

type Props = {
  order: OpenOrder;
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  chartColors: ChartColors;
};

const ChartOpenOrder: React.FC<Props> = ({ order, accountId, accountCurrency, chartColors, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const ref = useRef<IPositionLineAdapter | null>(null);

  const { mutate: closeOrder } = useCloseTerminalOrdersMutation();

  useEffect(() => {
    window.tvWidget.activeChart().dataReady(() => {
      // https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.IChartWidgetApi#createpositionline
      try {
        ref.current = window.tvWidget
          .activeChart()
          .createPositionLine()
          .setLineStyle(5)
          .setBodyFont(chartLineFont)
          .setQuantityFont(chartLineFont)
          // updatable params
          .setLineColor(
            getTypeColor(order.type) === "positive" ? chartColors.positivePressed : chartColors.negativePressed,
          )
          .setQuantityTextColor(chartColors.orderLineText)
          .setBodyBackgroundColor(chartColors.orderLineBackground)
          .setBodyBorderColor(chartColors.orderLineBackground)
          .setCloseButtonBackgroundColor(chartColors.orderLineBackground)
          .setCloseButtonBorderColor(chartColors.orderLineBackground)
          .setCloseButtonIconColor(chartColors.iconClose)
          .setQuantityBackgroundColor(chartColors.orderLineBackground)
          .setQuantityBorderColor(chartColors.orderLineBackground)
          .setQuantity(`${getOrderTypeText(t, order.type)}: ${String(order.volume)}`)
          .setBodyTextColor(order.pnl >= 0 ? chartColors.positive : chartColors.negative)
          .setPrice(order.price)
          .setText(formatPnl({ currency: accountCurrency, value: order.pnl, decimalScale: currencyDecimalScale }))
          .onClose(() =>
            closeOrder({
              tradingAccountId: accountId,
              terminalCloseOrderRequest: { id: order.id, type: TerminalCloseOrderType.Market },
            }),
          );
      } catch (e) {
        console.log(e, "error chart open order");
      }
    });

    return () => {
      ref.current && ref.current.remove();
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      window.tvWidget.activeChart().dataReady(() => {
        ref
          .current!.setLineColor(
            getTypeColor(order.type) === "positive" ? chartColors.positivePressed : chartColors.negativePressed,
          )
          .setQuantityTextColor(chartColors.orderLineText)
          .setBodyBackgroundColor(chartColors.orderLineBackground)
          .setBodyBorderColor(chartColors.orderLineBackground)
          .setCloseButtonBackgroundColor(chartColors.orderLineBackground)
          .setCloseButtonBorderColor(chartColors.orderLineBackground)
          .setCloseButtonIconColor(chartColors.iconClose)
          .setQuantityBackgroundColor(chartColors.orderLineBackground)
          .setQuantityBorderColor(chartColors.orderLineBackground)
          .setBodyTextColor(order.pnl >= 0 ? chartColors.positive : chartColors.negative);
      });
    }
  }, [chartColors]);

  useEffect(() => {
    if (ref.current) {
      window.tvWidget.activeChart().dataReady(() => {
        ref
          .current!.setQuantity(`${getOrderTypeText(t, order.type)}: ${String(order.volume)}`)
          .setLineColor(
            getTypeColor(order.type) === "positive" ? chartColors.positivePressed : chartColors.negativePressed,
          )
          .setBodyTextColor(order.pnl >= 0 ? chartColors.positive : chartColors.negative)
          .setPrice(order.price)
          .setText(formatPnl({ currency: accountCurrency, value: order.pnl, decimalScale: currencyDecimalScale }))
          .onClose(() =>
            closeOrder({
              tradingAccountId: accountId,
              terminalCloseOrderRequest: { id: order.id, type: TerminalCloseOrderType.Market },
            }),
          );
      });
    }
  }, [order]);

  return null;
};

ChartOpenOrder.displayName = "ChartOpenOrder";

export { ChartOpenOrder };
