import { FC, memo, useMemo } from "react";

import { NumberFormat, PnlFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { getDecimalScaleFromTickSize } from "@/features/terminal/helpers/formatting";
import { useAddChartSymbol } from "@/features/terminal/hooks/add-chart-symbol.hook";
import { terminalFormatDate } from "@/features/terminal/place-order/place-order.helpers";
import { TerminalPosition } from "@/services/openapi";

import { ClosedPositionVolume } from "../../closed-positions/volume";
import { OrderType } from "../../components/trading-table/order-type";
import { TradingTable } from "../table";

type Props = {
  position: TerminalPosition;
  accountId: string;
  currencyDecimalScale: number;
  setSymbol: (symbol: string) => void;
};

const _ClosedPositionsBodyRow: FC<Props> = ({ position, setSymbol, accountId, currencyDecimalScale }) => {
  const {
    symbol,
    type,
    stopLoss,
    takeProfit,
    swap,
    profit,
    dateOpen,
    volumeSell,
    volumeBuy,
    priceOpen,
    dateClose,
    priceClose,
    position: positionNumber,
    symbolDigits,
    symbolVolumeStep,
  } = position;

  const volumeDecimalScale = useMemo(() => getDecimalScaleFromTickSize(symbolVolumeStep!), [symbolVolumeStep]);

  const { addChartSymbol } = useAddChartSymbol({ setSymbol, accountId });

  return (
    <TradingTable.Row>
      <TradingTable.Cell>{terminalFormatDate(dateOpen!)}</TradingTable.Cell>
      <TradingTable.Cell>
        <TradingTable.Symbol symbol={symbol!} onClick={() => addChartSymbol(symbol!)} />
      </TradingTable.Cell>
      <TradingTable.Cell>
        <OrderType type={type!} />
      </TradingTable.Cell>
      <TradingTable.Cell>
        <ClosedPositionVolume
          decimalScale={volumeDecimalScale}
          type={type!}
          volumeBuy={volumeBuy}
          volumeSell={volumeSell}
        />
      </TradingTable.Cell>
      <TradingTable.Cell>
        <NumberFormat value={priceOpen} decimalScale={symbolDigits} />
      </TradingTable.Cell>
      <TradingTable.Cell>{positionNumber}</TradingTable.Cell>
      <TradingTable.Cell>
        {takeProfit ? <NumberFormat value={takeProfit} decimalScale={symbolDigits} /> : "—"}
      </TradingTable.Cell>
      <TradingTable.Cell>
        {stopLoss ? <NumberFormat value={stopLoss} decimalScale={symbolDigits} /> : "—"}
      </TradingTable.Cell>
      <TradingTable.Cell>{terminalFormatDate(dateClose!)}</TradingTable.Cell>
      <TradingTable.Cell>
        <NumberFormat value={priceClose} decimalScale={symbolDigits} />
      </TradingTable.Cell>
      <TradingTable.Cell align="end">
        <NumberFormat value={swap} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell className={getNumberColorClassname(profit!)} align="end">
        <PnlFormat value={profit} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
    </TradingTable.Row>
  );
};

const Component = memo(_ClosedPositionsBodyRow);

export { Component as ClosedPositionsBodyRow };
