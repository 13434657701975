import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { IconCross } from "@/domains/icons";

import { _Button, type _ExternalWrapperProps, _Input, _Wrapper } from "../_base";

type Props = Omit<ComponentPropsWithoutRef<typeof _Input>, "size" | "onChange" | "value"> &
  _ExternalWrapperProps & {
    value: string;
    onChange: (value: string) => void;
    clearable?: boolean;
  };

const TextInput = forwardRef<ElementRef<typeof _Input>, Props>(
  (
    {
      value,
      onChange,
      className,
      label,
      size,
      startSection,
      disabled,
      invalid,
      pending,
      clearable,
      descriptor,
      renderWrapper,
      endSection,
      tooltipContent,
      bottomLabel,
      ...props
    },
    ref,
  ) => {
    const showClearButton = clearable && value !== "";

    const hasEndSection = !!(showClearButton || endSection);

    return (
      <_Wrapper
        type="input"
        bottomLabel={bottomLabel}
        className={className}
        startSection={startSection}
        tooltipContent={tooltipContent}
        invalid={invalid}
        pending={pending}
        label={label}
        size={size}
        disabled={disabled}
        descriptor={descriptor}
        renderWrapper={renderWrapper}
        endSection={
          hasEndSection ? (
            <>
              {showClearButton && (
                <_Button disabled={disabled} onClick={() => onChange("")}>
                  <IconCross />
                </_Button>
              )}
              {endSection}
            </>
          ) : null
        }
      >
        <_Input disabled={disabled} value={value} onChange={e => onChange(e.target.value)} {...props} ref={ref} />
      </_Wrapper>
    );
  },
);

export { TextInput };
