import { FC } from "react";
import { Outlet } from "react-router-dom";

import { Box } from "@/components/box";
import { Grid } from "@/components/grid";
import { styled } from "@/styles/stitches.config";

import { OnboardingHeader } from "./onboarding-header";

const Wrapper = styled(Grid, {
  background: "$lightGray",
  minHeight: "100%",
  gridTemplateRows: "auto 1fr",
});

const Container = styled(Box, {
  mx: "auto",
  px: "24px",
  maxWidth: "$appContainer",
  mt: "32px",
});

export const OnboardingLayout: FC = () => {
  return (
    <Wrapper>
      <OnboardingHeader />
      <Box>
        <Container>
          <Outlet />
        </Container>
      </Box>
    </Wrapper>
  );
};
