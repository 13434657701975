import { type FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat, PnlFormat } from "@/app/components";
import { getNumberColorClassname } from "@/app/ui/colors";
import { TerminalPopover } from "@/features/terminal/components/popover";
import { useCurrentSymbolContext } from "@/features/terminal/contexts/current-symbol-context";
import { OpenOrder } from "@/features/terminal/contexts/extended-orders.context";
import { useAddChartSymbol } from "@/features/terminal/hooks/add-chart-symbol.hook";
import { terminalLocators } from "@/features/terminal/locators";
import { terminalFormatDate } from "@/features/terminal/place-order/place-order.helpers";

import { OrderType } from "../../components/trading-table/order-type";
import { PartialCloseContainer } from "../../modify-order/partial-close/partial-close.container";
import { ModifyOpenOrderStopLossContainer } from "../../modify-order/stop-loss/open-order-stop-loss.container";
import { ModifyOpenOrderTakeProfitContainer } from "../../modify-order/take-profit/open-order-take-profit.container";
import { ModifyOrderButton } from "../modify-order/button";
import { TradingTable } from "../table";
import { CloseOpenOrderButton } from "./close-button";

type Props = {
  order: OpenOrder;
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  setSymbol: ReturnType<typeof useCurrentSymbolContext>["setSymbol"];
};

const _OpenOrdersBodyRow: FC<Props> = ({ order, accountId, currency, setSymbol, currencyDecimalScale }) => {
  const {
    symbol,
    type,
    volume,
    price,
    stopLoss,
    takeProfit,
    id,
    date,
    swap,
    contractSize,
    volumeStep,
    volumeMin,
    volumeDecimalScale,
    baseCurrency,
    quoteCurrency,
    ask,
    bid,
    currentPrice,
    pnl,
    priceDecimalScale,
    margin,
    calculatedProfitRate,
  } = order;

  const { t } = useTranslation();

  const { addChartSymbol } = useAddChartSymbol({ setSymbol, accountId });

  return (
    <TradingTable.Row>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.symbol}>
        <TradingTable.Symbol symbol={symbol} onClick={() => addChartSymbol(symbol)} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.type}>
        <OrderType type={type} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.volume}>
        <ModifyOrderButton value={<NumberFormat value={volume} decimalScale={volumeDecimalScale} />}>
          {onClose => (
            <TerminalPopover title={t("terminal.modify-order.partial-close")}>
              <PartialCloseContainer
                onClose={onClose}
                volume={volume}
                volumeStep={volumeStep}
                volumeMin={volumeMin}
                volumeDecimalScale={volumeDecimalScale}
                currentPrice={currentPrice!}
                orderType={type}
                orderId={id}
                contractSize={contractSize}
                baseCurrency={baseCurrency}
                quoteCurrency={quoteCurrency}
                accountId={accountId}
                currency={currency}
                openPrice={price}
                symbol={symbol}
                priceDecimalScale={priceDecimalScale}
                currencyDecimalScale={currencyDecimalScale}
                swap={swap}
                profitRate={calculatedProfitRate}
              />
            </TerminalPopover>
          )}
        </ModifyOrderButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.margin}>
        <NumberFormat value={margin} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.openPrice}>
        <NumberFormat value={price} decimalScale={priceDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.currentPrice}>
        {currentPrice ? <NumberFormat value={currentPrice} decimalScale={priceDecimalScale} /> : "—"}
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.takeProfit}>
        <ModifyOrderButton
          value={takeProfit ? <NumberFormat value={takeProfit} decimalScale={priceDecimalScale} /> : null}
        >
          {onClose => (
            <TerminalPopover tooltipText={t("terminal.take-profit-desc")!} title={t("terminal.take-profit")}>
              <ModifyOpenOrderTakeProfitContainer
                onClose={onClose}
                symbol={symbol}
                volume={volume}
                orderType={type}
                orderId={id}
                priceDecimalScale={priceDecimalScale}
                stopLoss={stopLoss}
                takeProfit={takeProfit}
                ask={ask}
                bid={bid}
                accountId={accountId}
                baseCurrency={baseCurrency}
                quoteCurrency={quoteCurrency}
                contractSize={contractSize}
                currency={currency}
                openPrice={price}
                currentPrice={currentPrice!}
                currencyDecimalScale={currencyDecimalScale}
                profitRate={calculatedProfitRate}
              />
            </TerminalPopover>
          )}
        </ModifyOrderButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.stopLoss}>
        <ModifyOrderButton value={stopLoss ? <NumberFormat value={stopLoss} decimalScale={priceDecimalScale} /> : null}>
          {onClose => (
            <TerminalPopover tooltipText={t("terminal.stop-loss-desc")!} title={t("terminal.stop-loss")}>
              <ModifyOpenOrderStopLossContainer
                onClose={onClose}
                symbol={symbol}
                volume={volume}
                orderType={type}
                orderId={id}
                priceDecimalScale={priceDecimalScale}
                stopLoss={stopLoss}
                takeProfit={takeProfit}
                ask={ask}
                bid={bid}
                accountId={accountId}
                baseCurrency={baseCurrency}
                quoteCurrency={quoteCurrency}
                contractSize={contractSize}
                currency={currency}
                openPrice={price}
                currentPrice={currentPrice!}
                currencyDecimalScale={currencyDecimalScale}
                profitRate={calculatedProfitRate}
              />
            </TerminalPopover>
          )}
        </ModifyOrderButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.timeOpened}>
        {terminalFormatDate(date)}
      </TradingTable.Cell>
      <TradingTable.Cell align="end" data-test={terminalLocators.tradingTables.open.swap}>
        <NumberFormat value={swap} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell
        align="end"
        className={getNumberColorClassname(pnl)}
        data-test={terminalLocators.tradingTables.open.pnl}
      >
        <PnlFormat value={pnl} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell align="end" data-test={terminalLocators.tradingTables.open.close}>
        <CloseOpenOrderButton orderId={id} accountId={accountId} />
      </TradingTable.Cell>
    </TradingTable.Row>
  );
};

const Component = memo(_OpenOrdersBodyRow);

export { Component as OpenOrdersBodyRow };
