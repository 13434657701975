import { SumsubContainer } from "@/features/onboarding/components/sumsub/sumsub.container";
import { useKycInfoQuery } from "@/state/server/kyc";
import { useProfileHeaderQuery } from "@/state/server/profile";

export const SumsubPage = () => {
  const { data: kycInfo } = useKycInfoQuery();
  const { data: profileHeader } = useProfileHeaderQuery();

  if (!profileHeader || !kycInfo) return null;

  return <SumsubContainer sumSubInfo={kycInfo.sumSub!} email={profileHeader.email!} />;
};
