import { cva } from "class-variance-authority";
import { ElementRef, forwardRef } from "react";

import { IconCross } from "@/domains/icons";
import { cn } from "@/shared/styles";

import { MergedTerminalSymbol } from "../../helpers/symbols";
import { SymbolIcon } from "../../symbols/icon";

const buttonStyles = cva(
  "group flex h-12 shrink-0 items-center gap-2 whitespace-nowrap rounded-[16px] border border-control-border bg-control-bg px-3 transition-colors hover:bg-control-bg-active",
  {
    variants: {
      isActive: {
        true: "bg-control-bg-active",
      },
    },
  },
);

type Props = {
  symbolInfo: MergedTerminalSymbol;
  onSelect: () => void;
  onRemove?: () => void;
  isActive?: boolean;
};

const MobileChartSymbolButton = forwardRef<ElementRef<"div">, Props>(
  ({ symbolInfo, onRemove, isActive, onSelect }, forwardedRef) => {
    const { symbol } = symbolInfo;

    return (
      <div ref={forwardedRef} onClick={onSelect} className={cn(buttonStyles({ isActive }))} role="button">
        <SymbolIcon symbol={symbol!} />
        <div className="font-gilroy text-[16px] font-semibold leading-[1.2] text-contrast-primary">{symbol}</div>
        {onRemove && (
          <button
            className="text-contrast-secondary"
            onClick={e => {
              e.stopPropagation();
              onRemove();
            }}
          >
            <IconCross />
          </button>
        )}
      </div>
    );
  },
);

export { MobileChartSymbolButton };
