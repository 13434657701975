import { FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { Tabs } from "@/shared/ui";

import { useOrdersContext } from "../../contexts/orders.context";
import { TerminalTableState, useLayoutContext } from "../../layout/context";
import { ClosedPositionsContainer } from "../closed-positions/container";
import { OpenOrdersContainer } from "../open-orders/container";
import { PendingOrdersContainer } from "../pending-orders/container";

type Props = {
  onClose?: () => void;
};

const MobileTradingTablesContainer: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const { table, changeTable } = useLayoutContext();
  const { openOrders, pendingOrders } = useOrdersContext();

  return (
    <div className="flex flex-col overflow-auto scrollbar-hide">
      <Tabs value={table} onValueChange={changeTable}>
        <Tabs.List grow className="mb-4">
          <Tabs.Trigger value={TerminalTableState.OPEN}>
            {t("terminal.open")}: {openOrders.length}
          </Tabs.Trigger>
          <Tabs.Trigger value={TerminalTableState.PENDING}>
            {t("terminal.pending")}: {pendingOrders.length}
          </Tabs.Trigger>
          <Tabs.Trigger value={TerminalTableState.CLOSED_POSITIONS}>{t("terminal.closed-positions")}</Tabs.Trigger>
        </Tabs.List>
      </Tabs>
      {table === TerminalTableState.OPEN && <OpenOrdersContainer onClose={onClose} />}
      {table === TerminalTableState.PENDING && <PendingOrdersContainer onClose={onClose} />}
      {table === TerminalTableState.CLOSED_POSITIONS && <ClosedPositionsContainer />}
    </div>
  );
};

export { MobileTradingTablesContainer };
