import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "@/shared/ui";

import { TerminalDialogContent } from "../../components/terminal-dialog-content";
import { InsightsContainer } from "../../insights/container";
import { useLayoutContext } from "../context";

const InsightsDialog: FC = () => {
  const { t } = useTranslation();

  const { insightsDialogOpen, closeInsightsDialog } = useLayoutContext();

  return (
    <Dialog open={insightsDialogOpen} onOpenChange={closeInsightsDialog}>
      <TerminalDialogContent title={t("terminal.insights.title")}>
        <InsightsContainer onClose={closeInsightsDialog} />
      </TerminalDialogContent>
    </Dialog>
  );
};

export { InsightsDialog };
