import { FC } from "react";

import { DialogContent } from "@/components/dialog";
import { InfoDialog } from "@/components/info-dialog";
import { Dialog } from "@/shared/ui";

import img from "./assets/Shield.png";
import { ContentProps } from "./types";

export const DepositVerificationDialog: FC<ContentProps> = ({ open, onOpenChange, children, title, description }) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <InfoDialog imageSrc={img} title={title} description={description}>
          {children}
        </InfoDialog>
      </DialogContent>
    </Dialog>
  );
};
