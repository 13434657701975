import dayjs from "dayjs";
import { type FC, ReactNode } from "react";

import { TerminalTradingCentralArticle } from "@/services/openapi";
import { Dialog, ExternalLink, InfoDialogContent } from "@/shared/ui";

type Props = {
  trigger: ReactNode;
  article: TerminalTradingCentralArticle;
};

const NewsDialog: FC<Props> = ({ trigger, article }) => {
  const { publisher, content, title, date, url } = article;

  return (
    <Dialog>
      <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
      <InfoDialogContent title={title}>
        <div className="mb-4 flex justify-between">
          <div>
            <div className="font-roboto text-[14px] leading-normal text-contrast-secondary">Published by</div>
            <div className="font-roboto text-[14px] leading-normal text-positive-text">{publisher}</div>
          </div>
          <div className="font-roboto text-[14px] leading-normal text-contrast-secondary">
            {dayjs(date).format("YYYY MMMM DD, HH:mm")}
          </div>
        </div>
        <p className="whitespace-pre-wrap font-roboto text-[14px] leading-normal text-contrast-primary">{content}</p>
        <div className="mt-6 flex flex-col gap-2">
          <ExternalLink
            href={url!}
            className="flex items-center gap-1 font-gilroy text-[16px] font-semibold leading-normal text-positive-text"
          >
            Story continues
            {/* TODO: icon */}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.21967 3.17084C5.51256 2.87795 5.98744 2.87795 6.28033 3.17084L10.2803 7.17084C10.5732 7.46374 10.5732 7.93861 10.2803 8.2315L6.28033 12.2315C5.98744 12.5244 5.51256 12.5244 5.21967 12.2315C4.92678 11.9386 4.92678 11.4637 5.21967 11.1708L8.68934 7.70117L5.21967 4.2315C4.92678 3.93861 4.92678 3.46373 5.21967 3.17084Z"
                fill="currentColor"
              />
            </svg>
          </ExternalLink>
          <div className="font-roboto text-[14px] leading-normal text-contrast-secondary">
            According to relevant copyright regulations, some news cannot be fully displayed
          </div>
        </div>
      </InfoDialogContent>
    </Dialog>
  );
};

export { NewsDialog };
