import { cva } from "class-variance-authority";
import { FC } from "react";

import image from "@/assets/images/DialogImage2.svg";
import { Button } from "@/components/button";
import { LimitsIcon, LiveTradingIcon, StarIcon } from "@/components/icons";
import { InfoDialog, InfoDialogContent } from "@/components/info-dialog";
import { Text } from "@/components/text";
import { useTranslation } from "@/hooks/translator.hook";

const listItemStyles = cva("flex gap-4 md:flex-col md:items-center md:justify-end")();

type Props = {
  onDepositFunds: () => void;
  onUploadDocuments: () => void;
};

export const DeclarationDialogContent: FC<Props> = ({ onDepositFunds, onUploadDocuments }) => {
  const { t } = useTranslation();
  return (
    <InfoDialog
      imageSrc={image}
      title={t("onboarding.declaration.dialog-title")}
      description={t("onboarding.declaration.dialog-description")}
    >
      <InfoDialogContent>
        <Text weight="bold" size="4" align="center" css={{ mb: "32px" }}>
          {t("onboarding.declaration.dialog-content-text")}
        </Text>
        <ul className="flex flex-col flex-wrap justify-start gap-4 md:flex-row md:justify-between md:px-8">
          <li className={listItemStyles}>
            <LiveTradingIcon />
            <Text family="roboto">{t("onboarding.declaration.live-trading")}</Text>
          </li>
          <li className={listItemStyles}>
            <LimitsIcon />
            <Text family="roboto">{t("onboarding.declaration.increased-limits")}</Text>
          </li>
          <li className={listItemStyles}>
            <StarIcon />
            <Text family="roboto">{t("onboarding.declaration.full-access")}</Text>
          </li>
        </ul>
      </InfoDialogContent>
      <div className="flex justify-center">
        <Button onClick={onUploadDocuments}>{t("button.upload-documents")}</Button>
      </div>
      <div className="flex justify-center">
        <Button variant="text" onClick={onDepositFunds} size="small">
          {t("button.deposit-funds")}
        </Button>
      </div>
    </InfoDialog>
  );
};
