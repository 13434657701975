import { FC, useEffect, useState } from "react";

import { amplitudeEvents } from "@/app/libs/amplitude";
import { SelectOptionType } from "@/components/form";
import { WithdrawalPageForm } from "@/features/withdrawal/components/form/withdrawal.page.form";
import { PaymentMethodGrid } from "@/features/withdrawal/components/payment-method/payment-method-grid";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTrackVisitPage } from "@/hooks/track-visit-page.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { PaymentMethod } from "@/services/openapi";
import { useBonusesQuery } from "@/state/server/bonuses";
import { useWithdrawMethodQuery } from "@/state/server/payment";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { CabinetPage } from "../cabinet/components/cabinet-page";
import { CabinetTitle } from "../cabinet/components/cabinet-title";

export const WithdrawalPage: FC = () => {
  const { t } = useTranslation();
  const { data: allPaymentMethods } = useWithdrawMethodQuery();
  const { isLargeDesktop } = useScreenSize();
  const { data: bonusData } = useBonusesQuery();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const [showWithdrawalForm, setShowWithdrawalForm] = useState<boolean>(false);

  const availablePaymentMethods = allPaymentMethods?.items?.filter(method => method.isAvailable);
  const unavailablePaymentMethods = allPaymentMethods?.items?.filter(method => !method.isAvailable);

  const profileData = useProfileData();

  useTrackVisitPage(amplitudeEvents.withdrawal.visit);

  useEffect(() => {
    if (!isLargeDesktop) window.scrollTo({ top: 0 });
  }, []);

  if (!allPaymentMethods?.items) {
    return null;
  }

  const choosePaymentMethod = (method: PaymentMethod) => {
    setShowWithdrawalForm(true);
    setPaymentMethod(method);
  };

  const paymentOptions: SelectOptionType[] | undefined = availablePaymentMethods?.map(item => ({
    value: `${item.id}${item.reccuringId!}`,
    label: item.title!,
    icon: item.images!.mini!,
    data: {
      id: item.id,
      recurringId: item.reccuringId,
    },
  }));

  const onMethodChange = (methodId: PaymentMethod) => {
    const selectedMethod = allPaymentMethods?.items?.find(item => item.id === methodId);
    setPaymentMethod(selectedMethod!);
  };

  const openPageForm = showWithdrawalForm && paymentMethod !== null;

  // TODO: вынести общий контент в WithdrawLayout на манер DepositLayout
  return (
    <CabinetPage
      title={
        <CabinetTitle title={t("cabinet.navigation.withdrawal")} showBalance={!openPageForm}>
          <CabinetTitle.TradeButton />
        </CabinetTitle>
      }
    >
      <PaymentMethodGrid
        choosePaymentMethod={choosePaymentMethod}
        items={{ available: availablePaymentMethods, unavailable: unavailablePaymentMethods }}
      />

      {/*<CreateProfileInfo />*/}
      {openPageForm && bonusData?.items && (
        <WithdrawalPageForm
          bonus={bonusData.items}
          key={paymentMethod.id}
          paymentOptions={paymentOptions}
          paymentMethod={paymentMethod}
          onMethodChange={onMethodChange}
          noKycAmountLimit={profileData.featuresInfo?.withdrawScreenNoKycAmountLimit}
        />
      )}
    </CabinetPage>
  );
};
