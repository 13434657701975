import { IOrderLineAdapter } from "public/charting_library/charting_library";
import { type FC, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { TerminalSymbolSignal } from "@/services/openapi";

import { getIsUpSignal } from "../../signal/helpers";
import { ChartColors } from "../config/colors";
import { chartLineFont } from "../config/fonts";

type Props = {
  chartColors: ChartColors;
  signal: TerminalSymbolSignal;
  isSignalChartDialogShown: boolean;
  showSignalChartDialog: () => void;
};

const TargetPriceLine: FC<Props> = ({ chartColors, isSignalChartDialogShown, signal, showSignalChartDialog }) => {
  const { t } = useTranslation();

  const ref = useRef<IOrderLineAdapter | null>(null);

  const color = useMemo(() => {
    const isUpSignal = getIsUpSignal(signal.type!);

    if (isUpSignal) {
      return isSignalChartDialogShown ? chartColors.positivePressed : chartColors.positiveHover;
    }
    return isSignalChartDialogShown ? chartColors.negativePressed : chartColors.negativeHover;
  }, [signal, isSignalChartDialogShown, chartColors]);

  useEffect(() => {
    window.tvWidget.activeChart().dataReady(() => {
      // https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.IChartWidgetApi#createorderline
      try {
        ref.current = window.tvWidget
          .activeChart()
          .createOrderLine({ disableUndo: true })
          .setText("")
          .setLineStyle(5)
          .setQuantity(t("terminal.chart.lines.target-price"))
          .setModifyTooltip(t("terminal.signal.trade-button"))
          .setQuantityTextColor(chartColors.orderLineText)
          .setQuantityBackgroundColor(color)
          .setQuantityBorderColor(color)
          .setLineColor(color)
          .setBodyFont(chartLineFont)
          .setQuantityFont(chartLineFont)
          .setPrice(signal.priceTarget!)
          .onModify(() => showSignalChartDialog());
      } catch (e) {
        console.log(e, "target price line");
      }
    });

    return () => {
      ref.current && ref.current.remove();
    };
  }, []);

  useEffect(() => {
    if (ref.current) {
      window.tvWidget.activeChart().dataReady(() => {
        ref
          .current!.setPrice(signal.priceTarget!)
          .setQuantityBackgroundColor(color)
          .setQuantityBorderColor(color)
          .setLineColor(color);
      });
    }
  }, [signal.type, signal.priceTarget, color]);

  return null;
};

TargetPriceLine.displayName = "TargetPriceLine";

export { TargetPriceLine };
