import { useCallback } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";

import { MergedTerminalSymbol } from "./helpers/symbols";

const symbolsMap = new Map<string, number>();

// !!! elements in symbols array must have the same order on every update change or this won't work
const getSymbol = ({
  symbols,
  baseCurrencyPredicate,
  quoteCurrencyPredicate,
  symbolKey,
}: {
  symbols: MergedTerminalSymbol[];
  baseCurrencyPredicate: string;
  quoteCurrencyPredicate: string;
  symbolKey: string;
}) => {
  if (symbols.length === 0) {
    return null;
  }

  const symbolIndex = symbolsMap.get(symbolKey);

  if (symbolIndex === -1) {
    return null;
  }

  // index can be 0
  if (typeof symbolIndex === "number") {
    return symbols[symbolIndex];
  }

  const index = symbols.findIndex(
    symbol => symbol.baseCurrency === baseCurrencyPredicate && symbol.quoteCurrency === quoteCurrencyPredicate,
  );

  symbolsMap.set(symbolKey, index);

  return index !== -1 ? symbols[index] : null;
};

const getBaseSymbol = ({
  symbols,
  baseCurrencyPredicate,
  quoteCurrencyPredicate,
}: Omit<Parameters<typeof getSymbol>[0], "symbolKey">) => {
  return getSymbol({
    baseCurrencyPredicate,
    quoteCurrencyPredicate,
    symbols,
    symbolKey: `base=${baseCurrencyPredicate}&quote=${quoteCurrencyPredicate}`,
  });
};

const getQuoteSymbol = ({
  symbols,
  baseCurrencyPredicate,
  quoteCurrencyPredicate,
}: Parameters<typeof getBaseSymbol>[0]) => {
  return getSymbol({
    baseCurrencyPredicate,
    quoteCurrencyPredicate,
    symbols,
    symbolKey: `quote=${quoteCurrencyPredicate}&base=${baseCurrencyPredicate}`,
  });
};

const useTerminalNavigate = (isWebView: boolean) => {
  const reactRouterNavigate = useNavigate();

  const navigate = useCallback(
    (url: string, options?: NavigateOptions) => {
      if (isWebView) {
        window.location.assign(url);
        return;
      }

      reactRouterNavigate(url, options);
    },
    [isWebView, reactRouterNavigate],
  );

  return navigate;
};

export { getBaseSymbol, getQuoteSymbol, useTerminalNavigate };
