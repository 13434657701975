import { FC, lazy, useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { Grid } from "@/components/grid";
import { useOndoardingContext } from "@/contexts/onboarding,context";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTerminalAutoRoute } from "@/hooks/terminal-auto-route.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { KycInfo, ProfileHeader } from "@/services/openapi";
import { profileQueryKeys } from "@/state/server/profile";
import { styled } from "@/styles";

import {
  createOnboardingNavigation,
  NavigationTitle,
  NavigationTypes,
} from "./components/navigation/navigation.helpers";
import { ProfileContainer } from "./components/profile/profile.container";
import { SurveyContainer } from "./components/survey/survey.container";

const OnboardingNavigation = lazy(() =>
  import("./components/navigation/onboarding-navigation").then(module => ({
    default: module.OnboardingNavigation,
  })),
);

const Container = styled(Grid, {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  paddingBottom: "130px",
  "@bp1": {
    display: "grid",
  },
  "@bp3": {
    gridTemplateColumns: "346px 1fr",
  },
});

type Props = {
  kycInfo: KycInfo;
  profileHeader: ProfileHeader;
};

export const Onboarding: FC<Props> = ({ kycInfo, profileHeader }) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const { currentStepIndex, setCurrentStepIndex } = useOndoardingContext();
  const navigate = useNavigate();
  const { open: openTerminal } = useTerminalAutoRoute();

  const navigation = useMemo(() => createOnboardingNavigation(t, kycInfo, profileHeader), [t, kycInfo, profileHeader]);
  const [activeStep, setStep] = useState<string>(navigation[0].id);
  const featureTourInfo = useMemo(() => profileHeader.featureTours, [profileHeader]);
  const queryClient = useQueryClient();

  const navigationParams = useMemo<NavigationTypes>(
    () => ({ activeStep, setStep, navigation }),
    [activeStep, setStep, navigation],
  );

  const surveyGroup = useMemo(() => {
    return kycInfo.survey!.surveyGroups!.find(({ id }) => id === activeStep);
  }, [activeStep, kycInfo]);

  const backStepFun = useCallback(() => {
    if (currentStepIndex > 0) {
      const prevStep = navigation[currentStepIndex - 1];
      // +1 because for analytics steps count from 1
      track(amplitudeEvents.kyc.back(currentStepIndex + 1));
      setStep(prevStep.id);
    }
    window.scrollTo({ top: 0 });
  }, [currentStepIndex, navigation]);

  const nextStepFun = useCallback(() => {
    if (currentStepIndex < navigation.length - 1) {
      const nextStep = navigation[currentStepIndex + 1];
      // +1 because for analytics steps count from 1
      track(amplitudeEvents.kyc.next(currentStepIndex + 1));

      if (nextStep.id === NavigationTitle.SUMSUB) {
        if (profileHeader.featuresInfo?.mandatorySurvey) {
          openTerminal();
        } else {
          navigate(onboardingRoutes.kyc);
        }
      } else {
        setStep(nextStep.id);
      }
    }
    window.scrollTo({ top: 0 });
  }, [currentStepIndex, navigate, navigation, openTerminal, profileHeader.featuresInfo?.mandatorySurvey]);

  /* updating profile header for new featureTour info */
  useEffect(() => {
    queryClient.invalidateQueries(profileQueryKeys.header());
  }, []);

  /* NOTE - переключаю на последний активный step */
  useEffect(() => {
    let lastFilledId = activeStep;

    for (const page of navigation) {
      if (page.filled) {
        lastFilledId = page.id;
      }
    }

    activeStep !== lastFilledId && setStep(lastFilledId);
  }, []);

  useEffect(() => {
    setCurrentStepIndex(navigation.findIndex(step => step.id === activeStep));
  }, [navigation, activeStep]);

  return (
    <Container>
      {!isMobile && <OnboardingNavigation activeStep={activeStep} setStep={setStep} navigation={navigation} />}
      {navigation.map(({ id }, i, array) => {
        if (activeStep !== id) {
          return null;
        }
        switch (id) {
          case NavigationTitle.PROFILE:
            return (
              <ProfileContainer
                key={id}
                profile={kycInfo.profile!}
                address={kycInfo.address!}
                navigationParams={navigationParams}
                setNextStep={nextStepFun}
                featureTourInfo={featureTourInfo!}
              />
            );
          default:
            return (
              <SurveyContainer
                key={id}
                email={profileHeader.email!}
                navigationParams={navigationParams}
                surveyGroup={surveyGroup!}
                setBackStep={backStepFun}
                setNextStep={nextStepFun}
              />
            );
        }
      })}
    </Container>
  );
};
