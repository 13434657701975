import { FC } from "react";
import { useController } from "react-hook-form";

import { SubmitButton } from "@/app/form";
import { DialogButtons } from "@/components/dialog";
import { HookForm } from "@/components/form/hook-form";
import { Text } from "@/components/text";
import { Leverage } from "@/entities/accounts/leverage";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { Button, Dialog, Select } from "@/shared/ui";

enum Fields {
  VALUE = "value",
}

type FormValues = {
  [Fields.VALUE]: string;
};

type Props = {
  onSubmit: (values: FormValues) => Promise<unknown>;
  leverage: number;
  availableLeverages: number[];
};

const LeverageForm: FC<Props> = ({ onSubmit, leverage, availableLeverages }) => {
  const { t } = useTranslation();
  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.VALUE]: String(leverage),
    },
  });

  const {
    field: { onChange, ...field },
  } = useController({ name: Fields.VALUE, control: form.control });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <Select {...field} onValueChange={onChange} data-test="dialog-leverage-switcher">
        {availableLeverages.map(leverage => (
          <Select.Item key={leverage} value={String(leverage)}>
            <Leverage leverage={leverage} />
          </Select.Item>
        ))}
      </Select>
      <Text css={{ mt: 8 }} family="roboto" color="dimmed" lineHeight="3">
        {t("accounts.leverage.description")}
      </Text>
      <DialogButtons>
        <SubmitButton data-test="dialog-leverage-submit">{t("button.set")}</SubmitButton>
        <Dialog.Close asChild>
          <Button variant="tertiary" data-test="dialog-leverage-cancel">
            {t("button.cancel")}
          </Button>
        </Dialog.Close>
      </DialogButtons>
    </HookForm>
  );
};

export { LeverageForm };
