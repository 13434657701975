import { type FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { IconSearch, IconStar } from "@/domains/icons";
import { Chip, SearchInput } from "@/shared/ui";

import { useSymbolsContext } from "../contexts/symbols.context";
import { MergedTerminalSymbol, StaticSymbolGroup } from "../helpers/symbols";
import { WatchlistEmptyState } from "./empty-state";
import { WatchlistSymbolsList } from "./symbols-list";
import { WatchlistTradingUnavailableAlert } from "./trading-unavailable/alert";

type Props = {
  symbols: MergedTerminalSymbol[];
  search: string;
  hasSearch: boolean;
  showUnavailableAlert: boolean;
  periodDateEnd: string | null;
  isMobile: boolean;
  groupOptions: { label: string; value: string }[];
  group: ReturnType<typeof useSymbolsContext>["group"];
  setSearch: (value: string) => void;
  changeGroup: ReturnType<typeof useSymbolsContext>["changeGroup"];
  onClose: () => void;
};

const Watchlist: FC<Props> = ({
  symbols,
  search,
  setSearch,
  group,
  changeGroup,
  groupOptions,
  onClose,
  hasSearch,
  showUnavailableAlert,
  isMobile,
  periodDateEnd,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col gap-4 lg:mx-4">
      <div className="flex flex-col gap-4">
        <SearchInput
          value={search}
          onChange={setSearch}
          size="sm"
          placeholder={t("terminal.watchlist.search.placeholder")!}
        />
        {!hasSearch && (
          <Chip.Group variant={isMobile ? "overflow" : "wrap"} value={group} onValueChange={changeGroup}>
            {groupOptions.map(({ label, value }) => (
              <Chip key={value} value={value}>
                {label}
              </Chip>
            ))}
          </Chip.Group>
        )}
      </div>
      {symbols.length > 0 ? (
        <>
          {showUnavailableAlert && periodDateEnd && <WatchlistTradingUnavailableAlert periodDateEnd={periodDateEnd} />}
          <WatchlistSymbolsList symbols={symbols} onClose={onClose} />
        </>
      ) : group === StaticSymbolGroup.FAVORITES && !hasSearch ? (
        <WatchlistEmptyState icon={<IconStar />} text={t("terminal.watchlist.empty-state.favorites")} />
      ) : (
        <WatchlistEmptyState icon={<IconSearch />} text={t("terminal.watchlist.empty-state.search")} />
      )}
    </div>
  );
};

const Component = memo(Watchlist);

export { Component as Watchlist };
