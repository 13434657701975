import { FC } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, Link } from "react-router-dom";

import { DialogName, useDialog } from "@/hooks/dialog.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { AvailabilityStatus } from "@/services/openapi";
import { AlertDialog, Button } from "@/shared/ui";

type Props = {
  onCreateNewAccount: () => void;
};

export const NoAccountsDialog: FC<Props> = ({ onCreateNewAccount }) => {
  const { t } = useTranslation();
  const { open: openMainDialog, onClose: onCloseMainDialog } = useDialog(DialogName.NO_ACCOUNTS);

  return (
    <>
      <AlertDialog open={openMainDialog} onOpenChange={onCloseMainDialog}>
        <AlertDialog.Content
          noCross
          title={t("accounts.dialogs.no-accounts.title")}
          description={t("accounts.dialogs.no-accounts.description")}
        >
          <AlertDialog.Buttons>
            <Button onClick={onCreateNewAccount}>{t("accounts.dialogs.no-accounts.button-1")}</Button>
            <AlertDialog.Cancel asChild>
              <Button variant="tertiary" asChild>
                <Link
                  to={{
                    pathname: cabinetRoutes.dashboard,
                    search: createSearchParams({ tab: AvailabilityStatus.Archived }).toString(),
                  }}
                >
                  {t("accounts.dialogs.no-accounts.button-2")}
                </Link>
              </Button>
            </AlertDialog.Cancel>
          </AlertDialog.Buttons>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  );
};
