import { type FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { cabinetRoutes } from "@/routes/cabinet.routes";
import { BonusUserPlatform } from "@/services/openapi";
import { Button, Dialog } from "@/shared/ui";

import bonusImg from "../assets/promo.png";
import { getBonusMainPercent } from "../helpers";
import { BonusDialogContent } from "./bonus-dialog-content";
import { ExampleTable } from "./example-table";
import { TermsBlock } from "./terms-block";

type Props = {
  bonus: BonusUserPlatform;
};

const BonusDialog: FC<Props> = ({ bonus }) => {
  const { t } = useTranslation();

  const mainPercent = useMemo(() => getBonusMainPercent(bonus), [bonus]);

  return (
    <BonusDialogContent
      image={bonusImg}
      title={t("bonus.dialog.title", { value: mainPercent })}
      description={
        <BonusDialogContent.Description>
          <Trans
            i18nKey="bonus.dialog.description"
            values={{ value: mainPercent }}
            components={{
              strong: <BonusDialogContent.DescriptionStrong />,
            }}
          />
        </BonusDialogContent.Description>
      }
      footer={
        <Dialog.Close asChild>
          <Button variant="secondary" className="w-full" asChild>
            <Link to={cabinetRoutes.deposit}>{t("button.deposit")}</Link>
          </Button>
        </Dialog.Close>
      }
    >
      <ExampleTable currency={bonus.currency!} mainPercent={mainPercent!} />
      <BonusDialogContent.Separator />
      <TermsBlock bonus={bonus} />
    </BonusDialogContent>
  );
};

export { BonusDialog };
