import { FC } from "react";

import { BottomSheet, BottomSheetContent } from "@/components/bottom-sheet";

import img from "./assets/Shield.png";
import { ContentProps } from "./types";

export const DepositVerificationBottomSheet: FC<ContentProps> = ({
  open,
  onOpenChange,
  children,
  title,
  description,
}) => {
  return (
    <BottomSheet open={open} onOpenChange={onOpenChange}>
      <BottomSheetContent
        image={{
          src: img,
        }}
        title={title}
        description={description}
      >
        <div className="px-6">{children}</div>
      </BottomSheetContent>
    </BottomSheet>
  );
};
