import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box } from "@/components/box";
import { useAuth } from "@/contexts/auth.context";
import { ExternalKycStatus } from "@/services/openapi";
import { Button } from "@/shared/ui";
import { useKycInfoQuery } from "@/state/server/kyc";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { KycPendingMessage } from "./components/kyc-pending-message";
import { ProfileFilledContent } from "./components/profile-filled-content";
import { ProfileInCompleteContent } from "./components/profile-incomplete-content";
import { VerificationButton } from "./components/verification-button";

export const PersonalContainer: FC = () => {
  const { t } = useTranslation();

  const profile = useProfileData();
  const { data: kyc } = useKycInfoQuery();

  const navigate = useNavigate();
  const { logout } = useAuth();

  const { kycStatus, options, email } = profile;

  const signOut = useCallback(() => {
    logout(navigate);
  }, [navigate, logout]);

  return (
    <>
      {kyc && (
        <>
          {options!.isAddressFilled && options!.isProfileFilled ? (
            <ProfileFilledContent
              email={email!}
              profile={kyc.profile!}
              address={kyc.address!}
              isEmailConfirmed={options!.isEmailConfirmed!}
            />
          ) : (
            <ProfileInCompleteContent email={email!} isEmailConfirmed={options!.isEmailConfirmed!} />
          )}
          {!options!.isKycCompleted && <VerificationButton isSurveyCompleted={options!.isSurveyCompleted!} />}
        </>
      )}
      {kycStatus === ExternalKycStatus.Pending && (
        <Box css={{ mt: "48px" }}>
          <KycPendingMessage />
        </Box>
      )}
      <div className="mt-[32px]">
        <Button size="sm" onClick={signOut} variant="danger">
          {t("cabinet.navigation.sign-out")}
        </Button>
      </div>
    </>
  );
};
