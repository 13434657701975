import { TradingServerSymbolType } from "@/services/openapi";

import { calculateCurrency, calculateLeverage } from "../../helpers/formulas";
import { MergedTerminalSymbol } from "../../helpers/symbols";
import { usePlaceOrderContext, VolumeLotsError } from "../context";

type Params = {
  volumeLotsError: ReturnType<typeof usePlaceOrderContext>["volumeLotsError"];
  volumeLots: ReturnType<typeof usePlaceOrderContext>["volumeLots"];
  volumeMarginDecimalScale: ReturnType<typeof usePlaceOrderContext>["volumeMarginDecimalScale"];
  realTimeMargin: ReturnType<typeof usePlaceOrderContext>["realTimeMargin"];
  currentPrice: ReturnType<typeof usePlaceOrderContext>["currentPrice"];
  isBuyOrder: ReturnType<typeof usePlaceOrderContext>["isBuyOrder"];
  leverage: number;
  marginRateInitialMarketBuy: number;
  contractSize: number;
  baseCurrency: string;
  currency: string;
  quoteCurrency: string;
  symbols: MergedTerminalSymbol[];
  instrumentType: TradingServerSymbolType;
};

const usePlaceOrderDetails = ({
  marginRateInitialMarketBuy,
  leverage,
  volumeLots,
  volumeLotsError,
  volumeMarginDecimalScale,
  contractSize,
  baseCurrency,
  currency,
  quoteCurrency,
  symbols,
  realTimeMargin,
  instrumentType,
  currentPrice,
  isBuyOrder,
}: Params) => {
  const showValues = volumeLotsError === VolumeLotsError.NONE;

  return {
    leverage: calculateLeverage({ leverage, instrumentType, marginRateInitialMarketBuy }),
    tradeAmount: showValues
      ? {
          value: calculateCurrency({
            accountCurrency: currency,
            quoteCurrency,
            baseCurrency,
            contractSize,
            volumeLots: volumeLots || 0,
            ask: currentPrice,
            bid: currentPrice,
            type: isBuyOrder ? "buy" : "sell",
            symbols,
            instrumentType,
          }),
          decimalScale: volumeMarginDecimalScale,
        }
      : null,
    margin: showValues
      ? {
          value: realTimeMargin,
          decimalScale: volumeMarginDecimalScale,
        }
      : null,
  };
};

export { usePlaceOrderDetails };
