import { cva } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const _inputTextStyles = cva(
  "font-roboto text-[14px] leading-[1.5] text-contrast-primary transition-colors placeholder:text-contrast-tertiary group-data-[size=sm]/input-wrapper:text-[14px] group-data-[disabled=true]/input-wrapper:text-contrast-tertiary lg:text-[16px]",
);

const _Input = forwardRef<ElementRef<"input">, ComponentPropsWithoutRef<"input">>(({ autoFocus, ...props }, ref) => (
  <input
    className={cn(_inputTextStyles(), "w-full bg-transparent outline-none")}
    autoFocus={autoFocus}
    data-autofocus={autoFocus}
    {...props}
    ref={ref}
  />
));

export { _Input, _inputTextStyles };
